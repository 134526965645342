import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import useApi from '../../../utility/Hooks/Api/useApi';
import {ElectronicHousingUnitApi} from '../../../services/Api/ElectronicHousingUnitApi';
import {CheckCircleIcon, XCircleIcon} from '@heroicons/react/24/solid';
import DefaultInput from '../../Inputs/Default/DefaultInput';
import CompanySelect from '../../Selects/Company/CompanySelect';
import DefaultSelect from '../../Selects/Default/DefaultSelect';
import DefaultAddFormHeader from '../Helpers/FormHeaders/DefaultAddFormHeader';
import DefaultAddFormNavigation from "../Helpers/FormNavigation/DefaultAddFormNavigation";
import EhuStatusSelect from "../../Selects/ElectronicHousingUnit/EhuStatusSelect";

export default function ElectronicHousingUnitAddForm({}) {
    const navigate = useNavigate();
    const {data, request, loading, error} = useApi();

    const [itemData, setItemData] = useState(
        {
            "productCode": "",
            "productType": "",
            "status": "Ordered",
            "assemblyDate": "",
            "assembledBy": "",
            "installationDate": "",
            "installedBy": "",
            "customerId": null,
            "assemblyCompanyId": null,
            "installationCompanyId": null,
            "typeCode": "",
            "certificate": "",
            "typeId": null,
        }
    );
    const [itemQuantity, setItemQuantity] = useState(0);
    const [openTab, setOpenTab] = useState(1);

    const lastIndex = 3;

    const typeOptions = [
        {value: 1, label: 'Classic Truck-Trailer'},
        {value: 2, label: 'Classic Truck Rigid'},
        {value: 3, label: 'Classic Double Decker Stand-alone'},
        {value: 4, label: 'Classic Trailer Stand-alone'},
        {value: 5, label: 'Classic Custom'},
    ];

    const tabItems = [
        {title: "EHU Specification", subtitle: "Electronic Housing Unit specifications"},
        {title: "EHU Information", subtitle: "Basic information on Electronic Housing Unit"}
    ];
    const productCodeHandler = (e) => {
        setItemData({...itemData, productCode: e.target.value})
    };

    const productTypeHandler = (e) => {
        const selectedIndex = e.target.selectedIndex;
        const selectedText = e.target.options[selectedIndex].text;
        setItemData({...itemData, productType: selectedText, typeId: parseInt(e.target.value)})
    };

    const typeCodeHandler = (e) => {
        setItemData({...itemData, typeCode: e.target.value})
    };

    const productStatusHandler = (e) => {
        setItemData({...itemData, status: e.target.value})
    };

    const assemblyCompanyIdHandler = (e) => {
        setItemData({...itemData, assemblyCompanyId: parseInt(e.target.value) })
    };

    const assemblyDateHandler = (e) => {
        setItemData({...itemData, assemblyDate: e.target.value})
    };

    const installationCompanyIdHandler = (e) => {
        setItemData({...itemData, installationCompanyId: parseInt(e.target.value)})
    };

    const installationDateHandler = (e) => {
        setItemData({...itemData, installationDate: e.target.value})
    };

    const customerIdHandler = (e) => {
        setItemData({...itemData, customerId: parseInt(e.target.value) })
    };

    const certificateHandler = (e) => {
        setItemData({...itemData, certificate: e.target.value})
    };

    const itemQuantityHandler = (e) => {
        setItemQuantity(e.target.value)
    };

    const nextBtnHandler = (e) => {
        e.preventDefault();

        const positionLastIndex = openTab >= lastIndex && !loading;
        const nextCondition = openTab === (lastIndex - 1);
        const continueCondition = positionLastIndex && !error;
        const retryCondition = positionLastIndex && error;

        const createRequest = () => {
                request(ElectronicHousingUnitApi.createElectronicHousingUnit(itemData))
        };

        if (nextCondition) {
            createRequest();
            setOpenTab(lastIndex)
        } else if (continueCondition) {
            navigate("/main/electronic-housing-units");
        } else if (retryCondition) {
            createRequest();
        } else {
            setOpenTab(openTab + 1)
        }
    };

    return (
        <>
            <DefaultAddFormHeader items={tabItems} openTab={openTab}/>
            <form onSubmit={(e) => nextBtnHandler(e)}>
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                    <div
                        className="w-full md:w-[39%] ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[39.1rem]">
                        <div className="relative w-full py-2">
                            <DefaultSelect required={openTab === 1} options={typeOptions} label="SolarOnTop Type"
                                           onChange={(e) => productTypeHandler(e)}/>
                            <DefaultInput required={openTab === 1} type="text" label="Electronic Housing Unit Type (e.g. ASYEHU001R2201)"
                                          onChange={(e) => typeCodeHandler(e)}/>
                            <DefaultInput required={openTab === 1} type="text" label="Electronic Housing Unit Serial Number"
                                          onChange={(e) => productCodeHandler(e)}/>
                            <DefaultInput type="text" label="Electronic Housing Unit Certificate (e.g. ImEfficiencySOT000000XX)"
                                          onChange={(e) => certificateHandler(e)}/>
                        </div>
                    </div>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                    <div
                        className="w-full md:w-[39%] ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[39.1rem]">
                        <div className="relative w-full py-2">
                            <CompanySelect label="Customer"
                                           onChange={(e) => customerIdHandler(e)}/>
                            <CompanySelect label="Assembly Partner"
                                           onChange={(e) => assemblyCompanyIdHandler(e)}/>
                            <DefaultInput  type="text" label="Assembly Date"
                                           onChange={(e) => assemblyDateHandler(e)}/>
                            <CompanySelect label="Installation Partner"
                                           onChange={(e) => installationCompanyIdHandler(e)}/>
                            <DefaultInput type="text" label="Installation Date"
                                          onChange={(e) => installationDateHandler(e)}/>
                            <EhuStatusSelect onChange={(e) => productStatusHandler(e)}/>
                        </div>
                    </div>
                </div>
                {/*<div className={openTab === 3 ? "block" : "hidden"} id="link3">*/}
                {/*    <div*/}
                {/*        className="w-full md:w-[39%] ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[39.1rem]">*/}
                {/*        <div className="relative w-full py-2">*/}
                {/*      */}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className={openTab === lastIndex ? "block" : "hidden"} id="link4">
                    {loading
                        ? <div
                            className="w-full md:w-[39%] ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[39.1rem]">
                            {<svg className="animate-spin h-5 w-5 text-indigo-600"
                                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>}
                        </div>
                        : error
                            ?
                            <div className="ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[39.1rem]">
                                <XCircleIcon className="h-32 w-32 text-red-500" aria-hidden="true"/>
                                <span className="text-red-500 font-bold text-2xl py-3">Error</span>
                                <span
                                    className="text-gray-500 font-semibold text-gray-400 text-sm">Could not add {itemQuantity} pre-assembled Electronic Housing Unit(s) to inventory.</span>
                                <span className="text-gray-500 font-semibold text-gray-400 text-sm">Please check if fields are filled in correctly or try again later.</span>
                            </div>
                            : <div
                                className="ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[39.1rem] text-center">
                                <CheckCircleIcon className="h-32 w-32 text-green-500 " aria-hidden="true"/>
                                <span className="text-green-500 font-bold text-2xl py-3">Success</span>
                                <span
                                    className="font-semibold text-gray-400 text-sm">Successfully added {itemQuantity} pre-assembled Electronic Housing Unit(s)</span>
                                <span className="font-semibold text-gray-400 text-sm">to inventory.</span>
                            </div>
                    }
                </div>
                <DefaultAddFormNavigation open={openTab}
                                          lastIndex={lastIndex}
                                          error={error}
                                          loading={loading}
                                          data={data}
                                          previousBtnHandler={() => setOpenTab(openTab - 1)}/>
            </form>
        </>
    )
}