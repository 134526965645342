import React from 'react';
import ElectronicHousingUnitAddForm
    from "../../components/Forms/ElectronicHousingUnit/ElectronicHousingUnitAddForm";

export default function ElectronicHousingUnitAddView() {
    return (
        <>
            <div className="relative container mx-auto shadow-2xl bg-white rounded-lg min-h-[46.4rem]">
                <ElectronicHousingUnitAddForm/>
            </div>
        </>
    );
}