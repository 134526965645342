import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import useApi from '../../../utility/Hooks/Api/useApi';
import {OrderApi} from '../../../services/Api/OrderApi';
import DefaultTable from '../../../components/Tables/Bodies/DefaultTable';
import FunctionalTableHeader from '../../../components/Tables/Headers/FunctionalTableHeader';
import ConfirmationModal from '../../../components/Modals/ConfirmationModal';
import OrderEditModal from "../../../components/Modals/OrderEditModal";
import PackingSlipCompositionView from "../PackingSlipViews/PackingSlipCompositionView";

export default function OrdersView() {
    const itemEls = useRef([]);
    const {request, data} = useApi();
    const {request: deleteRequest, loading, data: deleteData, error: deleteError, reset: deleteReset} = useApi();
    const {request: editRequest, loading: editLoading, data: editData, error: editError, reset: editReset} = useApi();
    const {orderType: type} = useParams();
    const navigate = useNavigate();
    const [checkItems, setCheckItems] = useState([]);
    const [deleteModalHidden, setDeleteModalHidden] = useState(false);
    const [editModalHidden, setEditModalHidden] = useState(false);
    const [packingSlipHidden, setPackingSlipHidden] = useState(false);
    const [status, setStatus] = useState(null);
    const [q, setQ] = useState("");
    const [searchParam] = useState([
        "id",
        "customerCompanyId",
        "partnerCompanyId",
        "type.name",
        "status.name",
    ]);

    useEffect(() => {
        request(OrderApi.getOrders());
    }, [deleteRequest, editRequest, type]);

    const column = () => {
        const arr = [
            // {heading: 'ID', value: 'id',},
            {heading: 'Type', value: 'type.name',},
            {heading: 'Status', value: 'status.name',},
            {heading: 'Partner', value: 'partnerCompanyId',},
        ];

        if (type !== "assembly") {
            arr.push({heading: 'Owner', value: 'customerCompanyId',},)
        }
        return arr;
    };

    const dataFilter = data
        ?.filter(item => {
            return item.type.name.toLocaleLowerCase() === type
        });

    const deletionHandler = (ele) => {
        const id = itemEls.current.find(function (element) {
            return element === ele["id"];
        });

        if (!id) {
            itemEls.current.push(ele["id"]);
            return setCheckItems([...itemEls.current])
        } else {
            const filtered = checkItems.filter(id => {
                return id !== ele["id"];
            });
            itemEls.current = [...filtered];
            return setCheckItems([...itemEls.current])
        }
    };

    const deleteRequestHandler = () => {
        checkItems.map((e) => {
            deleteRequest(OrderApi.deleteOrder(e))
        });
        itemEls.current = [];
        setCheckItems([...itemEls.current])
    };

    const editRequestHandler = () => {
        console.log('Checked items in editRequestHandler: ', checkItems);
        checkItems.map((e) => {
            const obj = data.find(function (ele) {
                return ele["id"].toString() === e.toString();
            });
            editRequest(OrderApi.patchStatus(obj["id"], {...obj, statusId: status,}))
        });
        itemEls.current = [];
        setCheckItems([...itemEls.current])
    };

    const deleteModalHandler = () => {
        setDeleteModalHidden(!deleteModalHidden);
        if (!deleteModalHidden) {
            deleteReset();
        }
    };

    const editModalHandler = () => {
        console.log('Checked items in editModalHandler: ' ,checkItems)
        setEditModalHidden(!editModalHidden);
        console.log('model status: ', editModalHidden)
        if (!editModalHidden) {
            editReset();
        }
    };

    const editOnChangeHandler = (e) => {
        setStatus(e.target.value)
    };

    const packingSlipHandler = () => {
        setPackingSlipHidden(!packingSlipHidden);
    };

    const getPackingSlipOrders = () => {
        const orders = [];
        checkItems.map((e) => {
            const obj = data.find(function (ele) {
                return ele["id"].toString() === e.toString();
            });
            orders.push(obj)
        });
        return orders
    };

    const deletionCondition = checkItems.length > 0;

    const searchHandler = (items) => {
        return items?.filter((item) => {
            return searchParam.some((newItem) => {
                if (newItem.includes(".")) {
                    const itemSplit = newItem.split(".");
                    return (
                        item[itemSplit[0]][itemSplit[1]]
                            ?.toString()
                            ?.toLowerCase()
                            ?.indexOf(q.toLowerCase()) > -1
                    );
                } else {
                    return (
                        item[newItem]
                            ?.toString()
                            ?.toLowerCase()
                            ?.indexOf(q.toLowerCase()) > -1
                    );
                }
            });
        });
    };

    const currentTableData = useMemo(() => {
        return searchHandler(type ? dataFilter : data)
    }, [data, q, type]);

    return (
        <>
            <div className="relative container mx-auto shadow-2xl bg-white rounded-lg min-h-[46.4rem]">
                <ConfirmationModal hidden={deleteModalHidden}
                                   onCancelClick={() => deleteModalHandler(!deleteModalHidden)}
                                   loading={loading} onClick={() => deleteRequestHandler()} error={deleteError}
                                   data={deleteData}/>
                <OrderEditModal hidden={editModalHidden}
                                onCancelClick={() => setEditModalHidden(!editModalHidden)}
                                loading={editLoading} onClick={() => editRequestHandler()} error={editError}
                                data={editData} onChange={(e) => editOnChangeHandler(e)}/>
                {/*<FunctionalTableHeader*/}
                {/*    title={`Orders ${type ? `- ${type}` : ""}`}*/}
                {/*    onClick={deletionCondition ? () => deleteModalHandler() : type ? () => navigate(`/main/order/${type}/add`) : null}*/}
                {/*    onDelete={deletionCondition} onChange={(e) => setQ(e.target.value)}*/}
                {/*    onClickEdit={() => editModalHandler()}/>*/}
                {/*<DefaultTable*/}
                {/*    companyIdKey={["customerCompanyId", "partnerCompanyId"]}*/}
                {/*    data={currentTableData}*/}
                {/*    column={column} onChange={(e) => deletionHandler(e)} deletionItems={checkItems}*/}
                {/*    onNavDetail={`/main/orders/${type}/`}/>*/}
                {!packingSlipHidden
                    ? <>
                        <FunctionalTableHeader
                            title={`Orders - ${type ? type : ""}`}
                            onClick={deletionCondition ? () => deleteModalHandler() : null}
                            onClickAdd={type ? () => navigate(`/main/order/${type}/add`) : null}
                            onDelete={deletionCondition}
                            onChange={(e) => setQ(e.target.value)}
                            onClickEdit={() => editModalHandler()}
                            onClickPackingSlip={() => packingSlipHandler()}/>
                        <DefaultTable
                            companyIdKey={["customerCompanyId", "partnerCompanyId"]}
                            data={currentTableData}
                            column={column()} onChange={(e) => deletionHandler(e)} deletionItems={checkItems}
                            onNavDetail={`/main/orders/${type}/`}
                            order
                            checkBox/>
                    </>
                    : <PackingSlipCompositionView
                        orders={getPackingSlipOrders()}/>
                }
            </div>
        </>
    );
}