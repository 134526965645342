import React from 'react';
import {RouterProvider} from 'react-router-dom';
import root from './routes/root';

const routs = root();

const AppWrapper = () => {
    return (
        <RouterProvider router={routs}/>
    );
};

export default AppWrapper;
