import React from "react";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/24/solid/esm";
import DefaultModal from "./DefaultModals/DefaultModal";
import OrderStatusSelect from "../Selects/Order/OrderStatusSelect";

export default function OrderEditModal({hidden, onClick, onCancelClick, loading, error, data, onChange}) {
    const submitBtnHandler = (e) => {
        e.preventDefault();
        onClick();
    };
    return (
        <DefaultModal hidden={hidden} onCancelClick={onCancelClick} loading={loading}
                      child={<form onSubmit={(e) => submitBtnHandler(e)}>
                          {loading
                              ? <div
                                  className="w-full h-full ml-auto mr-auto px-4 flex flex-col justify-center items-center">
                                  <svg className="animate-spin mb-4 w-14 h-14 text-indigo-600 mx-auto "
                                       xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                      <circle className="opacity-25" cx="12" cy="12" r="10"
                                              stroke="currentColor"
                                              strokeWidth="4"></circle>
                                      <path className="opacity-75" fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                  </svg>
                                  <span
                                      className="mb-5 font-semibold text-gray-400 text-sm">Updating selected order(s)...</span>
                              </div>
                              : error
                                  ? <div
                                      className="ml-auto mr-auto px-4 flex flex-col justify-center items-center">
                                      <XCircleIcon className="mb-4 h-14 w-14 text-red-500"
                                                   aria-hidden="true"/>
                                      <span
                                          className="text-gray-500 font-semibold text-gray-400 text-sm">Could not update selected order(s).</span>
                                      <span
                                          className="mb-5 text-gray-500 font-semibold text-gray-400 text-sm">Please try again.</span>
                                  </div>
                                  : data
                                      ? <div
                                          className="ml-auto mr-auto px-4 flex flex-col justify-center items-center text-center">
                                          <CheckCircleIcon className="mb-4 h-14 w-14 text-green-500 "
                                                           aria-hidden="true"/>
                                          <span className="mb-5 font-semibold text-gray-400 text-sm">Successfully updated selected order(s).</span>
                                      </div>
                                      :
                                      <div
                                          className="w-full h-full ml-auto mr-auto px-4 flex flex-col justify-center items-center">
                                          <svg aria-hidden="true"
                                               className="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200"
                                               fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                               xmlns="http://www.w3.org/2000/svg">
                                              <path strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                          </svg>
                                          <span className="font-semibold text-gray-400 text-sm">Select new status for selected order(s):</span>
                                          <div className="relative w-full pb-5">
                                              <OrderStatusSelect required onChange={(e) => onChange(e)}/>
                                          </div>

                                      </div>
                          }
                          <button type="submit"
                                  disabled={loading}
                                  className={`${data ? "hidden" : ""} text-white bg-indigo-600 hover:bg-indigo-800 focus:outline-none dark:focus:ring-indigo-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2`}>
                              {error ? "Try again" : "Update"}
                          </button>
                          <button type="button"
                                  onClick={onCancelClick}
                                  disabled={loading}
                                  className={`focus:outline-none rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 focus:z-10 ${data ? "bg-blue-500 hover:bg-blue-400 text-white" : "dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600"}`}>
                              {data ? "Continue" : "Cancel"}
                          </button>
                      </form>}
        />
    )
};