import React from 'react';

export default function DefaultInput({onChange, label, type, error, required, disabled}) {
    return (
        <div className="w-full relative flex justify-center items-center bg-white flex-row">
            <div className="w-full relative pt-[15px]">
                <input minLength="1" required={required} onChange={(e) => onChange(e)} type={type} name={label} id={label} disabled={disabled} placeholder={""}
                       className={`input-field w-full outline-0 rounded border-2 p-[10px] text-sm text-gray-800 ease-linear transition duration-1500" autoComplete="off`}/>
                <label htmlFor={label}
                       className="input-label block absolute top-0 left-[10px] p-[8px] bg-white text-sm ease-linear transition duration-1500 top-[18px] text-gray-300">{label} {!required && "(optional)"} {disabled && "(disabled)"}</label>
                <div className={`flex flex-row items-center mt-1 ${error ? "" : "hidden"}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="rgba(239, 68, 68, 1)"
                         className="w-5 h-5">
                        <path fillRule="evenodd"
                              d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                              clipRule="evenodd"/>
                    </svg>
                    <p className="ml-1 text-red-500 text-xs overflow-visible">
                        {error}
                    </p>
                </div>
            </div>
        </div>
    )
}