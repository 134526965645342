import React, {useEffect} from 'react';
import useApi from '../../../utility/Hooks/Api/useApi';
import {VehicleApi} from "../../../services/Api/VehicleApi";
import DefaultSelect from '../Default/DefaultSelect';
import {CompanyApi} from "../../../services/Api/CompanyApi";

export default function VehicleSelect({onChange, label, required}) {
    const {request, data} = useApi();
    const {request: companyRequest, data: companyData} = useApi();

    useEffect(() => {
        request(VehicleApi.getVehicles());
        companyRequest(CompanyApi.getCompanies());
    }, []);

    const getCompanyName = (id) => {
        const company = companyData?.find(function (element) {
            return element["customer id"] === id.toString() || element["id"].toString() === id.toString();
        });
        return company ? `${company["company name"]} (${company["customer id"]})` : "No assigned owner";
    };

    const VehicleOptions = (e) => {
        return data.map((vehicle) => ({value: JSON.stringify(vehicle) , label: `${vehicle["id"]} - ${getCompanyName(vehicle["owner"])} - ${vehicle["reference"]}`}));
    };

    return (
        <>
            <DefaultSelect required={required} options={data ? VehicleOptions() : []} onChange={(e) => onChange(e)} label={label}/>
        </>
    )
}