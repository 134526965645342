import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom'
import useApi from '../../utility/Hooks/Api/useApi';
import {VehicleApi} from '../../services/Api/VehicleApi';
import DefaultTable from '../../components/Tables/Bodies/DefaultTable';
import FunctionalTableHeader from '../../components/Tables/Headers/FunctionalTableHeader';
import ConfirmationModal from "../../components/Modals/ConfirmationModal";

export default function VehiclesView() {
    const itemEls = useRef([]);
    const {request, data} = useApi();
    const {request: deleteRequest, loading, data: deleteData, error: deleteError, reset: deleteReset} = useApi();
    const navigate = useNavigate();
    const [checkItems, setCheckItems] = useState([]);
    const [modalHidden, setModalHidden] = useState(false);
    const [q, setQ] = useState("");
    const [searchParam] = useState([
        "id",
        "Reference",
        "Owner",
        "Type",
        "Category",
        "Brand",
        "Model",
        "NumberPlate",
        "NumberPlateCountry",
    ]);

    useEffect(() => {
        request(VehicleApi.getVehicles());
        if (deleteData) {
            itemEls.current = [];
            setCheckItems([...itemEls.current])
        }
    }, [deleteRequest]);

    const column = [
        // {heading: 'ID', value: 'id',},
        {heading: 'Reference', value: 'reference',},
        {heading: 'Owner', value: 'owner',},
        {heading: 'Type', value: 'type',},
        {heading: 'Category', value: 'category',},
        {heading: 'Brand', value: 'brand',},
        {heading: 'Model', value: 'model',},
        {heading: 'Number Plate', value: 'plateNumber',},
        {heading: 'Country', value: 'numberPlateCountry',},
    ];

    const deletionHandler = (ele) => {
        const id = itemEls.current.find(function (element) {
            return element === ele["id"];
        });

        if (!id) {
            itemEls.current.push(ele["id"]);
            return setCheckItems([...itemEls.current])
        } else {
            const filtered = checkItems.filter(id => {
                return id !== ele["id"];
            });
            itemEls.current = [...filtered];
            return setCheckItems([...itemEls.current])
        }
    };

    const deleteRequestHandler = () => {
        checkItems.map((e) => {
            deleteRequest(VehicleApi.deleteVehicle(e))
        });
        itemEls.current = [];
        setCheckItems([...itemEls.current])
    };

    const modalHandler = () => {
        setModalHidden(!modalHidden);
        if (!modalHidden) {
            deleteReset();
        }
    };

    const deletionCondition = checkItems.length > 0;

    const searchHandler = (items) => {
        return items?.filter((item) => {
            return searchParam.some((newItem) => {
                if (newItem.includes(".")) {
                    const itemSplit = newItem.split(".");
                    return (
                        item[itemSplit[0]][itemSplit[1]]
                            ?.toString()
                            ?.toLowerCase()
                            ?.indexOf(q.toLowerCase()) > -1
                    );
                } else {
                    return (
                        item[newItem]
                            ?.toString()
                            ?.toLowerCase()
                            ?.indexOf(q.toLowerCase()) > -1
                    );
                }
            });
        });
    };

    const currentTableData = useMemo(() => {
        return searchHandler(data)
    }, [data, q]);

    return (
        <>
            <div className="relative container mx-auto shadow-2xl bg-white rounded-lg min-h-[46.4rem]">
                    <ConfirmationModal hidden={modalHidden} onCancelClick={() => modalHandler(!modalHidden)}
                                       loading={loading} onClick={() => deleteRequestHandler()} error={deleteError}
                                   data={deleteData}/>
                <FunctionalTableHeader
                    title={"Vehicles"}
                    onClick={deletionCondition ? () => modalHandler() : null}
                    onClickAdd={() => navigate("/main/vehicle/add")}
                    onDelete={deletionCondition}
                    onChange={(e) => setQ(e.target.value)}/>
                <DefaultTable
                    companyIdKey={["owner"]}
                    data={currentTableData}
                    column={column} onChange={(e) => deletionHandler(e)} deletionItems={checkItems}
                    onNavDetail="/main/vehicle/"
                    checkBox/>
            </div>
        </>
    )
}