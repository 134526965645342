import React from 'react';
import SearchInput from "../../Inputs/Search/SearchInput";
import {SquaresPlusIcon, TrashIcon, ClipboardDocumentListIcon} from '@heroicons/react/24/outline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPenToSquare} from "@fortawesome/free-regular-svg-icons";

export default function DefaultTableHeader({title, onChange, onClick, onDelete, onClickEdit, onClickPackingSlip, onClickAdd}) {
    return (
        <>
            <form className="lg:hidden">
                <SearchInput label={title} onChange={(e) => onChange(e)}/>
            </form>
            <div className="flex flex-row mb-1 sm:mb-0 justify-between w-full items-center">
                <div className="rounded-t mb-0 px-4 py-4 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1"><h3
                            className="font-semibold text-lg text-blueGray-700">{title}</h3>
                        </div>
                    </div>
                </div>
                <form className="-ml-32 hidden lg:block">
                        <SearchInput label={title} onChange={(e) => onChange(e)}/>
                </form>
                {
                    onDelete
                        ?   <div className="flex items-center">
                            {onClickPackingSlip &&
                                    <ClipboardDocumentListIcon className="h-5 w-5 text-indigo-600 hover:text-indigo-400 cursor-pointer ease-linear transition duration-200 mx-5"
                                                               aria-hidden="true"
                                                               onClick={() => onClickPackingSlip()}/>}
                            {onClickEdit && <FontAwesomeIcon
                                icon={faPenToSquare}
                                className="h-4 w-4 text-indigo-600 hover:text-indigo-400 cursor-pointer ease-linear transition duration-200"
                                onClick={() => onClickEdit()}/>}
                            <TrashIcon className="h-5 w-5 text-red-600 hover:text-red-400 cursor-pointer ease-linear transition duration-200 mx-5"
                                       aria-hidden="true"
                                       onClick={() => onClick()}/>
                        </div>
                        : onClickAdd
                        ? <div>
                            <SquaresPlusIcon className="h-5 w-5 text-indigo-600 hover:text-indigo-400 cursor-pointer mx-5 ease-linear transition duration-200"
                                             aria-hidden="true"
                                             onClick={() => onClickAdd()}/>
                        </div>
                        : <div className="w-7 h-7"/>
                }
            </div>
        </>
    )
}