import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import moment from "moment";
import useApi from '../../../utility/Hooks/Api/useApi';
import { IdentificationIcon, MinusSmallIcon, PlusSmallIcon, PencilSquareIcon } from '@heroicons/react/24/solid/esm'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarcode, faFloppyDisk, faRectangleXmark } from "@fortawesome/free-solid-svg-icons";
import { ElectronicHousingUnitApi } from '../../../services/Api/ElectronicHousingUnitApi';
import { CompanyApi } from '../../../services/Api/CompanyApi';
import { PartSpecificationsApi } from '../../../services/Api/PartSpecificationsApi';
import RemarkTextArea from "../../../components/Inputs/Remark/RemarkTextArea";
import PartsSelect from "../../../components/Selects/Parts/PartsSelect";
import CustomSelect from "../../../components/Selects/Custom/CustomSelect";

export default function ElectronicHousingUnitDetailView() {
    const { electronicHousingUnitId: id } = useParams();
    const { request: ehuRequest, data: ehuData, loading: ehuLoading } = useApi();
    const { request: ehuPartsRequest, data: ehuPartsData, set: ehuSetPartsData, loading: ehuPartsLoading } = useApi();
    const { request: companyRequest, data: companyData, loading: companyLoading } = useApi();
    const { request: specRequest, data: specData, loading: specLoading } = useApi();
    const { request: linkRequest, data: linkData, loading: linkLoading } = useApi();
    const [newRemark, setNewRemark] = useState("");
    const [showSelect, setShowSelect] = useState(false);
    const [editing, setEditing] = useState({
        show: false,
        assembledBy: false,
        assemblyDate: false,
        installedBy: false,
        installationDate: false,
        certificateNumber: false,
    });

    const [editableEhuData, setEditableEhuData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        ehuRequest(ElectronicHousingUnitApi.getElectronicHousingUnit(id));
        ehuPartsRequest(ElectronicHousingUnitApi.getElectronicHousingUnitParts(id));
        companyRequest(CompanyApi.getCompanies());
        specRequest(PartSpecificationsApi.getPartSpecifications())
    }, []);

    useEffect(() => {
        setEditableEhuData(ehuData)
    }, [ehuData])

    const newRemarkHandler = (e) => {
        setNewRemark(e.target.value)
    };

    const newRemarkRequestHandler = (e) => {
        if (newRemark !== "") {
            const obj = {
                "remark": newRemark.toString()
            };
            ehuRequest(ElectronicHousingUnitApi.remarkElectronicHousingUnit(id, obj));
            setNewRemark("")
        }
    };

    const newRemarkCancelHandler = (e) => {
        setNewRemark("")
    };

    const dataUpdateRequestHandler = () => {
        // console.log(editableEhuData);
        const { id, ...dataToSend } = editableEhuData;
        ehuRequest(ElectronicHousingUnitApi.updateElectronicHousingUnit(id, dataToSend));
        toggleEditing('show')
    }

    const certificateHandler = (e) => {
        const certificate = e?.find(e => e.text.includes("certificateNumber"))?.["text"];
        return certificate ? certificate.split(": ").pop() : ehuData["certificate"]
    };

    const reverseArray = (arr) => {
        return arr.slice().reverse()
    };

    const addPart = (e) => {
        const part = JSON.parse(e.value);
        linkRequest(ElectronicHousingUnitApi.addPart(id, part.id));
        ehuPartsData.push(part);
        setShowSelect(false)
    };

    const removePart = (partId) => {
        linkRequest(ElectronicHousingUnitApi.removePart(id, partId));
        const newArr = ehuPartsData.filter(part => {
            return part.id !== partId;
        });
        ehuSetPartsData([...newArr]);
    };

    // Handle input change
    const handleInputChange = (field, value) => {
        console.log("this is it :", editableEhuData)
        setEditableEhuData(prevState => ({
            ...prevState,
            [field]: value,
        }));
    };

    // Function to toggle editing state
    const toggleEditing = (field) => {
        setEditing(prevState => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };

    // Render function to switch between text and input based on editing state
    const renderField = (field, value, options) => {
        if (editing['show']) {
            return options ?
                <div className="w-[200px]">
                    <CustomSelect
                        options={options}
                        placeHolder={`Select company...`}
                        onChange={(e) => handleInputChange(field, (field === "productType") ? e.label : e.value)}
                        isSearchable
                        value={value}
                    />
                </div> : (
                    <input
                        type="text"
                        className="border border-gray-200 px-2 py-1 rounded"
                        value={value}
                        onChange={(e) => handleInputChange(field, e.target.value)}
                        onBlur={() => toggleEditing(field)}
                        autoFocus
                    />
                );
        }
        return (
            <span
                className="text-gray-700 cursor-pointer"
            >
                {value}
            </span>
        );
    };

    const companyOptions = () => {
        return companyData.map((company) => ({ value: company.id, label: company["company name"] }));
    };

    const typeOptions = [
        {value: 1, label: 'Classic Truck-Trailer'},
        {value: 2, label: 'Classic Truck Rigid'},
        {value: 3, label: 'Classic Double Decker Stand-alone'},
        {value: 4, label: 'Classic Trailer Stand-alone'},
        {value: 5, label: 'Classic Custom'},
    ];


    return (
        <>
            {(ehuData && ehuPartsData && companyData && specData) ?
                <div className="relative container mx-auto bg-white rounded-lg shadow-2xl min-h-[46.4rem]">
                    <div className="mb-1 sm:mb-4 justify-between w-full items-center">
                        <div>
                            <div className="mb-0 px-4 pt-4 border-0">
                                <div className="flex lg:flex-row flex-col">
                                    <div className="relative px-4 max-w-full">
                                        <h3 className="font-semibold text-lg text-gray-800">Electronic Housing Unit</h3>
                                    </div>
                                    <div className="relative px-4 max-w-full flex lg:flex-col text-sm lg:border-l-2">
                                        <span className="flex lg:mr-0 mr-1">Status:</span>
                                        <span className="flex text-indigo-600">{ehuData["status"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="rounded-t mb-0 pt-1 pb-4 px-4 border-0">
                                    <div className="relative px-4 max-w-full flex flex-row items-center">
                                        <IdentificationIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                        <span className="text-gray-500 px-2 text-sm">{id}</span>
                                    </div>
                                    <div className="relative px-4 max-w-full flex flex-row items-center">
                                        <FontAwesomeIcon
                                            icon={faBarcode}
                                            className="h-4 w-5 text-gray-500"
                                        />
                                        <span className="text-gray-500 px-2 text-sm">{ehuData["productCode"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="relative lg:flex mb-1 sm:mb-4">
                                <div className="flex flex-row mb-1 justify-between w-full lg:mr-2">
                                    <div className="mb-0 px-4 py-4 w-full">
                                        <div className="flex flex-wrap items-center border-0">
                                            <div className="relative w-full px-4 max-w-full mt-2 mb-4 border-b-2 border-gray-100 flex justify-between items-center">
                                                <h3 className="font-semibold text-lg text-gray-800">Basic Info</h3>
                                                {!editing.show ? (<PencilSquareIcon className={"h-5 w-5 text-gray-500 hover:text-blue-400 cursor-pointer"}
                                                    onClick={() => toggleEditing('show')} />) : (<div className="">
                                                        <FontAwesomeIcon
                                                            icon={faFloppyDisk}
                                                            className="h-5 w-5 text-gray-500 hover:text-green-500 cursor-pointer"
                                                            onClick={() => dataUpdateRequestHandler()}
                                                        />
                                                        <FontAwesomeIcon
                                                            icon={faRectangleXmark}
                                                            className="h-5 w-5 text-gray-500 hover:text-red-500 cursor-pointer ml-2"
                                                            onClick={() => toggleEditing('show')}
                                                        />
                                                    </div>)}

                                            </div>

                                            {/* <div className="relative px-4 w-full">
                                                <ul className="w-full lg:w-3/5">
                                                    <li className="flex justify-between items-center mb-2">
                                                        <span>Id :</span>
                                                        <span>{editableEhuData.id}</span>
                                                    </li>
                                                    <li className="flex justify-between items-center mb-2">
                                                        <span>Serial number :</span>
                                                        <span>{renderField("productCode", editableEhuData.productCode)}</span>
                                                    </li>
                                                    <li className="flex justify-between items-center mb-2">
                                                        <span>SoT Type :</span>
                                                        <span>{renderField("productType", editableEhuData.productType)}</span>
                                                    </li>
                                                    <li className="flex justify-between items-center mb-2">
                                                        <span>EHU Type :</span>
                                                        <span>{renderField("typeCode", editableEhuData.typeCode)}</span>
                                                    </li>
                                                    <li className="flex justify-between items-center mb-2">
                                                        <span>Owner :</span>
                                                        <span>{renderField("customerId", companyData?.find(e => e.id === editableEhuData.customerId)?.["company name"], companyOptions())}</span>
                                                    </li>
                                                    <li className="flex justify-between items-center mb-2">
                                                        <span>Assembly company :</span>
                                                        <span>{renderField("assemblyCompanyId", companyData?.find(e => e.id === editableEhuData.assemblyCompanyId)?.["company name"], companyOptions())}</span>
                                                    </li>
                                                    <li className="flex justify-between items-center mb-2">
                                                        <span>Assembly date :</span>
                                                        <span>{renderField("assemblyDate", editableEhuData.assemblyDate)}</span>
                                                    </li>
                                                    <li className="flex justify-between items-center mb-2">
                                                        <span>Assembled by :</span>
                                                        <span>{renderField("assembledBy", editableEhuData.assembledBy)}</span>
                                                    </li>
                                                    <li className="flex justify-between items-center mb-2 w-full">
                                                        <span>Installation company :</span>
                                                        <span>{renderField("installationCompanyId", companyData?.find(e => e.id === editableEhuData.installationCompanyId)?.["company name"], companyOptions())}</span>
                                                    </li>
                                                    <li className="flex justify-between items-center mb-2">
                                                        <span>Installation date :</span>
                                                        <span>{renderField("installationDate", editableEhuData.installationDate)}</span>
                                                    </li>
                                                    <li className="flex justify-between items-center mb-2">
                                                        <span>Installed by :</span>
                                                        <span>{renderField("installedBy", editableEhuData.installedBy)}</span>
                                                    </li>
                                                    <li className="flex justify-between items-center mb-2">
                                                        <span>Certificate number :</span>
                                                        <span>{renderField("certificate", editableEhuData["certificate"] || certificateHandler(editableEhuData["remarks"]))}</span>
                                                    </li>
                                                </ul>
                                            </div> */}

                                            <div className='px-4 w-full'>
                                                <ul>
                                                    <li className={`flex flex-row items-center py-2`}>
                                                        <div className='w-1/3'>
                                                            <span>Id :</span>
                                                        </div>
                                                        <div className='w-2/3'>
                                                        <span>{editableEhuData.id}</span>
                                                        </div>
                                                    </li>
                                                    <li className={`flex flex-row items-center ${editing.show ? '' : 'py-1'}`}>
                                                        <div className='w-1/3'>
                                                        Serial number :
                                                        </div>
                                                        <div className='w-2/3'>
                                                        <span>{renderField("productCode", editableEhuData.productCode)}</span>
                                                        </div>
                                                    </li>

                                                    <li className={`flex flex-row items-center ${editing.show ? '' : 'py-1'}`}>
                                                        <div className='w-1/3'>
                                                        SoT Type :
                                                        </div>
                                                        <div className='w-2/3'>
                                                        <span>{renderField("productType", editableEhuData.productType, typeOptions)}</span>
                                                        </div>
                                                    </li> 




                                                    <li className={`flex flex-row items-center ${editing.show ? '' : 'py-1'}`}>
                                                        <div className='w-1/3'>
                                                        EHU Type :
                                                        </div>
                                                        <div className='w-2/3'>
                                                        <span>{renderField("typeCode", editableEhuData.typeCode)}</span>
                                                        </div>
                                                    </li>
                                                    <li className="flex flex-row items-center py-1">
                                                        <div className='w-1/3'>
                                                        Owner :
                                                        </div>
                                                        <div className='w-2/3'>
                                                        <span>{renderField("customerId", companyData?.find(e => e.id === editableEhuData.customerId)?.["company name"], companyOptions())}</span>
                                                        </div>
                                                    </li>
                                                    <li className="flex flex-row">
                                                        <div className='w-1/3'>
                                                        Assembly company :
                                                        </div>
                                                        <div className='w-2/3'>
                                                        <span>{renderField("assemblyCompanyId", companyData?.find(e => e.id === editableEhuData.assemblyCompanyId)?.["company name"], companyOptions())}</span>
                                                        </div>
                                                    </li>
                                                    <li className="flex flex-row">
                                                        <div className='w-1/3'>
                                                        Assembly date :
                                                        </div>
                                                        <div className='w-2/3'>
                                                        <span>{renderField("assemblyDate", editableEhuData.assemblyDate)}</span>
                                                        </div>
                                                    </li>
                                                    <li className="flex flex-row">
                                                        <div className='w-1/3'>
                                                        Assembled by :
                                                        </div>
                                                        <div className='w-2/3'>
                                                        <span>{renderField("assembledBy", editableEhuData.assembledBy)}</span>
                                                        </div>
                                                    </li>
                                                    <li className="flex flex-row">
                                                        <div className='w-1/3'>
                                                        Installation company :
                                                        </div>
                                                        <div className='w-2/3'>
                                                        <span>{renderField("installationCompanyId", companyData?.find(e => e.id === editableEhuData.installationCompanyId)?.["company name"], companyOptions())}</span>
                                                        </div>
                                                    </li>
                                                    <li className="flex flex-row">
                                                        <div className='w-1/3'>
                                                        Installation date :
                                                        </div>
                                                        <div className='w-2/3'>
                                                        <span>{renderField("installationDate", editableEhuData.installationDate)}</span>
                                                        </div>
                                                    </li>
                                                    <li className="flex flex-row">
                                                        <div className='w-1/3'>
                                                        Installed by :
                                                        </div>
                                                        <div className='w-2/3'>
                                                        <span>{renderField("installedBy", editableEhuData.installedBy)}</span>
                                                        </div>
                                                    </li>
                                                    <li className="flex flex-row">
                                                        <div className='w-1/3'>
                                                        Certificate number :
                                                        </div>
                                                        <div className='w-2/3'>
                                                        <span>{renderField("certificate", editableEhuData["certificate"] || certificateHandler(editableEhuData["remarks"]))}</span>
                                                        </div>
                                                    </li> 
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row mb-1 justify-between w-full lg:ml-2">
                                    <div className="mb-0 px-4 py-4 border-0 w-full">


                                        <div className="flex flex-wrap items-center">
                                            <div className="flex flex-col w-full">
                                                <div className="relative w-full px-4 max-w-full flex-grow flex-1 mt-2 mb-4 flex justify-between border-b-2 border-gray-100 items-center"><h3
                                                    className="font-semibold text-lg text-gray-800">Parts</h3>
                                                    <PlusSmallIcon className="h-5 w-5 text-green-600 cursor-pointer hover:text-green-400 mx-2" onClick={() => setShowSelect(!showSelect)} />
                                                </div>
                                                <div className={`w-full px-2 mb-4 ${showSelect ? "" : "hidden"}`}>
                                                    <PartsSelect classname="w-full" required={true} onChange={(part) => addPart(part)} />
                                                </div>
                                            </div>


                                            <div className="relative px-4 max-w-full flex w-full">

                                                <ul className="flex flex-col px-2 text-sm w-full">
                                                    {ehuPartsData.map((part) => {
                                                        const spec = specData?.find(e => e.id === parseInt(part["partSpecificationsId"]))
                                                        return (<li className="pb-3 sm:pb-4">
                                                            <div className="flex space-x-4">
                                                                <div className="flex-1 truncate hover:text-indigo-400 cursor-pointer" onClick={() => navigate(`/main/part/${part["id"]}`)}>
                                                                    {spec?.["partType"]}
                                                                </div>
                                                                <div className="flex-1 min-w-0">
                                                                    {/* TODO: Handle cases where some specs have concatenated details (manufacturer, version, etc.) */}
                                                                    <p className="text-sm font-medium text-gray-700 truncate">
                                                                        {spec?.["details"][0]["value"]}
                                                                    </p>
                                                                    <p className="text-sm text-gray-500 truncate">
                                                                        {spec?.["details"]?.[1]?.["value"]}
                                                                    </p>
                                                                </div>
                                                                <div className="flex-1 min-w-0">
                                                                    <p className="text-sm truncate flex">
                                                                        <IdentificationIcon
                                                                            className="h-5 w-5 text-gray-700"
                                                                            aria-hidden="true" />
                                                                        <span className="px-2 text-gray-500">{part["id"]}</span>
                                                                    </p>
                                                                    <p className="text-sm text-gray-00 truncate">
                                                                        <FontAwesomeIcon
                                                                            icon={faBarcode}
                                                                            className="h-4 w-5 text-gray-700"
                                                                        />
                                                                        <span
                                                                            className="px-2 text-gray-500">{part["serialNumber"]}</span>
                                                                    </p>
                                                                </div>
                                                                <div
                                                                    className="inline-flex items-center text-base font-semibold text-gray-900">
                                                                    <MinusSmallIcon className="h-5 w-5 text-red-600 cursor-pointer hover:text-red-400" onClick={() => removePart(part["id"])} />
                                                                </div>
                                                            </div>
                                                        </li>)
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row mb-1 justify-between w-full items-center lg:mr-2 pb-4">
                                <div className="mb-0 px-4 py-4 border-0 w-full">
                                    <div className="flex flex-wrap items-center">
                                        <div className="relative w-full px-4 max-w-full flex-grow flex-1"><h3
                                            className="font-semibold text-lg text-gray-800 border-b-2 border-gray-100 py-2">Remarks</h3>
                                        </div>
                                    </div>
                                    <RemarkTextArea
                                        required
                                        onChange={e => newRemarkHandler(e)}
                                        value={newRemark}
                                        confirm={() => newRemarkRequestHandler()}
                                        cancel={() => newRemarkCancelHandler()} />
                                    {reverseArray(ehuData["remarks"]).map((remark, index) => {
                                        if (!remark["text"].includes('certificateNumber'))
                                            return (<div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                                <div className="flex flex-wrap items-center pt-4">
                                                    <div
                                                        className="text-indigo-600 text-base font-semibold mr-2">{remark["name"]}</div>
                                                    <div
                                                        className="text-gray-500 text-sm">{moment(remark["on"]).format("DD-MM-yyyy").toString()}
                                                    </div>
                                                </div>
                                                <div
                                                    className="text-gray-400 text-sm border-b border-gray-200 pb-4 pt-2">{remark["text"]}</div>
                                            </div>
                                            )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null}
        </>
    )
}