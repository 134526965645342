import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import useApi from "../../utility/Hooks/Api/useApi";
import {ElectronicHousingUnitApi} from "../../services/Api/ElectronicHousingUnitApi";
import DefaultTable from '../../components/Tables/Bodies/DefaultTable';
import FunctionalTableHeader from '../../components/Tables/Headers/FunctionalTableHeader';
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import ElectronicHousingUnitEditModal from "../../components/Modals/ElectronicHousingUnitEditModal";
import {OrderApi} from "../../services/Api/OrderApi";

export default function ElectronicHousingUnitsView() {
    const itemEls = useRef([]);
    const {request, data} = useApi();
    const {request: deleteRequest, loading, data: deleteData, error: deleteError, reset: deleteReset} = useApi();
    const {request: editRequest, loading: editLoading, data: editData, error: editError, reset: editReset} = useApi();
    const navigate = useNavigate();
    const [checkItems, setCheckItems] = useState([]);
    const [deleteModalHidden, setDeleteModalHidden] = useState(false);
    const [editModalHidden, setEditModalHidden] = useState(false);
    const [status, setStatus] = useState(null);
    const [q, setQ] = useState("");
    const [searchParam] = useState([
        "id",
        "customerId",
        "productCode",
        "productType",
        "status",
        "assemblyCompanyId",
        "installationCompanyId",
    ]);

    useEffect(() => {
        request(ElectronicHousingUnitApi.getElectronicHousingUnits())
        if (editData || deleteData) {
            itemEls.current = [];
            setCheckItems([...itemEls.current])
        }
    }, [deleteRequest, editRequest]);

    const column = [
        // {heading: 'ID', value: 'id',},
        {heading: 'Owner', value: 'customerId',},
        {heading: 'Serial Number', value: 'productCode',},
        {heading: 'Product Type', value: 'productType',},
        {heading: 'Status', value: 'status',},
        {heading: 'Assembly Partner', value: 'assemblyCompanyId',},
        {heading: 'Installation Partner', value: 'installationCompanyId',}
    ];

    const deletionHandler = (ele) => {
        const id = itemEls.current.find(function (element) {
            return element === ele["id"];
        });

        if (!id) {
            itemEls.current.push(ele["id"]);
            return setCheckItems([...itemEls.current])
        } else {
            const filtered = checkItems.filter(id => {
                return id !== ele["id"];
            });
            itemEls.current = [...filtered];
            return setCheckItems([...itemEls.current])
        }
    };

    const deleteRequestHandler = () => {
        checkItems.map((e) => {
            deleteRequest(ElectronicHousingUnitApi.deleteElectronicHousingUnit(e))
        });
        itemEls.current = [];
        setCheckItems([...itemEls.current])
    };

    const editRequestHandler = () => {
        checkItems.map((e) => {
            const obj = data.find(function (ele) {
                return ele["id"].toString() === e.toString();
            });
            editRequest(ElectronicHousingUnitApi.updateElectronicHousingUnit(obj["id"], {
                ...obj,
                status: status,
                productType: obj["productType"]
            }))
        });
    };

    const deleteModalHandler = () => {
        setDeleteModalHidden(!deleteModalHidden);
        if (!deleteModalHidden) {
            deleteReset();
        }
    };

    const editModalHandler = () => {
        setEditModalHidden(!editModalHidden);
        if (!editModalHidden) {
            editReset();
        }
    };

    const editOnChangeHandler = (e) => {
        setStatus(e.target.value)
    };

    const deletionCondition = checkItems.length > 0;

    useEffect(() => {

        // Bind the event listener
        let code = "";
        let reading = false;

        function handleBarcodeScanner(e) {
            if (e.keyCode === 13) {
                if (code.length > 1) {
                    const id = data.find(function (element) {
                        return element["productCode"]?.toString() === code.toString();
                    });
                    if (id) {
                        deletionHandler(id)
                    }
                    /// code ready to use
                    code = "";
                }
            } else {
                code += e.key; //while this is not an 'enter' it stores the every key
            }

            // run a timeout of 200ms at the first read and clear everything
            if (!reading) {
                reading = true;
                setTimeout(() => {
                    code = "";
                    reading = false;
                }, 200);  //200 works fine for me but you can adjust it
            }
        }

        document.addEventListener('keypress', handleBarcodeScanner);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("keypress", handleBarcodeScanner);
        };
    }, [data]);

    const searchHandler = (items) => {
        return items?.filter((item) => {
            return searchParam.some((newItem) => {
                if (newItem.includes(".")) {
                    const itemSplit = newItem.split(".");
                    return (
                        item[itemSplit[0]][itemSplit[1]]
                            ?.toString()
                            ?.toLowerCase()
                            ?.indexOf(q.toLowerCase()) > -1
                    );
                } else {
                    return (
                        item[newItem]
                            ?.toString()
                            ?.toLowerCase()
                            ?.indexOf(q.toLowerCase()) > -1
                    );
                }
            });
        });
    };

    const currentTableData = useMemo(() => {
        return searchHandler(data)
    }, [data, q]);

    return (
        <>
            <div className="relative container mx-auto shadow-2xl bg-white rounded-lg min-h-[46.4rem]">
                <ConfirmationModal hidden={deleteModalHidden}
                                   onCancelClick={() => deleteModalHandler(!deleteModalHidden)}
                                   loading={loading} onClick={() => deleteRequestHandler()} error={deleteError}
                                   data={deleteData}/>
                <ElectronicHousingUnitEditModal hidden={editModalHidden}
                                                onCancelClick={() => editModalHandler(!editModalHidden)}
                                                loading={editLoading} onClick={() => editRequestHandler()}
                                                error={editError}
                                                data={editData} onChange={(e) => editOnChangeHandler(e)}/>
                <FunctionalTableHeader
                    title={"Electronic Housing Units"}
                    onClick={deletionCondition ? () => deleteModalHandler() : null}
                    onDelete={deletionCondition}
                    onClickAdd={() => navigate("/main/electronic-housing-unit/add")}
                    onClickEdit={() => editModalHandler()}
                    onChange={(e) => setQ(e.target.value)}/>
                <DefaultTable
                    companyIdKey={["customerId", "assemblyCompanyId", "installationCompanyId"]}
                    data={currentTableData}
                    column={column} onChange={(e) => deletionHandler(e)} deletionItems={checkItems}
                    onNavDetail="/main/electronic-housing-unit/"
                    checkBox/>
            </div>
        </>
    );
}