import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom'
import DefaultPagination from "../../Pagination/DefaultPagination";
import useApi from "../../../utility/Hooks/Api/useApi";
import { CompanyApi } from "../../../services/Api/CompanyApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCarDown, faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";

let PageSize = 20;

const companyKeys = ['customerId', 'assemblyCompanyId', 'installationCompanyId'];

export default function DefaultTable({ data, column, companyIdKey,
    onChange, deletionItems, onNavDetail,
    order, checkBox }) {
    const [currentPage, setCurrentPage] = useState(1);
    const { request, data: companies } = useApi();
    const { request: companyTypesRequest, data: companyTypes } = useApi();
    const navigate = useNavigate();
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

    useEffect(() => {
        if (companyIdKey) {
            request(CompanyApi.getCompanies());
        }
        companyTypesRequest(CompanyApi.getTypes());
    }, []);

    const getCompanyName = useCallback((item, key) => {
        const company = companies?.find(function (element) {
            return element["id"]?.toString() === item[key]?.toString() || element["customer id"] === item[key]?.toString();
        });
        return company ? company["company name"] : "";
    }, [companies]);

    const getCompanyType = (item) => {
        const type = companyTypes?.find(function (element) {
            return element["id"]?.toString() === item?.toString();
        });
        return type ? type["name"] : "";
    };

    const sortedData = useMemo(() => {
        if (!sortConfig.key) return data;

        return [...data].sort((a, b) => {
            const aValue = companyKeys.includes(sortConfig.key) ? getCompanyName(a, sortConfig.key) : a[sortConfig.key];
            const bValue = companyKeys.includes(sortConfig.key) ? getCompanyName(b, sortConfig.key) : b[sortConfig.key];

            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return sortConfig.direction === 'ascending' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }

            if (aValue < bValue) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
    }, [data, sortConfig, getCompanyName]);

    const currentTableData = useMemo(() => {
        if (sortedData) {
            const firstPageIndex = (currentPage - 1) * PageSize;
            const lastPageIndex = firstPageIndex + PageSize;
            return sortedData.slice(firstPageIndex, lastPageIndex);
        }
    }, [currentPage, sortedData]);

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        } else if (sortConfig.key === key && sortConfig.direction === 'descending') {
            direction = 'ascending';
        }
        setSortConfig({ key, direction });
    };

    const TableHeadItem = ({ item }) => (
        <th
            scope="col"
            className="px-5 py-2 bg-white border-b-2 border-t border-gray-200 text-gray-800 text-left text-sm font-semibold border-r cursor-pointer"
            onClick={() => handleSort(item.value)}
        >
            {item.heading}
            {sortConfig.key === item.value ? (sortConfig.direction === 'ascending' ?
                <FontAwesomeIcon
                    icon={faSortUp}
                    className="h-5 w-5 text-gray-500 ml-2 bottom"
                />
                : <FontAwesomeIcon
                    icon={faSortDown}
                    className="h-5 w-5 text-gray-500 ml-2"
                />) : null}
        </th>
    );


    return (
        <>
            {!data && <div
                className="absolute w-full h-full ml-auto mr-auto px-4 flex flex-col justify-center items-center">
                <svg className="animate-spin h-5 w-5 text-indigo-600"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                        strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </div>}
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-hidden">
                <div className="min-w-full shadow overflow-x-auto min-h-[38.7rem]">
                    <table className="min-w-full leading-normal">
                        <thead>
                            <tr>
                                {column.map((item, index) => <TableHeadItem item={item} index={index} column={column} />)}
                                {order && <th scope="col"
                                    className="px-5 py-2 bg-white border-b-2  border-t border-gray-200 text-gray-800  text-left text-sm font-semibold border-r">
                                    Progress
                                </th>}
                                {checkBox && <th scope="col"
                                    className="px-5 py-2 bg-white border-b-2  border-t border-gray-200 text-gray-800  text-left text-sm font-semibold">
                                    Action
                                </th>}

                            </tr>
                        </thead>
                        <tbody>{data && currentTableData.map((item, index) => <TableRow item={item} column={column}
                            order={order}
                            rowIndex={index}
                            companyIdKey={companyIdKey}
                            getCompanyName={getCompanyName}
                            getCompanyType={getCompanyType}
                            onChange={(e) => onChange(e)}
                            checkBox={checkBox}
                            checked={deletionItems?.find(e => e === item["id"])}
                            onNavDetail={() => navigate(`${onNavDetail}${item["id"]}`)} />)}</tbody>
                    </table>
                </div>
                {
                    data && <DefaultPagination
                        currentPage={currentPage}
                        totalCount={data.length}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentPage(page)}
                    />
                }
            </div>
        </>
    )
}


const TableRow = ({
    column, item, rowIndex,
    companyIdKey, getCompanyName, getCompanyType,
    onChange, checked, onNavDetail,
    order, checkBox
}) => {
    return (
        <tr className={`hover:bg-blue-600/20 ${rowIndex % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`} onClick={onNavDetail}>
            {column.map((columnItem) => {
                if (columnItem.value.includes(".")) {
                    const itemSplit = columnItem.value.split(".");
                    return <td
                        className={`px-5 py-1 border-b border-gray-200 text-sm font-base`}>
                        <div className="flex items-center">
                            <div className="ml-3">
                                <p className="text-gray-900 whitespace-no-wrap">
                                    {item[itemSplit[0]][itemSplit[1]]}
                                </p>
                            </div>
                        </div>
                    </td>
                }
                return <td
                    className={`px-5 py-1 border-b border-gray-200 text-sm font-base`}>
                    <div className="flex items-center">
                        <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                                {companyIdKey
                                    ? companyIdKey.find(e => e === columnItem.value) ? getCompanyName(item, companyIdKey.find(e => e === columnItem.value)) : item[`${columnItem.value}`]
                                    : columnItem.value === 'typeId' ? getCompanyType(item[`${columnItem.value}`]) : item[`${columnItem.value}`]}
                            </p>
                        </div>
                    </div>
                </td>
            })}
            {order && <td className="px-5 py-1 border-b border-gray-200">
                <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                    <div
                        className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                        style={{ width: `${(item["electronicHousingUnitIds"].length / item?.["orderParts"]?.[0]?.["count"]) * 100}%` }}> {(item["electronicHousingUnitIds"].length / item?.["orderParts"]?.[0]?.["count"]) * 100}%
                    </div>
                </div>
            </td>}

            {checkBox && <td className="px-5 py-1 border-b border-gray-200 w-[10px]">
                <div className="flex items-center">
                    <div className="form-check">
                        <input
                            className="form-check-input text-white appearance-none h-[14px] w-[14px] border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                            type="checkbox" value="" id="flexCheckChecked"
                            checked={checked ? checked : false}
                            onClick={event => event.stopPropagation()}
                            onChange={(e) => {
                                onChange(item)
                            }} />
                    </div>
                </div>
            </td>}

        </tr>)
};


{/*<td className={`px-5 py-1 border-b border-gray-200 bg-white text-sm ${bg}`}>*/
}
{/*                                    <span*/
}
{/*                                        className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">*/
}
{/*                                        <span aria-hidden="true"*/
}
{/*                                              className="absolute inset-0 bg-green-200 opacity-50 rounded-full">*/
}
{/*                                        </span>*/
}
{/*                                        <span className="relative">*/
}
{/*                                            {status}*/
}
{/*                                        </span>*/
}
{/*                                    </span>*/
}
{/*</td>*/
}