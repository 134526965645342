import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import useApi from '../../../utility/Hooks/Api/useApi';
import {OrderApi} from '../../../services/Api/OrderApi';
import DefaultTable from '../../../components/Tables/Bodies/DefaultTable';
import FunctionalTableHeader from '../../../components/Tables/Headers/FunctionalTableHeader';
import {UsersApi} from "../../../services/Api/UsersApi";

export default function OrdersAssemblyView() {
    const itemEls = useRef([]);
    const {request, data} = useApi();
    const {request: deleteRequest, loading, data: deleteData, error: deleteError, reset: deleteReset} = useApi();
    const {request: editRequest, loading: editLoading, data: editData, error: editError, reset: editReset} = useApi();
    const {request: userRequest, loading: userLoading, data: userData, error: userError} = useApi();
    const type = "assembly";
    const navigate = useNavigate();
    const [status, setStatus] = useState(null);
    const [q, setQ] = useState("");
    const [searchParam] = useState([
        "id",
        "customerCompanyId",
        "partnerCompanyId",
        "type.name",
        "status.name",
    ]);

    useEffect( () => {
        userRequest(UsersApi.CurrentUser());
        request(OrderApi.getOrders());

    }, [type]);

    const column = () => {
        const arr = [
            // {heading: 'ID', value: 'id',},
            {heading: 'Type', value: 'type.name',},
            {heading: 'Status', value: 'status.name',},
            {heading: 'Customer', value: 'customerCompanyId',},
            {heading: 'Partner', value: 'partnerCompanyId',},
            {heading: 'Units', value: "electronicHousingUnitIds.length",},
        ];

        if (type !== "assembly") {
            arr.push({heading: 'Owner', value: 'customerCompanyId',},)
        }
        return arr;
    };

    const searchHandler = (items) => {
        return items?.filter((item) => {
            return searchParam.some((newItem) => {
                if (newItem.includes(".")) {
                    const itemSplit = newItem.split(".");
                    return (
                        item[itemSplit[0]][itemSplit[1]]
                            ?.toString()
                            ?.toLowerCase()
                            ?.indexOf(q.toLowerCase()) > -1
                    );
                } else {
                    return (
                        item[newItem]
                            ?.toString()
                            ?.toLowerCase()
                            ?.indexOf(q.toLowerCase()) > -1
                    );
                }
            });
        });
    };

    const currentTableData = useMemo(() => {
        const url = window.location.href.split("/");
        const serviceType = url[3];

        const dataFilter = data
            ?.filter(item => {
                return (item.partnerCompanyId === userData?.["companies"][0]["id"] && item.type.name.toLocaleLowerCase() === serviceType)
            });
        return searchHandler(dataFilter)
    }, [data, userData, q, type]);

    return (
        <>
             <div className="relative container mx-auto shadow-2xl bg-white rounded-lg min-h-[46.4rem]">
                 <>
                            <FunctionalTableHeader
                                title={`Orders`}
                                onClick={null}
                                onClickAdd={null}
                                onChange={(e) => setQ(e.target.value)}/>
                            <DefaultTable
                                companyIdKey={["customerCompanyId", "partnerCompanyId"]}
                                data={currentTableData}
                                column={column()}
                                onNavDetail={`/assembly/order/`}
                                order/>
                        </>
                    }
                </div>
        </>
    );
}