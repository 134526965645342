import { get, post, put} from './../Client/BaseClient';

export const CompanyApi = {
    getCompanies: () =>
        get('Company'),
    getCompany: (id) =>
        get(`Company/${id}`),
    getTypes: () =>
        get('Company/Types'),
    getVehicles: (id) =>
        get(`Company/${id}/Vehicles`),
    addVehicle: (id, vehicleId) =>
        put(`Company/${id}/Vehicles/${vehicleId}/Add`),
    removeVehicle: (id, vehicleId) =>
        put(`Company/${id}/Vehicles/${vehicleId}/Remove`),
};