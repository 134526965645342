import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom'
import useApi from "../../../utility/Hooks/Api/useApi";
import {AuthenticationApi} from '../../../services/Api/AuthenticationApi';
import DefaultInput from "../../../components/Inputs/Default/DefaultInput";
import {UsersApi} from "../../../services/Api/UsersApi";

export default function LoginView() {
    const [user, setUser] = useState(null);
    const [auth, setAuth] = useState(false);

    const navigate = useNavigate();
    const {data, request, loading, error} = useApi();

    useEffect(() => {
        authenticationRouter()
    }, [auth]);

    const emailHandler = (e) => {
        setUser({...user, username: e.target.value})
    };

    const passwordHandler = (e) => {
        setUser({...user, password: e.target.value})
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        await request(AuthenticationApi.Login(user));
        await setAuth(true)
    };

    const authenticationRouter = async () => {
        if (data) {
            localStorage.setItem('SESSION', JSON.stringify(data));
            setAuth(false);
            await UsersApi.CurrentUser().then((respond) => {
                switch (respond.data["roles"][0]) {
                    case 'Administrator':
                    case 'ImEfficiency':
                        return navigate("/main");
                    case 'AssemblyEmployee':
                        return navigate("/assembly");
                    case 'InstallationEmployee':
                        return navigate("/installation");
                    default:
                        return null;
                }
            });
        } else {
            setAuth(false);
        }
    };

    return (
        <>
            <div className="items-center flex flex-wrap rounded-2xl shadow-2xl bg-white bg-opacity-50">
                <div className="w-full md:w-[39%] ml-auto mr-auto px-4">
                    <div className="lg:px-12 text-2xl px-4 text-indigo-600 mt-8 pb-8 pt-4">
                        <h1>Make Trucks</h1>
                        <h1 className="font-bold">Love The Sun!</h1>
                    </div>
                    <div className="flex-auto px-4 lg:px-20 py-10 pt-0">
                        <p className="text-gray-800 text-center py-4 text-xl">
                            <span className="text-indigo-600 font-bold">Login </span>
                            <span className="font-semibold">your account</span>
                        </p>
                        <form onSubmit={(e) => submitHandler(e)}>
                            <div className="relative w-full py-2">
                                <DefaultInput required type="text" label="Email" onChange={(e) => emailHandler(e)}/>
                            </div>
                            <div className="relative w-full py-2">
                                <DefaultInput required type="password" label="Password" error={error ? 'Couldnt find your IMEfficiency Account' : null} onChange={(e) => passwordHandler(e)}/>
                            </div>
                            <div className="text-right">
                                <label className="text-right cursor-pointer">
                                    <span
                                        className="text-sm font-semibold text-indigo-600">forgot password?
                                    </span>
                                </label>
                            </div>
                            <div className="text-center py-9">
                                <button
                                    className={`inline-flex items-center rounded bg-indigo-600 px-[4.5rem] py-2 text-xl font-normal leading-7 text-white shadow-sm ring-1 ring-indigo-600 disabled:bg-indigo-500 disabled:hover:bg-indigo-500 disabled:hover:ring-indigo-500 bg-indigo-600 hover:bg-indigo-700 hover:ring-indigo-700`}
                                    type="submit"
                                    disabled={loading}>
                                    {loading && <svg className="animate-spin -ml-8 mr-3 h-5 w-5 text-white"
                                                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                                strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor"
                                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>}
                                    Login
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="w-full h-full md:w-[61%]">
                    <iframe
                        src="https://www.youtube.com/embed/yqXxZrDw7ng?&autoplay=1&mute=1&loop=1&rel=0&modestbranding=1&controls=0&showinfo=0&color=white&iv_load_policy=3&playlist=yqXxZrDw7ng"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        className="w-full rounded-t-md rounded-br-2xl md:rounded-tl-none md:rounded-tr-2xl md:rounded-bl-none rounded-bl-2xl h-[35.7rem]"
                    />
                </div>
            </div>
        </>
    );
}