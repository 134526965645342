import React from 'react';
import OrderAddForm
    from "../../../components/Forms/Order/OrderAddForm";

export default function OrderAddView() {
    return (
        <>
            <div className="relative container mx-auto shadow-2xl bg-white rounded-lg min-h-[46.4rem]">
                <OrderAddForm/>
            </div>
        </>
    );
}