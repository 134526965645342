import {destroy, get, post, put} from './../Client/BaseClient';

export const VehicleApi = {
    getVehicles: () =>
        get('Vehicle'),
    getVehicle: (id) =>
        get(`Vehicle/${id}`),
    getVehicleEhu: (id) =>
        get(`Vehicle/${id}/ElectronicHousingUnits`),
    createVehicle: (param) =>
        post('Vehicle', param),
    deleteVehicle: (id) =>
        destroy(`Vehicle/${id}`),
    remarkVehicle: (id, param) =>
        post(`Vehicle/${id}/Remarks`, param),
    addEhu: (id, ehuId) =>
        put(`Vehicle/${id}/ElectronicHousingUnits/${ehuId}/Add`),
    removeEhu: (id, ehuId) =>
        put(`Vehicle/${id}/ElectronicHousingUnits/${ehuId}/Remove`),
};