import {destroy, get, post, patch, put} from './../Client/BaseClient';

export const OrderApi = {
    getOrders: () =>
        get('Order'),
    getOrder: (id) =>
        get(`Order/${id}`),
    getStatuses: () =>
        get('Order/Statuses'),
    createOrder: (param) =>
        post('Order', param),
    patchStatus: (id, param) =>
        patch(`Order/${id}/Status`, param),
    deleteOrder: (id) =>
        destroy(`Order/${id}`),
    addPart: (id, partId) =>
        put(`Order/${id}/Parts/${partId}/Add`),
    addEhu: (id, ehuId) =>
        put(`Order/${id}/ElectronicHousingUnits/${ehuId}/Add`),
    remarkOrder: (id, param) =>
        post(`Order/${id}/Remarks`, param),
};