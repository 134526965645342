import {useParams, useNavigate} from 'react-router-dom';
import moment from "moment";
import useApi from "../../../utility/Hooks/Api/useApi";
import React, {useEffect, useState} from "react";
import RemarkTextArea from "../../../components/Inputs/Remark/RemarkTextArea";
import {VehicleApi} from '../../../services/Api/VehicleApi';
import {CompanyApi} from '../../../services/Api/CompanyApi';
import {IdentificationIcon, MinusSmallIcon, PlusSmallIcon} from "@heroicons/react/24/solid/esm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBarcode} from "@fortawesome/free-solid-svg-icons";
import ElectronicHousingUnitSelect from "../../../components/Selects/ElectronicHousingUnit/ElectronicHousingUnitSelect";

export default function VehicleDetailView() {
    const navigate = useNavigate();
    const {vehicleId: id} = useParams();
    const {request: vehicleRequest, data: vehicleData, loading: vehicleLoading} = useApi();
    const {request: vehicleEhuRequest, data: vehicleEhuData, set: vehicleSetEhuData, loading: vehicleEhuLoading} = useApi();
    const {request: companyRequest, data: companyData, loading: companyLoading} = useApi();
    const {request: linkRequest, data: linkData, loading: linkLoading} = useApi();
    const [newRemark, setNewRemark] = useState("");
    const [showSelect, setShowSelect] = useState(false);

    useEffect(() => {
        vehicleRequest(VehicleApi.getVehicle(id));
        vehicleEhuRequest(VehicleApi.getVehicleEhu(id));
        companyRequest(CompanyApi.getCompanies());
    }, []);

    const newRemarkHandler = (e) => {
        setNewRemark(e.target.value)
    };

    const newRemarkRequestHandler = (e) => {
        if (newRemark !== "") {
            const obj = {
                "remark": newRemark.toString()
            };
            vehicleRequest(VehicleApi.remarkVehicle(id, obj));
            setNewRemark("")
        }
    };

    const newRemarkCancelHandler = (e) => {
        setNewRemark("")
    };

    const reverseArray = (arr) => {
        return arr.slice().reverse()
    };

    const addEhu = (e) => {
        const ehu = JSON.parse(e.target.value);
        linkRequest(VehicleApi.addEhu(id, ehu.id));
        vehicleEhuData.push(ehu);
        setShowSelect(false)
    };

    const removeEhu = (ehuId) => {
        linkRequest(VehicleApi.removeEhu(id, ehuId));
        const newArr = vehicleEhuData.filter(ehu => {
            return ehu.id !== ehuId;
        });
        vehicleSetEhuData([...newArr]);
    };

    return (
        <>
            {(vehicleData && companyData && vehicleEhuData) ?
                <div className="relative container mx-auto bg-white rounded-lg shadow-2xl min-h-[46.4rem]">
                    <div className="mb-1 sm:mb-4 justify-between w-full items-center">
                        <div>
                            <div className="mb-0 px-4 pt-4 border-0">
                                <div className="flex lg:flex-row flex-col">
                                    <div className="relative px-4 max-w-full">
                                        <h3 className="font-semibold text-lg text-gray-800">{vehicleData["reference"]}</h3>
                                    </div>
                                    <div className="relative px-4 max-w-full flex lg:flex-col text-sm lg:border-l-2">
                                        <span className="flex lg:mr-0 mr-1">Type:</span>
                                        <span className="flex text-indigo-600">{vehicleData["type"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="rounded-t mb-0 pt-1 pb-4 px-4 border-0">
                                    <div className="relative px-4 max-w-full flex flex-row items-center">
                                        <IdentificationIcon className="h-5 w-5 text-gray-600" aria-hidden="true"/>
                                        <span className="text-gray-500 px-2 text-sm">{id}</span>
                                    </div>
                                    <div className="relative px-4 max-w-full flex flex-row items-center">
                                        <FontAwesomeIcon
                                            icon={faBarcode}
                                            className="h-4 w-5 text-gray-500"
                                        />
                                        <span className="text-gray-500 px-2 text-sm">{vehicleData["vin"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="relative lg:flex mb-1 sm:mb-4">
                                <div className="flex flex-row mb-1 justify-between w-full lg:mr-2">
                                    <div className="mb-0 px-4 py-4 w-full">
                                        <div className="flex flex-wrap items-center border-0">
                                            <div
                                                className="relative w-full px-4 max-w-full mt-2 mb-4 border-b-2 border-gray-100 ">
                                                <h3
                                                    className="font-semibold text-lg text-gray-800">Basic
                                                    Info</h3>
                                            </div>
                                            <div className="relative px-4 max-w-full flex w-full">
                                                <ul className="flex flex-col text-gray-500 px-2 text-sm truncate">
                                                    <li className="pb-1">Id :</li>
                                                    <li className="pb-1">Vin :</li>
                                                    <li className="pb-1">Reference :</li>
                                                    <li className="pb-1">Owner :</li>
                                                    <li className="pb-1">Type :</li>
                                                    <li className="pb-1">Category :</li>
                                                    <li className="pb-1">Brand :</li>
                                                    <li className="pb-1">Model :</li>
                                                    <li className="pb-1">Number plate :</li>
                                                    <li className="pb-1">Country of origin :</li>
                                                </ul>
                                                <ul className="flex flex-col text-gray-700 text-sm px-4 truncate">
                                                    <li className="pb-1 flex-1">{vehicleData.id}</li>
                                                    <li className="pb-1 flex-1">{vehicleData.vin}</li>
                                                    <li className="pb-1 flex-1">{vehicleData.reference}</li>
                                                    <li className="pb-1 flex-1">{companyData?.find(e => (e["customer id"].toString() === vehicleData.owner.toString() || e["id"].toString() === vehicleData.owner.toString()))?.["company name"]}</li>
                                                    <li className="pb-1 flex-1">{vehicleData["type"]}</li>
                                                    <li className="pb-1 flex-1">{vehicleData.category}</li>
                                                    <li className="pb-1 flex-1">{vehicleData.brand}</li>
                                                    <li className="pb-1 flex-1">{vehicleData.model}</li>
                                                    <li className="pb-1 flex-1">{vehicleData.plateNumber}</li>
                                                    <li className="pb-1 flex-1">{vehicleData.numberPlateCountry}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row mb-1 justify-between w-full lg:ml-2">
                                    <div className="mb-0 px-4 py-4 border-0 w-full">


                                        <div className="flex flex-wrap items-center">
                                            <div className="flex flex-col w-full">
                                                <div
                                                    className="relative w-full px-4 max-w-full flex-grow flex-1 mt-2 mb-4 flex justify-between border-b-2 border-gray-100 items-center">
                                                    <h3 className="font-semibold text-lg text-gray-800">Parts</h3>
                                                    <PlusSmallIcon
                                                        className="h-5 w-5 text-green-600 cursor-pointer hover:text-green-400 mx-2"
                                                        onClick={() => setShowSelect(!showSelect)}/>
                                                </div>
                                                        <div className={`w-full px-2 mb-4 ${showSelect ? "" : "hidden"}`}>
                                                        <ElectronicHousingUnitSelect classname="w-full" required={true}
                                                                                     onChange={(ehu) => addEhu(ehu)}/>
                                                    </div>
                                                </div>



                                            <div className="relative px-4 max-w-full flex w-full">
                                                <ul className="flex flex-col px-2 text-sm w-full">
                                                    {vehicleEhuData.map((ehu) => {
                                                        return (<li className="flex flex-row">
                                                            <div className="flex space-x-4">
                                                                <div
                                                                    className="flex-1 truncate cursor-pointer hover:text-indigo-400"
                                                                    onClick={() => navigate(`/main/electronic-housing-unit/${ehu["id"]}`)}>
                                                                    Electronic Housing Unit
                                                                </div>
                                                                <div className="flex-1 min-w-0">
                                                                    <p className="text-sm font-medium text-gray-700 truncate">
                                                                        {ehu.productType}
                                                                    </p>
                                                                    <p className="text-sm text-gray-500 truncate">
                                                                        {ehu.status}
                                                                    </p>
                                                                </div>
                                                                <div className="flex-1 min-w-0">
                                                                    <p className="text-sm truncate flex">
                                                                        <IdentificationIcon
                                                                            className="h-5 w-5 text-gray-700"
                                                                            aria-hidden="true"/>
                                                                        <span
                                                                            className="px-2 text-gray-500">{ehu["id"]}</span>
                                                                    </p>
                                                                    <p className="text-sm text-gray-00 truncate">
                                                                        <FontAwesomeIcon
                                                                            icon={faBarcode}
                                                                            className="h-4 w-5 text-gray-700"
                                                                        />
                                                                        <span
                                                                            className="px-2 text-gray-500">{ehu["productCode"]}</span>
                                                                    </p>
                                                                </div>
                                                                <div
                                                                    className="pb-1 flex-1 inline-flex items-center">
                                                                    <MinusSmallIcon
                                                                        className="h-5 w-5 text-red-600 cursor-pointer hover:text-red-400"
                                                                        onClick={() => removeEhu(ehu["id"])}/>
                                                                </div>
                                                            </div>
                                                        </li>)
                                                    })}
                                                </ul>
                                                {/*<ul className="flex flex-col px-2 text-sm w-full">*/}
                                                {/*    {ehuPartsData.map((part) => {*/}
                                                {/*        const spec = specData?.find(e => e.id === parseInt(part["partSpecificationsId"]))*/}
                                                {/*        return (<li className="pb-3 sm:pb-4">*/}
                                                {/*            <div className="flex space-x-4">*/}
                                                {/*                <div className="flex-1 truncate">*/}
                                                {/*                    {spec?.["partType"]}*/}
                                                {/*                </div>*/}
                                                {/*                <div className="flex-1 min-w-0">*/}
                                                {/*                    <p className="text-sm font-medium text-gray-700 truncate">*/}
                                                {/*                        {spec?.["details"][0]["value"]}*/}
                                                {/*                    </p>*/}
                                                {/*                    <p className="text-sm text-gray-500 truncate">*/}
                                                {/*                        {spec?.["details"][1]["value"]}*/}
                                                {/*                    </p>*/}
                                                {/*                </div>*/}
                                                {/*                <div className="flex-1 min-w-0">*/}
                                                {/*                    <p className="text-sm truncate flex">*/}
                                                {/*                        <IdentificationIcon*/}
                                                {/*                            className="h-5 w-5 text-gray-700"*/}
                                                {/*                            aria-hidden="true"/>*/}
                                                {/*                        <span className="px-2 text-gray-500">{part["id"]}</span>*/}
                                                {/*                    </p>*/}
                                                {/*                    <p className="text-sm text-gray-00 truncate">*/}
                                                {/*                        <FontAwesomeIcon*/}
                                                {/*                            icon={faBarcode}*/}
                                                {/*                            className="h-4 w-5 text-gray-700"*/}
                                                {/*                        />*/}
                                                {/*                        <span*/}
                                                {/*                            className="px-2 text-gray-500">{part["serialNumber"]}</span>*/}
                                                {/*                    </p>*/}
                                                {/*                </div>*/}
                                                {/*                <div*/}
                                                {/*                    className="inline-flex items-center text-base font-semibold text-gray-900">*/}
                                                {/*                    <MinusSmallIcon className="h-5 w-5 text-red-600 cursor-pointer hover:text-red-400"/>*/}
                                                {/*                </div>*/}
                                                {/*            </div>*/}
                                                {/*        </li>)*/}
                                                {/*    })}*/}
                                                {/*</ul>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row mb-1 justify-between w-full items-center lg:mr-2 pb-4">
                                <div className="mb-0 px-4 py-4 border-0 w-full">
                                    <div className="flex flex-wrap items-center">
                                        <div className="relative w-full px-4 max-w-full flex-grow flex-1"><h3
                                            className="font-semibold text-lg text-gray-800 border-b-2 border-gray-100 py-2">Remarks</h3>
                                        </div>
                                    </div>
                                    <RemarkTextArea
                                        required
                                        onChange={e => newRemarkHandler(e)}
                                        value={newRemark}
                                        confirm={() => newRemarkRequestHandler()}
                                        cancel={() => newRemarkCancelHandler()}/>
                                    {reverseArray(vehicleData["remarks"]).map((remark, index) => {
                                        return (<div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                            <div className="flex flex-wrap items-center pt-4">
                                                <div
                                                    className="text-indigo-600 text-base font-semibold mr-2">{remark["Name"]}</div>
                                                <div
                                                    className="text-gray-500 text-sm">{moment(remark["On"]).format("DD-MM-yyyy").toString()}</div>
                                            </div>
                                            <div
                                                className="text-gray-400 text-sm border-b border-gray-200 pb-4 pt-2">{remark["Text"]}</div>
                                        </div>)
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null}
        </>
    )
}