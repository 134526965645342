import React, {Fragment, useRef, useState} from "react";
import {Dialog, Transition} from '@headlessui/react'
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/24/solid/esm";

export default function DefaultModal({hidden, onCancelClick, loading, child}) {
    const [open, setOpen] = useState(hidden);

    const confirmButtonRef = useRef(null);
    const cancelButtonRef = useRef(null);

    const IconOption = (props) => (
        <div {...props}>
           {props.children}
        </div>
    );

    return (
        <Transition.Root show={hidden} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-50 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                open={hidden}
                onClose={setOpen}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black-200 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div id="popup-modal" tabIndex="-1"
                             className={`overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 p-4 md:inset-0 h-modal md:h-full flex justify-center items-center`}>
                            <div className="relative w-full max-w-md mx-auto h-full md:h-auto md:-top-[5rem]">
                                <div className="relative bg-white rounded border-none shadow-xl">
                                    <button type="button"
                                            onClick={onCancelClick}
                                            disabled={loading}
                                            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                                            data-modal-toggle="popup-modal">
                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor"
                                             viewBox="0 0 20 20"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd"
                                                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                  clipRule="evenodd"></path>
                                        </svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>
                                    <div className="p-6 text-center">

                                        {child}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
};