import React from 'react';
import {createBrowserRouter, Navigate} from 'react-router-dom';
import HomeLayout from './../layouts/Home/HomeLayout';
import AuthenticationLayout from './../layouts/Authentication/AuthenticationLayout';
import MainLayout from './../layouts/Main/MainLayout';
import ErrorView from './../views/Error/ErrorView';
import HomeView from './../views/Home/HomeView';
import LoginView from './../views/Authentication/Login/LoginView';
import ElectronicHousingUnitsView from './../views/Main/ElectronicHousingUnitsView';
import ElectronicHousingUnitDetailView from "../views/Main/ElectronicHousingUnitViews/ElectronicHousingUnitDetailView";
import PartsView from './../views/Main/PartsView';
import PartsDetailView from "../views/Main/PartsViews/PartsDetailView";
import PartsAddView from '../views/Main/PartsAddView';
import VehiclesView from '../views/Main/VehiclesView';
import VehicleDetailView from "../views/Main/VehicleViews/VehicleDetailView";
import VehicleAddView from '../views/Main/VehicleViews/VehicleAddView';
import CompaniesView from '../views/Main/CompaniesView';
import CompanyDetailView from "../views/Main/CompanyViews/CompanyDetailView";
import OrdersView from "../views/Main/OrderViews/OrdersView";
import OrderAddView from "../views/Main/OrderViews/OrderAddView";
import OrderDetailView from "../views/Main/OrderViews/OrderDetailView";
import OrdersAssemblyView from "../views/Assembly/OrdersViews/OrdersAssemblyView";
import UnauthorizedView from "../views/Error/UnauthorizedView";
import OrderAssemblyDetailView from "../views/Assembly/OrdersViews/OrderAssemblyDetailView";
import ElectronicHousingUnitAssemblyView from "../views/Assembly/ElectronicHousingUnitAssemblyViews/ElectronicHousingUnitAssemblyView";
import OrdersInstallationView from "../views/Installation/OrdersViews/OrdersInstallationView";
import OrderInstallationDetailView from "../views/Installation/OrdersViews/OrderInstallationDetailView";
import VehicleInstallationView from "../views/Installation/VehicleInstallationViews/VehicleInstallationView";
import ElectronicHousingUnitAddView from "../views/Main/ElectronicHousingUnitAddView";
// MIGRATEDELETE: Delete this after completiong of migration together with the file MigrationView.js
import MigrationView from '../views/Main/MigrationView';

const routes = [
    {
        path: '/',
        element: <HomeLayout/>,
        errorElement: <ErrorView/>,
        children: [
            {index: true, element: <HomeView/>},
            {path: '/unauthorized', element: <UnauthorizedView/>},
        ],
    },
    {
        path: '/auth',
        element: <AuthenticationLayout/>,
        errorElement: <ErrorView/>,
        children: [
            {index: true, element: <LoginView/>},
        ],
    },
    {
        path: '/main',
        element: <MainLayout/>,
        errorElement: <ErrorView/>,
        children: [
            {index: true, element: <OrdersView/>},
            {path: '/main/orders/:orderType', element: <OrdersView/>},
            {path: '/main/orders/:orderType/:orderId', element: <OrderDetailView/>},
            {path: '/main/order/:orderType/add', element: <OrderAddView/>},
            {path: '/main/electronic-housing-units', element: <ElectronicHousingUnitsView/>},
            {path: '/main/electronic-housing-unit/:electronicHousingUnitId', element: <ElectronicHousingUnitDetailView/>},
            {path: '/main/electronic-housing-unit/add', element: <ElectronicHousingUnitAddView/>},
            {path: '/main/parts', element: <PartsView/>},
            {path: '/main/part/:partsId', element: <PartsDetailView/>},
            {path: '/main/part/add', element: <PartsAddView/>},
            {path: '/main/vehicles', element: <VehiclesView/>},
            {path: '/main/vehicle/:vehicleId', element: <VehicleDetailView/>},
            {path: '/main/vehicle/add', element: <VehicleAddView/>},
            {path: '/main/companies', element: <CompaniesView/>},
            {path: '/main/company/:companyId', element: <CompanyDetailView/>},
// MIGRATEDELETE: Delete this after completiong of migration together with the file MigrationView.js
            {path: '/main/migration', element: <MigrationView/>},
        ],
    },

    {
        path: '/assembly',
        element: <MainLayout/>,
        errorElement: <ErrorView/>,
        children: [
            {index: true, element: <OrdersAssemblyView/>},
            {path: '/assembly/orders', element: <OrdersAssemblyView/>},
            {path: '/assembly/orders/open', element: <OrdersAssemblyView/>},
            {path: '/assembly/orders/completed', element: <OrdersAssemblyView/>},
            {path: '/assembly/order/:orderId', element: <OrderAssemblyDetailView/>},
            {path: '/assembly/electronic-housing-unit/:ehuId', element: <ElectronicHousingUnitAssemblyView/>},
        ],
    },

    {
        path: '/installation',
        element: <MainLayout/>,
        errorElement: <ErrorView/>,
        children: [
            {index: true, element: <OrdersInstallationView/>},
            {path: '/installation/orders', element: <OrdersInstallationView/>},
            {path: '/installation/orders/open', element: <OrdersInstallationView/>},
            {path: '/installation/orders/completed', element: <OrdersInstallationView/>},
            {path: '/installation/order/:orderId', element: <OrderInstallationDetailView/>},
            {path: '/installation/vehicle/:orderId/:vehicleId', element: <VehicleInstallationView/>},
        ],
    },

    {
        path: '/unauthorized',
        errorElement: <ErrorView/>,
        children: [
            {index: true, element: <UnauthorizedView/>},
        ],
    },
];

export default function root() {
    return createBrowserRouter(routes);
}