import React, {useEffect, useState} from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import MainNavbar from '../../components/Navbars/Main/MainNavbar';
import MainSidebar from '../../components/Sidebars/Main/MainSidebar';
import AssemblyNavbar from "../../components/Navbars/Main/AssemblyNavbar";
import AssemblySidebar from "../../components/Sidebars/Main/AssemblySidebar";
import InstallationNavbar from "../../components/Navbars/Main/InstallationNavbar";
import InstallationSidebar from "../../components/Sidebars/Main/InstallationSidebar";
import AuthenticationWrapper from '../../utility/Wrappers/Authentication/AuthenticationWrapper';
import useApi from "../../utility/Hooks/Api/useApi";
import {UsersApi} from "../../services/Api/UsersApi";

export default function MainLayout() {
    const {data, request, loading, error} = useApi();

    useEffect(() => {
        request(UsersApi.CurrentUser());
    },[]);

    const sidebarHandler = (role) => {
        switch (role) {
            case 'Administrator':
            case 'ImEfficiency':
                return <MainSidebar/>;
            case 'AssemblyEmployee':
                return <AssemblySidebar/>;
            case 'InstallationEmployee':
                return <InstallationSidebar/>;
            default:
                return null;
        }
    };

    const navbarHandler = (role) => {
        switch (role) {
            case 'Administrator':
            case 'ImEfficiency':
                return <MainNavbar/>;
            case 'AssemblyEmployee':
                return <AssemblyNavbar/>;
            case 'InstallationEmployee':
                return <InstallationNavbar/>;
            default:
                return null;
        }
    };
    return (
        <>
           { data ? <AuthenticationWrapper redirect={<Navigate to="/" replace={true}/>} isAuth={true}>
            <div className="isolate bg-white">
                {navbarHandler(data["roles"][0])}
                <div
                    className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
                    <svg
                        className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
                        viewBox="0 0 1155 678"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
                            fillOpacity=".3"
                            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                        />
                        <defs>
                            <linearGradient
                                id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
                                x1="1155.49"
                                x2="-78.208"
                                y1=".177"
                                y2="474.645"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#9089FC"/>
                                <stop offset={1} stopColor="#FF80B5"/>
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
                <main className="lg:flex lg:flex-row">
                    {sidebarHandler(data["roles"][0])}
                    <div className="relative px-6 lg:px-8 w-full">
                        <div className="mx-auto max-w-8xl pt-20 pb-32 sm:pt-16 sm:pb-40">
                            <div>
                                {/*Outlet renders the view with use of react-router-dom*/}
                                <Outlet/>
                                <div
                                    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
                                    <svg
                                        className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
                                        viewBox="0 0 1155 678"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
                                            fillOpacity=".3"
                                            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                                        />
                                        <defs>
                                            <linearGradient
                                                id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                                                x1="1155.49"
                                                x2="-78.208"
                                                y1=".177"
                                                y2="474.645"
                                                gradientUnits="userSpaceOnUse"
                                            >
                                                <stop stopColor="#9089FC"/>
                                                <stop offset={1} stopColor="#FF80B5"/>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            </AuthenticationWrapper> : null}
        </>
    );
}