import React from 'react';
import DefaultSelect from '../Default/DefaultSelect';
import {useParams} from "react-router-dom";

export default function OrderStatusSelect({onChange, required}) {
    const {orderType: type} = useParams();

    const options = (type) => {
        switch (type) {
            case 'installation':
                // return [
                //     {value: 7, label: "Created"},
                //     {value: 8, label: "Scheduled"},
                //     {value: 9, label: "Picked"},
                //     {value: 10, label: "Shipped"},
                //     {value: 11, label: "Installing"},
                //     {value: 12, label: "Completed"},
                //     {value: 13, label: "Active"},
                // ];
                return [
                    {value: 7, label: "Created"},
                    {value: 9, label: "Picked"},
                    {value: 10, label: "Shipped"},
                    {value: 11, label: "Installing"},
                    {value: 13, label: "Completed"},

                ];
            case 'assembly':
                return [
                    {value: 7, label: "Created"},
                    {value: 3, label: "Assembled"},
                    {value: 4, label: "Warehouse IME, not tested"},
                    {value: 5, label: "Warehouse IME, OK"},
                    {value: 6, label: "Test NOK, fix"},
                ];
                //TODO Phact moet de volgende types toevoegen voor order (Created bestaat al)
            // return [
            //     {value: 0, label: "Created"},
            //     {value: 0, label: "Assembling"},
            //     {value: 0, label: "Post-Assembling"},
            //     {value: 0, label: "Completed"},
            // ];
            case 'reparation':
                return [];
            default:
                return 0;
        }
    };

    return (
        <>
            <DefaultSelect required={required} options={options(type)} onChange={(e) => onChange(e)}
                           label="Order status"/>
        </>
    )
}