import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

const createApiClient = (baseURL, version, urlAuthentication = []) => {
    const apiClient = axios.create({
        baseURL: `${baseURL}/api/v${version}/`
    });

    apiClient.interceptors.request.use((config) => {
        const urlCheck = urlAuthentication.some(endpoint => config.url.includes(endpoint));
        const session = JSON.parse(localStorage.getItem('SESSION'));

        if (urlCheck && session) {
            config.headers['Authorization'] = `Bearer ${session.token}`;
        }

        config.headers['Content-Type'] = 'application/json';
        config.headers['Accept'] = 'application/json';

        return config;
    }, error => Promise.reject(error));

    apiClient.interceptors.response.use(
        response => response,
        async (error) => {
            return Promise.reject(error);
        },
    );

    return apiClient;
};

const devApiClient = createApiClient(
    'http://localhost:8080/https://ime-we-d-azapp-solarontop-api002.azurewebsites.net',
    '0.1',
    ['Logout', 'Users', 'Parts', 'ElectronicHousingUnits', 'Vehicle', 'Company', 'PartSpecifications', 'Order']
);

const prodApiClient = createApiClient(
    'http://localhost:8080/https://ime-we-p-azapp-solarontop-api002.azurewebsites.net',
    '0.1',
    ['Logout', 'Users', 'Parts', 'ElectronicHousingUnits', 'Vehicle', 'Company', 'PartSpecifications', 'Order']
);

const JsonRenderer = ({ data }) => {
    const renderJson = (obj, indent = 2) => {
        if (typeof obj !== 'object' || obj === null) {
            return <span className="text-green-500">{JSON.stringify(obj)}</span>;
        }
        if (Array.isArray(obj)) {
            return (
                <span>
                    [<br />
                    {obj.map((item, index) => (
                        <span key={index} style={{ paddingLeft: indent * 8 }}>
                            {renderJson(item, indent + 1)}
                            {index < obj.length - 1 ? ',' : ''}
                            <br />
                        </span>
                    ))}
                    {Array.isArray(obj) && <span style={{ paddingLeft: (indent - 1) * 8 }}>]</span>}
                </span>
            );
        }
        return (
            <span>
                {'{'}
                <br />
                {Object.keys(obj).map((key, index, arr) => (
                    <span key={key} style={{ paddingLeft: indent * 8 }}>
                        <span className="text-white">"{key}"</span>: {renderJson(obj[key], indent + 1)}
                        {index < arr.length - 1 ? ',' : ''}
                        <br />
                    </span>
                ))}
                <span style={{ paddingLeft: (indent - 1) * 8 }}>{'}'}</span>
            </span>
        );
    };

    return (
        <div className="whitespace-pre-wrap bg-black text-white p-4 rounded">
            {renderJson(data)}
        </div>
    );
};


const MigrationView = () => {
    const [devParts, setDevParts] = useState([]);
    const [prodParts, setProdParts] = useState([]);
    const [devEhus, setDevEhus] = useState([]);
    const [prodEhus, setProdEhus] = useState([]);
    const [detailedData, setDetailedData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [tooltipData, setTooltipData] = useState(null);
    const [hoveredItemId, setHoveredItemId] = useState(null);
    const [selected, setSelected] = useState(null);
    const [selectedEHU, setSelectedEHU] = useState(null);
    const [partsSearchQuery, setPartsSearchQuery] = useState("");
    const [ehusSearchQuery, setEhusSearchQuery] = useState("");

    const fetchParts = useCallback(async () => {
        try {
            const [devPartsResponse, prodPartsResponse, devEhuResponse, prodEhuResponse] = await Promise.all([
                devApiClient.get('Parts'),
                prodApiClient.get('Parts'),
                devApiClient.get('ElectronicHousingUnits'),
                prodApiClient.get('ElectronicHousingUnits')
            ]);

            setDevParts(devPartsResponse.data);
            setProdParts(prodPartsResponse.data);
            setDevEhus(devEhuResponse.data);
            setProdEhus(prodEhuResponse.data);
        } catch (e) {
            console.error('The following error has occurred:', e);
            setError(e);
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchDetailedData = async (id, type) => {
        try {
            setLoading(true);
            let response, vehicleResponse, ehuResponse;
    
            if (type === "part") {
                [response, vehicleResponse, ehuResponse] = await Promise.all([
                    devApiClient.get(`Parts/${id}`),
                    devApiClient.get(`Parts/${id}/Vehicles`),
                    devApiClient.get(`Parts/${id}/ElectronicHousingUnits`)
                ]);
            } else if (type === "ehu") {
                [response, vehicleResponse, ehuResponse] = await Promise.all([
                    devApiClient.get(`ElectronicHousingUnits/${id}`),
                    devApiClient.get(`ElectronicHousingUnits/${id}/Vehicles`),
                    devApiClient.get(`ElectronicHousingUnits/${id}/Parts`)
                ]);
            }
    
            const combinedData = {
                ...response.data,
                vehicles: vehicleResponse?.data || [],
                [type === "part" ? "electronicHousingUnits" : "parts"]: ehuResponse?.data || []
            };
    
            setDetailedData(combinedData);
        } catch (e) {
            console.error('Failed to fetch detailed data:', e);
        } finally {
            setLoading(false);
        }
    };
    

    useEffect(() => {
        fetchParts();
    }, [fetchParts]);

    const handleMouseEnter = (item) => {
        setTooltipData(item);
        setHoveredItemId(item.id);
    };

    const handleMouseLeave = () => {
        setTooltipData(null);
        setHoveredItemId(null);
    };

    const handlePartSelect = async (part) => {
        setSelected(part);
        await fetchDetailedData(part.id, "part");
    };

    const handleEHUSelect = async (ehu) => {
        setSelectedEHU(ehu);
        await fetchDetailedData(ehu.id, "ehu");
    };

    const handleMigrate = async () => {
        if (selected && detailedData) {
            const { id, ...selectedWithoutId } = selected;
            try {
                // Migrate the selected part to production
                const response = await prodApiClient.post('Parts', selectedWithoutId);
                console.log('Migration successful:', response.data);
                const newId = response.data.id;
    
                // Extract the Text fields from the Remarks array in detailedData
                const remarks = detailedData.remarks?.map(remark => remark.Text);
    
                // For each Text, send an API request to Parts/{id}/Remarks
                if (remarks && remarks.length > 0) {
                    for (const text of remarks) {
                        try {
                            const remarkResponse = await prodApiClient.post(`Parts/${newId}/Remarks`, { remark: text });
                            console.log(`Remark migration successful for remark: ${text}`, remarkResponse.data);
                        } catch (remarkError) {
                            console.error(`Remark migration failed for remark: ${text}`, remarkError);
                        }
                    }
                } else {
                    console.log('No remarks to migrate.');
                }
            } catch (e) {
                console.error('Migration failed:', e);
            }
        } else {
            console.log('No part selected or detailed data missing for migration.');
        }
    };
    

    const handleEhuMigrate = async () => {
        if (selectedEHU) {
            const { id, ...selectedWithoutId } = selectedEHU;
            try {
                const response = await prodApiClient.post('ElectronicHousingUnits', selectedWithoutId);
                console.log('Migration successful:', response.data);
            } catch (e) {
                console.error('Migration failed:', e);
            }
        } else {
            console.log('No EHU selected for migration.');
        }
    };

    const getDuplicatesCount = (items) => {
        const serialNumbers = items.map(item => item.serialNumber || item.productCode).filter(Boolean);
        const duplicates = serialNumbers.filter((item, index) => serialNumbers.indexOf(item) !== index);
        return new Set(duplicates).size;
    };

    const renderList = (items, compareItems, type) => {
        return (
            items && items.map(item => {
                const identifier = item.serialNumber || item.productCode;
                const isDuplicate = items.filter(i => (i.serialNumber || i.productCode) === identifier).length > 1;
                const isMatched = compareItems.some(i => (i.serialNumber || i.productCode) === identifier);
                const bgColor = isMatched ? 'bg-red-400' : isDuplicate ? 'bg-yellow-400' : 'bg-white';

                return (
                    <div
                        key={item.id}
                        className={`mb-2 p-2 hover:bg-blue-900 hover:text-white relative shadow-lg rounded ${item.id === (type === "part" ? selected?.id : selectedEHU?.id) ? "bg-green-200" : bgColor}`}
                        onMouseEnter={() => handleMouseEnter(item)}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => type === "part" ? handlePartSelect(item) : handleEHUSelect(item)}
                    >
                        <div className="flex items-center justify-between">
                            <div>
                                <div className="font-semibold">ID: {item.id}</div>
                                <div>Serial Number: {identifier}</div>
                            </div>
                        </div>
                        {hoveredItemId === item.id && (
                            <div className="absolute bg-black text-gray-200 p-2 border rounded shadow-lg z-10">
                                <pre className="whitespace-pre-wrap">
                                    {/* {JSON.stringify(item, null, 2)} */}
                                    <JsonRenderer data={item} />
                                </pre>
                            </div>
                        )}
                    </div>
                );
            })
        );
    };

    const filterItems = (items, query, type) => {
        const identifierKey = type === "part" ? "serialNumber" : "productCode";
        return items.filter(item => item[identifierKey]?.includes(query));
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div>
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Search Parts by Serial Number"
                    value={partsSearchQuery}
                    onChange={(e) => setPartsSearchQuery(e.target.value)}
                    className="p-2 border rounded shadow"
                />
            </div>
            <div className="flex space-x-4">
                <div className="w-1/2">
                    <h2 className="text-xl font-bold mb-4">Dev API Parts</h2>
                    <h3 className="text-medium font-semibold mb-4">Total: {devParts.length} (Duplicates: {getDuplicatesCount(devParts)})</h3>
                    <div className="max-h-96 overflow-y-scroll">
                        {renderList(filterItems(devParts, partsSearchQuery, "part"), prodParts, "part")}
                    </div>
                </div>
                <div>
                    <button
                        className="px-4 py-2 rounded shadow-lg bg-gray-200 hover:bg-blue-900 hover:text-white"
                        onClick={handleMigrate}
                    >
                        Migrate
                    </button>
                </div>
                <div className="w-1/2">
                    <h2 className="text-xl font-bold mb-4">Prod API Parts</h2>
                    <h3 className="text-medium font-semibold mb-4">Total: {prodParts.length} (Duplicates: {getDuplicatesCount(prodParts)})</h3>
                    <div className="max-h-96 overflow-y-scroll">
                        {renderList(filterItems(prodParts, partsSearchQuery, "part"), devParts, "part")}
                    </div>
                </div>
            </div>
            <div className="my-4">
                <input
                    type="text"
                    placeholder="Search EHUs by Serial Number"
                    value={ehusSearchQuery}
                    onChange={(e) => setEhusSearchQuery(e.target.value)}
                    className="p-2 border rounded shadow"
                />
            </div>
            <div className="flex space-x-4 mt-4">
                <div className="w-1/2">
                    <h2 className="text-xl font-bold mb-4">Dev API EHUs</h2>
                    <h3 className="text-medium font-semibold mb-4">Total: {devEhus.length} (Duplicates: {getDuplicatesCount(devEhus)})</h3>
                    <div className="max-h-96 overflow-y-scroll">
                        {renderList(filterItems(devEhus, ehusSearchQuery, "ehu"), prodEhus, "ehu")}
                    </div>
                </div>
                <div>
                    <button
                        className="px-4 py-2 rounded shadow-lg bg-gray-200 hover:bg-blue-900 hover:text-white"
                        onClick={handleEhuMigrate}
                    >
                        Migrate
                    </button>
                </div>
                <div className="w-1/2">
                    <h2 className="text-xl font-bold mb-4">Prod API EHUs</h2>
                    <h3 className="text-medium font-semibold mb-4">Total: {prodEhus.length} (Duplicates: {getDuplicatesCount(prodEhus)})</h3>
                    <div className="max-h-96 overflow-y-scroll">
                        {renderList(filterItems(prodEhus, ehusSearchQuery, "ehu"), devEhus, "ehu")}
                    </div>
                </div>
            </div>
            {detailedData && (
                <div className="mt-4">
                    <h3 className="text-lg font-semibold">Detailed Data:</h3>
                    <JsonRenderer data={detailedData} />
                </div>
            )}
        </div>
    );
};

export default MigrationView;
