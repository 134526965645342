import React from 'react';
import DefaultSelect from '../Default/DefaultSelect';

export default function PartsStatusSelect({onChange, required}) {
    const options = [
        {value: "Ordered", label: "Ordered",},
        {value: "Warehouse IME", label: "Warehouse IME",},
        {value: "Warehouse Assembly", label: "Warehouse Assembly",},
        {value: "Assembled", label: "Assembled",},
        {value: "Active", label: "Active",},
        {value: "Deprecated", label: "Deprecated",},
    ];

    return (
        <>
            <DefaultSelect required={required} options={options} onChange={(e) => onChange(e)}
                           label="Parts status"/>
        </>
    )
}