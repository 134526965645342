import {useNavigate, useParams} from 'react-router-dom';
import useApi from "../../../utility/Hooks/Api/useApi";
import React, {useEffect, useState} from "react";
import {CompanyApi} from "../../../services/Api/CompanyApi";
import {OrderApi} from "../../../services/Api/OrderApi";
import {PartSpecificationsApi} from "../../../services/Api/PartSpecificationsApi";
import {IdentificationIcon} from "@heroicons/react/24/solid/esm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandshake} from "@fortawesome/free-regular-svg-icons";
import RemarkTextArea from "../../../components/Inputs/Remark/RemarkTextArea";
import moment from "moment";
import {ElectronicHousingUnitApi} from "../../../services/Api/ElectronicHousingUnitApi";
import ehuAssemblyTypes from "../../../assets/lists/Types/ehuAssemblyTypes"
import ehuInstallationTypes from "../../../assets/lists/Types/ehuInstallationTypes"

export default function OrderDetailView() {
    const {orderId: id, orderType: type} = useParams();
    const ehuTypes = type === "assembly" ? [...ehuAssemblyTypes] : [...ehuInstallationTypes];
    const {request: orderRequest, data: orderData, loading: orderLoading} = useApi();
    const {request: companyRequest, data: companyData, loading: companyLoading} = useApi();
    const {request: partSpecificationRequest, data: partSpecificationData, loading: partSpecificationLoading} = useApi();
    const {request: ehuRequest, data: ehuData, loading: ehuLoading} = useApi();
    const [newRemark, setNewRemark] = useState("");

    useEffect(() => {
        orderRequest(OrderApi.getOrder(id));
        companyRequest(CompanyApi.getCompanies());
        partSpecificationRequest(PartSpecificationsApi.getPartSpecifications());
    }, []);

    useEffect(() => {
        if (orderData?.["electronicHousingUnitIds"]?.length > 0) {
            ehuRequest(ElectronicHousingUnitApi.getElectronicHousingUnit(orderData["electronicHousingUnitIds"][0]))
        }
    }, [orderData]);

    const navigate = useNavigate();

    const newRemarkHandler = (e) => {
        setNewRemark(e.target.value)
    };

    const newRemarkRequestHandler = (e) => {
        if (newRemark !== "") {
            const obj = {
                "remark": newRemark.toString()
            };
            orderRequest(OrderApi.remarkOrder(id, obj));
            setNewRemark("")
        }
    };

    const newRemarkCancelHandler = (e) => {
        setNewRemark("")
    };

    const reverseArray = (arr) => {
        return arr.slice().reverse()
    };

    const partsHandler = () => {
        return ehuTypes.find(function (ehu) {
            console.log("ehu",ehu)
            return ehu["type"] === ehuData["productType"]
        })
    };

    const hasEhu = () => {
        return orderData?.["electronicHousingUnitIds"]?.length > 0 ? ehuData : true
    };

    // const getEhuProductType = () => {
    //   return orderData?.["electronicHousingUnitIds"]?.length > 0 ? ehuData["productType"] : "Not specified"
    // };

    /*TODO nakijken van de manier hoe dit wordt ingeladen*/
    const getEhuProductType = () => {
        return orderData?.["ehuProductType"] ? orderData["ehuProductType"]["name"] : "Not specified"
    };

    return (
        <>
            {(orderData && companyData && partSpecificationData && hasEhu()) ?
                <div className="relative container mx-auto bg-white rounded-lg shadow-2xl min-h-[46.4rem]">
                    <div className="mb-1 sm:mb-4 justify-between w-full items-center">
                        <div>
                            <div className="mb-0 px-4 pt-4 border-0">
                                <div className="flex lg:flex-row flex-col">
                                    <div className="relative px-4 max-w-full">
                                        <h3 className="font-semibold text-lg text-gray-800">Order - {orderData["type"]["name"]}</h3>
                                    </div>
                                    <div className="relative px-4 max-w-full flex lg:flex-col text-sm lg:border-l-2">
                                        <span className="flex lg:mr-0 mr-1">Status:</span>
                                        <span className="flex text-indigo-600">{orderData["status"]["name"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="rounded-t mb-0 pt-1 pb-4 px-4 border-0">
                                    <div className="relative px-4 max-w-full flex flex-row items-center">
                                        <IdentificationIcon className="h-5 w-5 text-gray-600" aria-hidden="true"/>
                                        <span className="text-gray-500 px-2 text-sm">{id}</span>
                                    </div>
                                    <div className="relative px-4 max-w-full flex flex-row items-center">
                                        <FontAwesomeIcon
                                            icon={faHandshake}
                                            className="h-4 w-5 text-gray-500"
                                        />
                                        <span
                                            className="text-gray-500 px-2 text-sm">{companyData?.find(e => e.id === orderData["partnerCompanyId"])?.["company name"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="relative lg:flex mb-1 sm:mb-4">
                                <div className="flex flex-row mb-1 justify-between w-full lg:mr-2">
                                    <div className="mb-0 px-4 py-4 w-full">
                                        <div className="flex flex-wrap items-center border-0">
                                            <div
                                                className="relative w-full px-4 max-w-full mt-2 mb-4 border-b-2 border-gray-100 ">
                                                <h3
                                                    className="font-semibold text-lg text-gray-800">Basic
                                                    Info</h3>
                                            </div>
                                            <div className="relative px-4 max-w-full flex w-full">
                                                <ul className="flex flex-col text-gray-500 px-2 text-sm truncate">
                                                    <li className="pb-1">Id :</li>
                                                    <li className="pb-1">Customer :</li>
                                                    <li className="pb-1">Service partner :</li>
                                                </ul>
                                                <ul className="flex flex-col text-gray-700 text-sm px-4 truncate">
                                                    <li className="pb-1 flex-1">{orderData["id"]}</li>
                                                    <li className="pb-1 flex-1">{companyData?.find(e => e.id === orderData["customerCompanyId"])?.["company name"]}</li>
                                                    <li className="pb-1 flex-1">{companyData?.find(e => e.id === orderData["partnerCompanyId"])?.["company name"]}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row mb-1 justify-between w-full lg:ml-2">
                                    <div className="mb-0 px-4 py-4 border-0 w-full">
                                        <div className="flex flex-wrap items-center">
                                            <div
                                                className="relative w-full px-4 max-w-full flex-grow flex-1 mt-2 mb-4 flex justify-between border-b-2 border-gray-100 items-center">
                                                <h3
                                                    className="font-semibold text-lg text-gray-800">{ getEhuProductType()}
                                                    <span className="text-sm ml-2 text-gray-400">( {type === "assembly" ? orderData["electronicHousingUnitIds"].length : orderData["electronicHousingUnitIds"].length} )</span>
                                                </h3>
                                            </div>
                                            <div className="relative px-4 max-w-full flex w-full">
                                                <ul className="flex flex-col px-2 text-sm w-full">
                                                    {ehuData ? Object.keys(partsHandler()["orderParts"]).map((key, i) => {
                                                            return (<li className="pb-1">
                                                                    <div className="flex space-x-4">
                                                                        <div className="flex-1 min-w-0">
                                                                            <p className="text-sm text-gray-500 truncate">
                                                                                <span>{partsHandler()["orderParts"][key]["name"]} : </span>
                                                                            </p>
                                                                        </div>
                                                                        <div className="flex-1 min-w-0">
                                                                            <p className="text-sm truncate flex">
                                                                                      <span
                                                                                          className="px-2 text-gray-700">{(partsHandler()["orderParts"][key]["count"] * orderData["electronicHousingUnitIds"].length)}x</span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        }) : null}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative lg:flex mb-1 sm:mb-4">
                                <div className="flex flex-row mb-1 justify-between w-full lg:mr-2">
                                    <div className="mb-0 px-4 py-4 border-0 w-full">
                                        <div className="flex flex-wrap items-center">
                                            <div
                                                className="relative w-full px-4 max-w-full flex-grow flex-1 mt-2 mb-4 flex justify-between border-b-2 border-gray-100 items-center">
                                                <h3
                                                    className="font-semibold text-lg text-gray-800">Electronic Housing Unit(s)
                                                </h3>
                                            </div>
                                            <div className="relative px-4 max-w-full flex w-full">
                                                <ul className="flex flex-col px-2 text-sm w-full">
                                                    {orderData["electronicHousingUnitIds"].map((ehu) => {
                                                        return (
                                                            <li className="pb-1">
                                                                <div className="flex space-x-4">
                                                                    <div className="flex-1 min-w-0">
                                                                        <p className="text-sm truncate flex">
                                                                        <span
                                                                            className="px-2 text-gray-700 hover:text-indigo-400 cursor-pointer"
                                                                            onClick={() => navigate(`/main/electronic-housing-unit/${ehu}`)}>{ehu}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {type === "installation"
                                    ? <div className="flex flex-row mb-1 justify-between w-full lg:ml-2">
                                        <div className="mb-0 px-4 py-4 w-full">
                                            <div className="flex flex-wrap items-center border-0">
                                                <div
                                                    className="relative w-full px-4 max-w-full mt-2 mb-4 border-b-2 border-gray-100 ">
                                                    <h3
                                                        className="font-semibold text-lg text-gray-800">Vehicle(s)</h3>
                                                </div>
                                                <div className="relative px-4 max-w-full flex w-full">
                                                    <ul className="flex flex-col px-2 text-sm w-full">
                                                        {orderData["vehicleIds"].map((vehicle) => {
                                                            return (
                                                                <li className="pb-1">
                                                                    <div className="flex space-x-4">
                                                                        <div className="flex-1 min-w-0">
                                                                            <p className="text-sm truncate flex">
                                                                        <span
                                                                            className="px-2 text-gray-700 hover:text-indigo-400 cursor-pointer"
                                                                            onClick={() => navigate(`/main/vehicle/${vehicle}`)}
                                                                        >{vehicle}</span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : <div className="flex flex-row mb-1 justify-between w-full lg:mr-2"/>}
                            </div>
                            <div className="flex flex-row mb-1 justify-between w-full items-center lg:mr-2 pb-4">
                                <div className="mb-0 px-4 py-4 border-0 w-full">
                                    <div className="flex flex-wrap items-center">
                                        <div className="relative w-full px-4 max-w-full flex-grow flex-1"><h3
                                            className="font-semibold text-lg text-gray-800 border-b-2 border-gray-100 py-2">Remarks</h3>
                                        </div>
                                    </div>
                                    <RemarkTextArea
                                        required
                                        onChange={e => newRemarkHandler(e)}
                                        value={newRemark}
                                        confirm={() => newRemarkRequestHandler()}
                                        cancel={() => newRemarkCancelHandler()}/>
                                    {reverseArray(orderData["remarks"]).map((remark, index) => {
                                        return (<div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                                <div className="flex flex-wrap items-center pt-4">
                                                    <div
                                                        className="text-indigo-600 text-base font-semibold mr-2">{remark["name"]}</div>
                                                    <div
                                                        className="text-gray-500 text-sm">{moment(remark["on"]).format("DD-MM-yyyy").toString()}
                                                    </div>
                                                </div>
                                                <div
                                                    className="text-gray-400 text-sm border-b border-gray-200 pb-4 pt-2">{remark["text"]}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null}
        </>
    )
}