import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import useApi from '../../../utility/Hooks/Api/useApi';
import {CheckCircleIcon, XCircleIcon} from '@heroicons/react/24/solid';
import DefaultAddFormHeader from '../../../components/Forms/Helpers/FormHeaders/DefaultAddFormHeader';
import DefaultAddFormNavigation from "../../../components/Forms/Helpers/FormNavigation/DefaultAddFormNavigation";
import {PartsApi} from '../../../services/Api/PartsApi';
import {ElectronicHousingUnitApi} from "../../../services/Api/ElectronicHousingUnitApi";
import {IdentificationIcon, MinusSmallIcon} from "@heroicons/react/24/solid/esm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBarcode} from "@fortawesome/free-solid-svg-icons";
import {PartSpecificationsApi} from "../../../services/Api/PartSpecificationsApi";
import {TrashIcon} from '@heroicons/react/24/outline'
import {VehicleApi} from "../../../services/Api/VehicleApi";
import {CompanyApi} from "../../../services/Api/CompanyApi";
import {OrderApi} from "../../../services/Api/OrderApi";

export default function VehicleInstallationLinkForm({}) {
    const navigate = useNavigate();
    const {data, request, loading, error} = useApi();
    const {request: partsRequest, data: partsData, loading: partsLoading, error: partsError} = useApi();
    const {request: ehuLinkRequest, data: ehuLinkData, loading: ehuLinkLoading, error: ehuLinkError} = useApi();
    const {request: vehicleRequest, data: vehicleData, loading: vehicleLoading, error: vehicleError} = useApi();
    const {request: specRequest, data: specData, loading: specLoading} = useApi();
    const {vehicleId: id, orderId: orderId} = useParams();
    const [string, setString] = useState({value: ""});
    const [scanned, setScanned] = useState([]);
    const [serialNumbers, setSerialNumbers] = useState([]);
    const [openTab, setOpenTab] = useState(1);

    const lastIndex = 2;

    useEffect(() => {
        partsRequest(ElectronicHousingUnitApi.getElectronicHousingUnits());
        specRequest(CompanyApi.getCompanies())
        vehicleRequest(VehicleApi.getVehicle(id))
    }, []);


    const inputElement = useRef(null);

    useEffect(() => {
        const handleKeyDown = (event) => {
            // console.log('A key was pressed', event.keyCode);
        };

        window.addEventListener('keydown', function (event) {
            if (inputElement.current) {
                inputElement.current.focus();
            }
        });
        return () => {
            window.removeEventListener('keydown', null);
        };
    }, [inputElement]);

    useEffect(() => {
        if (string.value !== "") {
            const part = partsData?.find(function (element) {
                return element["productCode"] === string.value;
            });

            const id = scanned.find(function (element) {
                return element?.["id"] === part?.["id"];
            });

            if (part && !id) {
                setScanned([...scanned, part]);
                setString({value: ""});
            }
        }
    }, [string]);

    const stringHandler = (e) => {
        setString({...string, value: string.value + e.target.value})
    };

    const [itemData, setItemData] = useState(
        {
            "serialNumber": null,
            "reference": "Ordered",
            "location": "Manufacturer",
            "orderNumber": null,
            "partSpecificationsId": null,
            "orderDate": "",
            "shippingDate": ""
        }
    );

    const tabItems = [
        {title: "Parts", subtitle: "Scan your parts"},
    ];

    const linkRequest = () => {
        scanned.map(async (serialNumber) => {
            // ehuLinkRequest(ElectronicHousingUnitApi.addPart(id, serialNumber["id"]))
            await ehuLinkRequest(OrderApi.addEhu(orderId, serialNumber["id"]))
            await ehuLinkRequest(VehicleApi.addEhu(id, serialNumber["id"]))
        });
    };

    const nextBtnHandler = (e) => {
        e.preventDefault();

        const positionLastIndex = openTab >= lastIndex && !loading;
        const nextCondition = openTab === (lastIndex - 1);
        const continueCondition = positionLastIndex && !error;
        const retryCondition = positionLastIndex && error;


        if (nextCondition) {
            linkRequest();
            setOpenTab(lastIndex)
        } else if (continueCondition) {
            navigate("/installation");
        } else if (retryCondition) {
            linkRequest();
        } else {
            setOpenTab(openTab + 1)
        }
    };

    const vehicleDetailForm = (obj) => {
        return <div>
            <div className="text-indigo-700 text-2xl text-center w-full flex justify-center items-center font-semibold mt-6">EHU Installation {obj["reference"]}</div>
            <div className="relative px-4 max-w-full flex w-full flex justify-center mt-6">
                <ul className="flex flex-col text-gray-500 px-2 text-sm truncate">
                    <li className="mb-1">Vin :</li>
                    <li className="mb-1">Reference :</li>
                    {/*<li className="pb-1">Owner :</li>*/}
                    <li className="mb-1">Type :</li>
                    <li className="mb-1">Category :</li>
                    <li className="mb-1">Brand :</li>
                    <li className="mb-1">Model :</li>
                    <li className="mb-1">Number plate :</li>
                    <li className="mb-1">Country of origin :</li>
                </ul>
                <ul className="flex flex-col text-gray-700 text-sm px-4 truncate">
                    <li className="mb-1 flex-1">{obj.vin}</li>
                    <li className="mb-1 flex-1">{obj.reference}</li>
                    {/*<li className="pb-1 flex-1">{companyData?.find(e => (e["customer id"].toString() === vehicleData.owner.toString() || e["id"].toString() === vehicleData.owner.toString()))?.["company name"]}</li>*/}
                    <li className="mb-1 flex-1">{obj["type"]}</li>
                    <li className="mb-1 flex-1">{obj.category}</li>
                    <li className="mb-1-1 flex-1">{obj.brand}</li>
                    <li className="mb-1 flex-1">{obj.model}</li>
                    <li className="mb-1 flex-1">{obj.plateNumber}</li>
                    <li className="mb-1 flex-1">{obj.numberPlateCountry}</li>
                </ul>
            </div>
        </div>
    };

    const removePart = (partId) => {
        const newArr =  scanned.filter((part) => {return part["id"] !== partId});
        setScanned(newArr)
    };

    return (
        <>
            <DefaultAddFormHeader items={tabItems} openTab={openTab}/>
            <div>
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                    <div className="w-full md:w-[100%] px-4 flex flex-col items-center h-[30.1rem]">
                        <input
                            className="caret-transparent !outline-none border-transparent ring-0 focus:border-transparent focus:ring-0"
                            type="text" name="scanner" ref={inputElement} value={""} onChange={e => stringHandler(e)}/>

                        <div className="flex md:flex-row w-full flex-col">
                            <div className="md:w-[40%]">
            {vehicleData && specData ?
                vehicleDetailForm(vehicleData) : null}
                            </div>
                            <div className="relative px-4 max-w-full flex md:w-[60%]">
                                <ul className="flex flex-col text-sm w-full border-2 border-indigo-200 bg-indigo-200 rounded shadow-md">
                                    {scanned.map((part) => {
                                        const spec = specData?.find(e => e.id === part["customerId"])
                                        return (<li className="px-4 py-4 border-b-2 border-indigo-200 bg-white">
                                            <div className="flex">
                                                <div className="flex-1 truncate">
                                                    EHU
                                                </div>
                                                <div className="flex-1 min-w-0">
                                                    <p className="text-sm truncate flex">
                                                        <IdentificationIcon
                                                            className="h-5 w-5 text-gray-700"
                                                            aria-hidden="true"/>
                                                        <span className="px-2 text-gray-500">{part["id"]}</span>
                                                    </p>
                                                    <p className="text-sm text-gray-00 truncate">
                                                        <FontAwesomeIcon
                                                            icon={faBarcode}
                                                            className="h-4 w-5 text-gray-700"
                                                        />
                                                        <span
                                                            className="px-2 text-gray-500">{part["productCode"]}</span>
                                                    </p>
                                                </div>
                                                <div className="flex-1 min-w-0">
                                                    <p className="text-sm font-medium text-gray-700 truncate">
                                                        Owner
                                                    </p>
                                                    <p className="text-sm text-gray-500 truncate">
                                                        {spec["company name"]}
                                                    </p>
                                                </div>
                                                <div
                                                    className="inline-flex items-center text-base font-semibold text-gray-900">
                                                    <TrashIcon className="h-5 w-5 text-red-600 cursor-pointer hover:text-red-400"
                                                               onClick={() => removePart(part["id"])}
                                                    />
                                                </div>
                                            </div>
                                        </li>)
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<div className={openTab === 2 ? "block" : "hidden"} id="link2">*/}

                {/*</div>*/}

                <div className={openTab === lastIndex ? "block" : "hidden"} id="link4">
                    {ehuLinkLoading
                        ? <div
                            className="w-full md:w-[39%] ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[39.1rem]">

                            {<svg className="animate-spin h-5 w-5 text-indigo-600"
                                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>}
                        </div>
                        : ehuLinkError
                            ?
                            <div className="ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[39.1rem]">
                                <XCircleIcon className="h-32 w-32 text-red-500" aria-hidden="true"/>
                                <span className="text-red-500 font-bold text-2xl py-3">Error</span>
                                <span
                                    className="text-gray-500 font-semibold text-gray-400 text-sm">Could not add part(s) to Electronic Housing Unit.</span>
                                <span className="text-gray-500 font-semibold text-gray-400 text-sm">Please check if parts are scanned correctly or try again later.</span>
                            </div>
                            : <div
                                className="ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[39.1rem] text-center">
                                <CheckCircleIcon className="h-32 w-32 text-green-500 " aria-hidden="true"/>
                                <span className="text-green-500 font-bold text-2xl py-3">Success</span>
                                <span
                                    className="font-semibold text-gray-400 text-sm">Successfully added part(s) to Electronic Housing Unit.</span>
                            </div>
                    }
                </div>
                <DefaultAddFormNavigation open={openTab}
                                          lastIndex={lastIndex}
                                          error={error}
                                          loading={loading}
                                          data={data}
                                          previousBtnHandler={() => setOpenTab(openTab - 1)}
                                          nextBtnHandler={(e) => nextBtnHandler(e)}
                />
            </div>
        </>
    )
}