import React, {useEffect, useMemo, useState} from 'react';
import useApi from '../../utility/Hooks/Api/useApi';
import {CompanyApi} from "../../services/Api/CompanyApi";
import FunctionalTableHeader from "../../components/Tables/Headers/FunctionalTableHeader";
import DefaultTable from "../../components/Tables/Bodies/DefaultTable";

export default function CompaniesView() {
    const {request, data} = useApi();
    const [checkItems, setCheckItems] = useState([]);
    const [q, setQ] = useState("");
    const [searchParam] = useState([
        "id",
        "company name",
        "partnerCompanyId",
        "customer id",
        "country",
        "typeId",
    ]);

    useEffect(() => {
       request(CompanyApi.getCompanies())
    },[]);

    const column = [
        // {heading: 'ID', value: 'id',},
        {heading: 'Name', value: 'company name',},
        {heading: 'Customer ID', value: 'customer id',},
        {heading: 'Country', value: 'country',},
        {heading: 'Type', value: 'typeId',},
    ];

    const searchHandler = (items) => {
        return items?.filter((item) => {
            return searchParam.some((newItem) => {
                if (newItem.includes(".")) {
                    const itemSplit = newItem.split(".");
                    return (
                        item[itemSplit[0]][itemSplit[1]]
                            ?.toString()
                            ?.toLowerCase()
                            ?.indexOf(q.toLowerCase()) > -1
                    );
                } else {
                    return (
                        item[newItem]
                            ?.toString()
                            ?.toLowerCase()
                            ?.indexOf(q.toLowerCase()) > -1
                    );
                }
            });
        });
    };

    const currentTableData = useMemo(() => {
        return searchHandler(data)
    }, [data, q]);

    return (
        <>
            <div className="relative container mx-auto shadow-2xl bg-white rounded-lg min-h-[46.4rem]">
                <FunctionalTableHeader
                    title={"Companies"}
                    onChange={(e) => setQ(e.target.value)}/>
                <DefaultTable
                    data={currentTableData}
                    column={column}
                    onNavDetail="/main/company/"/>
            </div>
        </>
    )
}