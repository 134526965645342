import React from 'react';
import VehicleAddForm from "../../../components/Forms/Vehicle/VehicleAddForm";

export default function PartsAddView() {
    return (
        <>
            <div className="relative container mx-auto shadow-2xl bg-white rounded-lg min-h-[46.4rem]">
                <VehicleAddForm/>
            </div>
        </>
    );
}