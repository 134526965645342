import React from 'react';
import { Link } from "react-router-dom";

export default function HomeView() {
    return (
        <>
            <div className="mb-8 flex justify-center">
                <div
                    className="relative overflow-hidden rounded-full py-1.5 px-4 text-sm leading-6 ring-1 ring-gray-900/10 hover:ring-gray-900/20 bg-white bg-opacity-50">
                  <span className="text-gray-600">
                    Find out about our news updates or blogs.{' '}
                      <a href="https://imefficiency.com/feed/" className="font-semibold text-indigo-600">
                      <span className="absolute inset-0" aria-hidden="true"/>
                      Read more <span aria-hidden="true">&rarr;</span>
                    </a>
                  </span>
                </div>
            </div>
            <div>
                <h1 className="text-4xl font-bold tracking-tight sm:text-center sm:text-6xl">
                    On-board solar solution for trucks
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600 sm:text-center">
                    Benefit from lower fuel consumption thanks to solar energy.
                    You can follow your truck online on the SolarOnTop dashboard (with GPS & energy data)
                    and you will receive monthly stats on performance.
                </p>
                <div className="mt-8 flex gap-x-4 sm:justify-center">
                    <Link
                        to="/auth"
                        className="inline-block rounded-lg bg-indigo-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-indigo-600 hover:bg-indigo-700 hover:ring-indigo-700"
                    >
                        Get started
                        <span className="text-indigo-200" aria-hidden="true">
                      &rarr;
                    </span>
                    </Link>
                    <a
                        href="https://imefficiency.com/"
                        className="inline-block rounded-lg px-4 py-1.5 text-base font-semibold leading-7 text-gray-900 ring-1 ring-gray-900/10 hover:ring-gray-900/20"
                    >
                        Learn more
                        <span className="text-gray-400" aria-hidden="true">
                      &rarr;
                    </span>
                    </a>
                </div>
            </div>
        </>
    );
}