import React, {useEffect} from 'react';
import useApi from '../../../utility/Hooks/Api/useApi';
import {CompanyApi} from "../../../services/Api/CompanyApi";
import DefaultSelect from '../Default/DefaultSelect';

export default function CompanySelect({onChange, label, required, types}) {
    const {request, data} = useApi();

    useEffect(() => {
        request(CompanyApi.getCompanies())
    }, []);

    const companyOptions = (e) => {
        const arr = [];
        if (types) {
            data.map((company) => {

                const obj = types.find(function (type) {
                    return type === company["typeId"];
                });
                if (obj) {
                    arr.push({value: company["id"], label: `${company["customer id"]} - ${company["company name"]}`})
                }
            });
            return arr
        } else {
            return data.map((company) => {
                return {value: company["id"], label: `${company["customer id"]} - ${company["company name"]}`}
            });
        }
    };

    return (
        <>
            <DefaultSelect required={required} options={data ? companyOptions() : []} onChange={(e) => onChange(e)}
                           label={label}/>
        </>
    )
}