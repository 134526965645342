import React from "react";

export default function DefaultAddFormNavigation({open, lastIndex, error, loading, previousBtnHandler, nextBtnHandler, data}) {
    return (
        <div
            className="absolute bottom-0 flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 rounded-b-2xl w-full">
            <div className="flex flex-1 justify-between sm:hidden">
                <div className={`${(open >= (lastIndex) && !error && !loading) ? null : 'hidden'}`}/>
                <button
                    type="button"
                    onClick={e => {
                        e.preventDefault();
                        previousBtnHandler()
                    }}
                    disabled={open === 1 || (open >= (lastIndex) && !error)}
                    className={`cursor-pointer relative min-w-[7rem] items-center rounded-md border border-gray-300 text-gray-400 bg-gray-300 hover:bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 ${(open >= (lastIndex) && !error && !loading) ? 'hidden' : null}`}
                >
                    Previous
                </button>
                <button
                    type="submit"
                    className={`cursor-pointer min-w-[7rem] relative items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-white hover:bg-gray-50  ${open >= (lastIndex - 1) ? !error ? (open >= (lastIndex) && !error)
                        ? 'bg-blue-500 hover:bg-blue-400'
                        : 'bg-green-500 hover:bg-green-400'
                        : 'bg-red-500 hover:bg-red-400'
                        : 'bg-blue-500 hover:bg-blue-400'}`}
                >
                        <span
                            className="">{open >= (lastIndex - 1) ? !error ? (open >= (lastIndex) && data) ? 'Continue' : 'Confirm' : 'Try again' : 'Next'}</span>
                </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div/>
                <div>
                    <nav className="isolate inline-flex rounded-md shadow-sm"
                         aria-label="Pagination">
                        <button
                            type="button"
                            onClick={e => {
                                e.preventDefault();
                                previousBtnHandler();
                            }}
                            disabled={open === 1 || (open >= (lastIndex) && !error)}
                            className={`cursor-pointer relative items-center rounded-md border border-gray-300 bg-gray-300 px-2 py-2 text-sm font-medium text-gray-400 hover:bg-gray-200 focus:z-20 mr-3 min-w-[8rem] ${(open >= (lastIndex) && !error && !loading) ? 'hidden' : null}`}
                        >
                            <span className="">Previous</span>
                        </button>
                        {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}

                        {nextBtnHandler ? <button
                                type="submit"
                                onClick={e => {
                                    e.preventDefault();
                                    nextBtnHandler(e);
                                }}
                                className={`cursor-pointer relative items-center rounded-md border px-2 py-2 text-sm font-medium text-white focus:z-20 min-w-[8rem] ${open >= (lastIndex - 1) ? !error ? (open >= (lastIndex) && !error)
                                    ? 'bg-blue-500 hover:bg-blue-400'
                                    : 'bg-green-500 hover:bg-green-400'
                                    : 'bg-red-500 hover:bg-red-400'
                                    : 'bg-blue-500 hover:bg-blue-400'}`}
                            >
                                <span
                                    className="">{open >= (lastIndex - 1) ? !error ? (open >= (lastIndex) && !error) ? 'Continue' : 'Confirm' : 'Try again' : 'Next'}</span>
                            </button> : <button
                                type="submit"
                                className={`cursor-pointer relative items-center rounded-md border px-2 py-2 text-sm font-medium text-white focus:z-20 min-w-[8rem] ${open >= (lastIndex - 1) ? !error ? (open >= (lastIndex) && !error)
                                    ? 'bg-blue-500 hover:bg-blue-400'
                                    : 'bg-green-500 hover:bg-green-400'
                                    : 'bg-red-500 hover:bg-red-400'
                                    : 'bg-blue-500 hover:bg-blue-400'}`}
                            >
                                <span
                                    className="">{open >= (lastIndex - 1) ? !error ? (open >= (lastIndex) && !error) ? 'Continue' : 'Confirm' : 'Try again' : 'Next'}</span>
                            </button>}
                    </nav>
                </div>
            </div>
        </div>
    )
}