import React, {useEffect} from 'react';
import useApi from '../../../utility/Hooks/Api/useApi';
import {PartSpecificationsApi} from "../../../services/Api/PartSpecificationsApi";
import DefaultSelect from '../Default/DefaultSelect';

export default function PartsSpecificationsSelect({onChange, label, required}) {
    const {request, data} = useApi();

    useEffect(() => {
        request(PartSpecificationsApi.getPartSpecifications())
    }, []);

    const PartSpecificationsOptions = (e) => {
        return data.map((specs) => ({value: specs["id"], label: `${specs["partType"]} ${specs["details"][0]?.value} ${specs["details"][1]?.value}`}));
    };

    return (
        <>
            <DefaultSelect required={required} options={data ? PartSpecificationsOptions() : []} onChange={(e) => onChange(e)} label={label}/>
        </>
    )
}