import axios from 'axios';

const version = '0.1';
const baseUrl = `https://ime-we-d-azapp-solarontop-api002.azurewebsites.net/api/v${version}/`;
const urlAuthentication = [
    'Logout', 'Users', 'Parts',
    'ElectronicHousingUnits', 'Vehicle', 'Company',
    'PartSpecifications', 'Order'];

const apiClient = axios.create({
    // baseURL: API_URL, // <- ENV variable
    baseURL: baseUrl
});

apiClient.interceptors.request.use((config) => {
        const urlCheck = urlAuthentication.some(x => config.url.includes(x));
        const session = JSON.parse(localStorage.getItem('SESSION'));

        if (urlCheck && session) {
            config.headers['Authorization'] = `Bearer ${session.token}`
        }

        return ({
            ...config,
            headers: {
                ...config.headers,
                'content-type': 'application/json',
                'Accept': '*/*',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': "GET, POST, OPTIONS",
            },
        })
    },
    error => Promise.reject(error),
);

apiClient.interceptors.response.use((response) =>
        response,
    async (error) => {
        // ...
        return Promise.reject(error);
    },
);

const {get, post, put, delete: destroy, patch} = apiClient;
export {get, post, put, destroy, patch};