import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import useApi from '../../../utility/Hooks/Api/useApi';
import {CheckCircleIcon, XCircleIcon} from '@heroicons/react/24/solid';
import DefaultInput from '../../Inputs/Default/DefaultInput';
import PartsSpecificationsSelect from "../../Selects/PartSpecifications/PartsSpecificationsSelect";
import DefaultAddFormHeader from '../Helpers/FormHeaders/DefaultAddFormHeader';
import DefaultAddFormNavigation from "../Helpers/FormNavigation/DefaultAddFormNavigation";
import {PartsApi} from '../../../services/Api/PartsApi';
import DefaultTextArea from '../../Inputs/Default/DefaultTextArea';
import DefaultDatePicker from '../../Selects/DatePicker/DefaultDatePicker';


export default function PartsAddForm({}) {
    const navigate = useNavigate();
    const {data, request, loading, error} = useApi();

    const [itemData, setItemData] = useState(
        {
            "serialNumber": null,
            "reference": "Ordered",
            "location": "Manufacturer",
            "orderNumber": null,
            "partSpecificationsId": null,
            "orderDate": "",
            "shippingDate": ""
        }
    );
    const [serialNumbers, setSerialNumbers] = useState([]);
    const [openTab, setOpenTab] = useState(1);

    const lastIndex = 4;

    const tabItems = [
        {title: "Product type", subtitle: "Part specifications"},
        {title: "Reference", subtitle: "Serial numbers of parts"},
        {title: "Order details", subtitle: "Details of ordered parts"},
    ];

    const serialNumberHandler = (e) => {
        const currentArr = e.target.value.split(/\n/);
        const newArr = [];
        currentArr.map((serial)=>{
            if(serial){newArr.push(serial)}
        });
        setSerialNumbers(newArr)
    };

    const orderNumberHandler = (e) => {
        setItemData({...itemData, orderNumber: e.target.value})
    };

    const partSpecificationsHandler = (e) => {
        setItemData({...itemData, partSpecificationsId: e.target.value})
    };

    const orderDateHandler = (e) => {
        setItemData({...itemData, orderDate: moment(e).format("MM-DD-yyyy").toString()})
    };

    const shippingDateHandler = (e) => {
        setItemData({...itemData, shippingDate: moment(e).format("MM-DD-yyyy").toString()})
    };

    const nextBtnHandler = (e) => {
        e.preventDefault();

        const positionLastIndex = openTab >= lastIndex && !loading;
        const nextCondition = openTab === (lastIndex - 1);
        const continueCondition = positionLastIndex && !error;
        const retryCondition = positionLastIndex && error;

        const createRequest = () => {
            serialNumbers.map((serialNumber) => {
                const part = {...itemData, serialNumber: serialNumber};
                request(PartsApi.createPart(part))
            });
        };

        if (nextCondition) {
            createRequest();
            setOpenTab(lastIndex)
        } else if (continueCondition) {
            navigate("/main/parts");
        } else if (retryCondition) {
            createRequest();
        } else {
            setOpenTab(openTab + 1)
        }
    };

    const getHeader = () => {
        return <div className="text-gray-600 text-opacity-50 text-2xl text-center w-full flex justify-center items-center font-semibold py-6">Parts</div>
    };

    return (
        <>
            <DefaultAddFormHeader items={tabItems} openTab={openTab}/>
            <form onSubmit={(e) => nextBtnHandler(e)}>
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                    <div
                        className="w-full md:w-[39%] ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[30.1rem]">
                        {getHeader()}
                        <div className="relative w-full py-2">
                            <PartsSpecificationsSelect required={openTab === 1} label="Part specifications"
                                                       onChange={(e) => partSpecificationsHandler(e)}/>
                        </div>
                    </div>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                    <div
                        className="w-full md:w-[39%] ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[30.1rem]">
                        {getHeader()}
                        <div className="relative w-full py-2">
                            <DefaultTextArea required={openTab === 2} label="Serial numbers"
                                             onChange={(e) => serialNumberHandler(e)}/>
                        </div>
                    </div>
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                    <div
                        className="w-full md:w-[39%] ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[30.1rem]">
                        {getHeader()}
                        <div className="relative w-full py-2">
                            <div className="relative w-full py-2">
                                <DefaultInput required={openTab === 3} type="text" label="Order number supplier"
                                              onChange={(e) => orderNumberHandler(e)}/>
                            </div>
                            {/*<div className="relative w-full py-2">*/}
                            {/*    <DefaultInput required={openTab === 3} type="text" label="Order date"*/}
                            {/*                  onChange={(e) => orderDateHandler(e)}/>*/}
                            {/*</div>*/}
                            <div className="relative w-full py-2">
                                <DefaultDatePicker type="text" label="Shipping date" onChange={(e) => shippingDateHandler(e)}/>
                            </div>
                            <div className="relative w-full py-2">
                                <DefaultDatePicker type="text" label="Order date"  onChange={(e) => orderDateHandler(e)}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={openTab === lastIndex ? "block" : "hidden"} id="link4">
                    {loading
                        ? <div
                            className="w-full md:w-[39%] ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[39.1rem]">
                            {getHeader()}
                            {<svg className="animate-spin h-5 w-5 text-indigo-600"
                                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>}
                        </div>
                        : error
                            ?
                            <div className="ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[39.1rem]">
                                <XCircleIcon className="h-32 w-32 text-red-500" aria-hidden="true"/>
                                <span className="text-red-500 font-bold text-2xl py-3">Error</span>
                                <span
                                    className="text-gray-500 font-semibold text-gray-400 text-sm">Could not add pre-assembled Part(s) to inventory.</span>
                                <span className="text-gray-500 font-semibold text-gray-400 text-sm">Please check if fields are filled in correctly or try again later.</span>
                            </div>
                            : <div
                                className="ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[39.1rem] text-center">
                                <CheckCircleIcon className="h-32 w-32 text-green-500 " aria-hidden="true"/>
                                <span className="text-green-500 font-bold text-2xl py-3">Success</span>
                                <span
                                    className="font-semibold text-gray-400 text-sm">Successfully added {serialNumbers.length} pre-assembled Part(s)</span>
                                <span className="font-semibold text-gray-400 text-sm">to inventory.</span>
                            </div>
                    }
                </div>
                <DefaultAddFormNavigation open={openTab}
                                          lastIndex={lastIndex}
                                          error={error}
                                          loading={loading}
                                          data={data}
                                          previousBtnHandler={() => setOpenTab(openTab - 1)}/>
            </form>
        </>
    )
}