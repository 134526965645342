import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import useApi from '../../../utility/Hooks/Api/useApi';
import {VehicleApi} from "../../../services/Api/VehicleApi";
import {OrderApi} from "../../../services/Api/OrderApi";
import {ElectronicHousingUnitApi} from "../../../services/Api/ElectronicHousingUnitApi";
import {CheckCircleIcon, XCircleIcon} from '@heroicons/react/24/solid';
import CompanySelect from '../../Selects/Company/CompanySelect';
import DefaultAddFormHeader from '../Helpers/FormHeaders/DefaultAddFormHeader';
import DefaultAddFormNavigation from "../Helpers/FormNavigation/DefaultAddFormNavigation";
import VehicleSelect from "../../Selects/Vehicle/VehicleSelect";
import {MinusSmallIcon, PlusSmallIcon} from "@heroicons/react/24/solid/esm";
import ElectronicHousingUnitOrderTypeSelect
    from "../../Selects/ElectronicHousingUnit/ElectronicHousingUnitOrderTypeSelect";
import DefaultInput from "../../Inputs/Default/DefaultInput";

const getTypeId = (type) => {
    switch (type) {
        case 'installation':
            return 1;
        case 'assembly':
            return 2;
        case 'reparation':
            return 3;
        default:
            return 0;
    }
};

export default function OrderAddForm({}) {
    const navigate = useNavigate();
    const {data, request, loading, error} = useApi();
    const {data: orderData, request: orderRequest, loading: orderLoading, error: orderError} = useApi();
    const {data: ehuData, request: ehuRequest, loading: ehuLoading, error: ehuError} = useApi();
    const {orderType: type} = useParams();

    const [pvPanelQuantity, setPvPanelQuantity] = useState(0);
    const [ehuQuantity, setEhuQuantity] = useState(0);
    const [openTab, setOpenTab] = useState(1);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [itemData, setItemData] = useState(
        {
            "customerCompanyId": 22,
            "partnerCompanyId": null,
            "statusId": 7,
            "typeId": getTypeId(type),
            "vehicleIds": []
        }
    );

    const [errors, setErrors] = useState({order: false})
    const [loadings, setLoadings] = useState({order: false})

    const tabItemsHandler = (type) => {
        const items = [
            {title: "Company", subtitle: "Customer and service partner"},
            {title: "Basic info", subtitle: `${type.charAt(0).toUpperCase() + type.slice(1)} order specifications`},
        ];

        switch (type) {
            case 'installation':
                items.push(
                    {title: "Vehicles", subtitle: "Target vehicle(s) for installation"}
                );
                return items;
            case 'assembly':
                return items;
            case 'reparation':
                return items;
            default:
                return items;
        }
    };

    const vehicleSelectHandler = (e) => {
        const vehicle = JSON.parse(e.target.value);
        setSelectedVehicle(parseInt(vehicle.id))
    };

    const vehicleHandler = () => {
        const listed = itemData.vehicleIds.find(function (id) {
            return id === selectedVehicle;
        });

        if (!listed) {
            const newArr = [...itemData.vehicleIds];
            newArr.push(selectedVehicle);
            const newItemData = {...itemData, vehicleIds: newArr};
            setItemData(newItemData)
        }
    };

    const customerCompanyIdHandler = (e) => {
        setItemData({...itemData, customerCompanyId: parseInt(e.target.value)})
    };

    const partnerCompanyIdHandler = (e) => {
        setItemData({...itemData, partnerCompanyId: parseInt(e.target.value)})
    };

    const pvPanelHandler = (e) => {
        setPvPanelQuantity(e.target.value)
    };

    const ehuHandler = (e) => {
        setEhuQuantity(e.target.value)
    };

    const orderPartsHandler = (e) => {
        const obj = JSON.parse(e.target.value);
        const typeId = obj.typeId;
        const productType = obj.type;
        // const arr = [];
        // Object.keys(obj["orderParts"]).map((part) => {
        //     if (isNaN(+obj["orderParts"][`${part}`]["count"])) {
        //         arr.push({...obj["orderParts"][`${part}`], count: "pv-value-not-set"})
        //     } else {
        //         arr.push(obj["orderParts"][`${part}`])
        //     }
        // });
        // setItemData({...itemData, orderParts: arr, ehuTypeId: typeId, ehuProductType: productType});
        setItemData({...itemData, ehuTypeId: typeId, ehuProductType: {
                "name": productType,
                "count": ehuQuantity
            },});
    };

    const nextBtnHandler = (e) => {
        e.preventDefault();

        const positionLastIndex = openTab >= lastIndex && !orderLoading;
        const nextCondition = openTab === (lastIndex - 1);
        const continueCondition = positionLastIndex && !orderError;
        const retryCondition = positionLastIndex && orderError;
        let orderId = null;

        const createRequest = async () => {
            setLoadings({...loadings, order: true})
            let obj = {...itemData};
            // let newOrderParts = [];
            // obj["orderParts"].map((part, index) => {
            //     const partId = obj["orderParts"][index]["id"];
            //     const partCount = obj["orderParts"][index]["count"];
            //     if (partCount === "pv-value-not-set") {
            //         newOrderParts.push({
            //             id: parseInt(partId),
            //             count: (parseInt(pvPanelQuantity) * parseInt(ehuQuantity)),
            //         })
            //     } else {
            //         newOrderParts.push({id: parseInt(partId), count: (parseInt(partCount) * parseInt(ehuQuantity)),})
            //     }
            // });
            // obj = {...itemData, orderParts: [...newOrderParts]};
           await OrderApi.createOrder(obj).then((respond) => {
               orderId = respond.data["id"];
               setErrors({...error, order: false});
               if (type === "installation") {setLoadings({...loadings, order: false})}
           }).catch(error => {
               setErrors({...error, order: true});
           })
        };

        const createEhuRequest = async () => {
            const obj = {
                "productCode": "",
                "productType": itemData.ehuProductType,
                "status": "Created",
                "assemblyDate": "",
                "assembledBy": "",
                "installationDate": "",
                "installedBy": "",
                "typeId": itemData.ehuTypeId,
                "customerId": itemData.customerCompanyId,
                "assemblyCompanyId": type === "assembly" ? itemData.partnerCompanyId : null,
                "installationCompanyId": type === "installation" ? itemData.partnerCompanyId : null,
            };

            let arr = [];
             for (let i = 0; i < ehuQuantity; i++) {
                await ElectronicHousingUnitApi.createElectronicHousingUnit(obj).then((respond) => {
                    const ehu = {...respond.data}
                    arr.push(ehu)
                })
            }
            await arr.map((ehu) => {
                 orderRequest(OrderApi.addEhu(orderId, ehu["id"]))
            })
            setLoadings({...loadings, order: false})
        };

        if (nextCondition) {
            createRequest();
            if (type === "assembly") {
                createEhuRequest()
            }
            setOpenTab(lastIndex)
        } else if (continueCondition) {
            navigate(`/main/orders/${type}`);
        } else if (retryCondition) {
            createRequest();
            if (type === "assembly") {
                createEhuRequest()
            }
        } else {
            setOpenTab(openTab + 1)
        }
    };

    useEffect(() => {
        request(VehicleApi.getVehicles())
    }, []);

    const getVehicle = (id) => {
        const vehicle = data.find(function (vehicle) {
            return vehicle["id"] === id;
        });
        return vehicle
            ? <div className="flex items-center">
                <div
                    className="mt-2 w-full outline-0 rounded border-2 py-[5px] px-[10px] text-sm text-indigo-400 ease-linear transition duration-1500 bg-opacity-50 bg-indigo-200 border-indigo-200 font-semibold">
                    {vehicle["Reference"]}
                </div>
                <MinusSmallIcon className="h-5 w-5 text-red-600 cursor-pointer hover:text-red-400 mx-2 mt-2"
                                onClick={() => vehicleRemoveHandler(id)}/>
            </div>
            : null
    };

    const vehicleRemoveHandler = (id) => {
        const newArr = [...itemData.vehicleIds];
        const newItemData = {
            ...itemData, vehicleIds: newArr.filter(function (vehicleId) {
                return vehicleId !== id
            })
        };
        setItemData(newItemData)
    };

    const lastIndex = tabItemsHandler(type).length + 1;

    const companyTypeOptions = (type) => {
        switch (type) {
            case 'installation':
                return [1,3,4];
            case 'assembly':
                return [1,5];
            case 'reparation':
                return [1];
            default:
                return [1,2,3,4,5];
        }
    };

    const getHeader = () => {
        return <div className="text-gray-600 text-opacity-50 text-2xl text-center w-full flex justify-center items-center font-semibold py-6">{`${type.charAt(0).toUpperCase() + type.slice(1)}`} order</div>
    };

    return (
        <>
            <DefaultAddFormHeader items={tabItemsHandler(type)} openTab={openTab}/>
            <form onSubmit={(e) => nextBtnHandler(e)}>
                <div className={openTab === 1 ? "block" : "hidden"} id="link2">
                    <div
                        className="w-full md:w-[39%] ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[30.1rem]">
                        {getHeader()}
                        <div className="relative w-full py-2">
                            {type === 'installation'
                                ? <CompanySelect required={openTab === 1} label="Customer"
                                                 onChange={(e) => customerCompanyIdHandler(e)}
                                                 types={[1,3]}
                                />
                                : null}
                        </div>
                        <div className="relative w-full py-2">
                            <CompanySelect required={openTab === 1} label="Service partner"
                                           onChange={(e) => partnerCompanyIdHandler(e)}  types={companyTypeOptions(type)}/>
                        </div>
                    </div>

                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                    <div
                        className="w-full md:w-[39%] ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[30.1rem]">
                        {getHeader()}
                        <div className="relative w-full py-2">
                            <ElectronicHousingUnitOrderTypeSelect required={openTab === 2}
                                                                  onChange={(e) => orderPartsHandler(e)}
                                                                  onNumberChange={(e) => pvPanelHandler(e)} type={type}/>
                        </div>
                        <div className="relative w-full py-2">
                            <DefaultInput required={openTab === 2} type="text"
                                          label="Number of electronic housing units"
                                          onChange={(e) => ehuHandler(e)}/>
                        </div>
                    </div>
                </div>
                {type === "installation" && <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                    <div
                        className="w-full md:w-[39%] ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[30.1rem]">
                        {getHeader()}
                        <div className="relative w-full py-2">
                            <div className="flex items-center">
                                <VehicleSelect required={openTab === 3} label="Target vehicle"
                                               onChange={(e) => vehicleSelectHandler(e)}/>
                                <PlusSmallIcon
                                    className="h-5 w-5 text-green-600 cursor-pointer hover:text-green-400 mx-2 mt-[21px]"
                                    onClick={selectedVehicle ? () => vehicleHandler() : null}/>
                            </div>
                            {itemData.vehicleIds.map((id) => {
                                return getVehicle(id)
                            })}
                        </div>
                    </div>
                </div>}
                <div className={openTab === lastIndex ? "block" : "hidden"} id="linkLast">
                    {loadings.order
                        ? <div
                            className="w-full md:w-[39%] ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[30.1rem]">
                            {getHeader()}
                            {<svg className="animate-spin h-5 w-5 text-indigo-600"
                                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>}
                        </div>
                        : errors.order
                            ?
                            <div className="ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[39.1rem]">
                                <XCircleIcon className="h-32 w-32 text-red-500" aria-hidden="true"/>
                                <span className="text-red-500 font-bold text-2xl py-3">Error</span>
                                <span
                                    className="text-gray-500 font-semibold text-gray-400 text-sm">Could not add {type} order.</span>
                                <span className="text-gray-500 font-semibold text-gray-400 text-sm">Please check if fields are filled in correctly or try again later.</span>
                            </div>
                            : <div
                                className="ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[39.1rem] text-center">
                                <CheckCircleIcon className="h-32 w-32 text-green-500 " aria-hidden="true"/>
                                <span className="text-green-500 font-bold text-2xl py-3">Success</span>
                                <span
                                    className="font-semibold text-gray-400 text-sm">Successfully added {type} order.</span>
                            </div>
                    }
                </div>
                <DefaultAddFormNavigation open={openTab}
                                          lastIndex={lastIndex}
                                          error={errors.order}
                                          loading={loadings.order}
                                          data={data}
                                          previousBtnHandler={() => setOpenTab(openTab - 1)}/>
            </form>
        </>
    )
}