import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Logo from '../../../assets/icon/IM_Efficiency_Logo_ral5015.png'

const navigation = [
    {name: 'Companies', href: '/main/companies'},
    {name: 'Vehicles', href: '/main/vehicles'},
    {name: 'Parts', href: '/main/parts'},
    {
        name: 'Orders', href: '/main', sub: [
            {name: "Assembly", href: "/main/orders/assembly"},
            {name: "Installation", href: "/main/orders/installation"},
            // {name: "Reparation", href: "/main/orders/reparations"},
        ]
    },
    {name: 'Electronic Housing Units', href: '/main/electronic-housing-units'},
    {name: 'FAQs', href: '/main/orders/installation'},
];

export default function MainSidebar() {
    const [show, setShow] = useState(false);
    return (
        <>
            <div className="hidden lg:flex max-w-[14rem] w-[25%] mx-6 pt-14">
                <div className="fixed">
                    <div className="flex flex-col lg:min-w-0 lg:flex-1 lg:justify-center lg:gap-x-12">
                        <div className="flex flex-row py-2 px-3 leading-6 ease-linear transition-all duration-150">
                            <img
                                className="h-6 w-12 text-gray-900 hover:text-gray-600 truncate"
                                src={Logo}
                                alt=""
                            />
                            <div className="text-ellipsis overflow-hidden font-semibold leading-6 mx-4">
                                <span className="">IM</span>
                                <span className="text-blue-600">Efficiency</span>
                            </div>
                        </div>
                        <div className="py-6">
                            {navigation.map((item) => (
                                !item.sub
                                    ? <Link key={item.name} to={item.href}
                                            className="font-medium text-gray-900 hover:text-gray-600 truncate flex flex-row py-2 px-3 hover:bg-gray-400/10 rounded-lg">
                                    <span
                                        className="text-ellipsis overflow-hidden text-gray-500 text-sm"> {item.name}</span>
                                    </Link>
                                    : <>
                                        <button key={item.name}
                                                className="font-medium text-gray-900 hover:text-gray-600 truncate flex flex-row py-2 px-3 hover:bg-gray-400/10 rounded-lg w-full items-center justify-between"
                                                onClick={() => setShow(!show)}>
                                            <span
                                                className="text-ellipsis overflow-hidden text-gray-500 text-sm"> {item.name}</span>
                                            <svg className="w-4 h-4 text-gray-500" fill="currentColor"
                                                 viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd"
                                                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                      clipRule="evenodd"/>
                                            </svg>
                                        </button>
                                        {item.sub.map((subRoute) => (
                                            <Link key={subRoute.name} to={subRoute.href}
                                                  className={`font-medium text-gray-900 hover:text-gray-600 truncate flex flex-row py-2 px-3 hover:bg-gray-400/10 rounded-lg ${show ? "" : "hidden"}`}>
                                    <span
                                        className="text-ellipsis overflow-hidden text-gray-500 text-sm"> {subRoute.name}</span>
                                            </Link>
                                        ))}
                                    </>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}