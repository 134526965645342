import React from 'react';
import DefaultSelect from '../Default/DefaultSelect';
import countries from '../../../assets/lists/Countries/countries'

export default function CountrySelect({onChange, required}) {
    const options = (e) => {
        return countries.map((specs) => ({value: specs.country, label: specs.country,}));
    };

    return (
        <>
            <DefaultSelect required={required} options={options()} onChange={(e) => onChange(e)}
                           label="Country"/>
        </>
    )
}