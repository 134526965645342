import React from 'react';
import DefaultSelect from '../Default/DefaultSelect';

export default function VehicleTypeSelect({onChange, required}) {
    const options = [
        {value: "truck", label: "Truck",},
        {value: "trailer", label: "Trailer",},
        {value: "semi-trailer", label: "Semi-trailer",},
    ];

    return (
        <>
            <DefaultSelect required={required} options={options} onChange={(e) => onChange(e)}
                           label="Vehicle type"/>
        </>
    )
}