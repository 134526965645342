import {useCallback, useState} from 'react';
import {useNavigate} from "react-router";

export default (apiFunc) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const urlAuthentication = [
        'Logout', 'Users', 'Parts',
        'ElectronicHousingUnits', 'Vehicle', 'Company',
        'PartSpecifications', 'Order'];

    const request = useCallback(async (apiFunc) => {
        try {
            setLoading(true);
            await apiFunc
                .then( response =>{
                    if (error) {setError(null)}
                    setData(response.data);
                })
                .catch(error => {
                    setError(error);
                    const urlCheck = urlAuthentication.some(x => error.response.request.responseURL.includes(x));
                    const unauthorized = error.response.status === 401;
                    if (urlCheck && unauthorized) {
                        navigate("/unauthorized");
                    }
                })
        } catch (err) {
            setError("Unexpected Error!");
        } finally {
            setLoading(false);
        }
    }, [data, error, loading]);

    const reset = useCallback(async (apiFunc) => {
        setData(null);
        setError(null);
    }, []);

    const set = useCallback(async (apiFunc) => {
        setData(apiFunc);
    }, []);

    return {
        data,
        error,
        loading,
        request,
        reset,
        set
    };
};