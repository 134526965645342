import React, {useEffect} from 'react';
import useApi from '../../../utility/Hooks/Api/useApi';
import DefaultSelect from '../Default/DefaultSelect';
import {PartsApi} from "../../../services/Api/PartsApi";
import CustomSelect from "../Custom/CustomSelect";

export default function PartsSelect({onChange, label, required}) {
    const {request, data} = useApi();

    useEffect(() => {
        request(PartsApi.getParts())
    }, []);

    const PartsOptions = (e) => {
        return data.map((part) => ({value: JSON.stringify(part), label: `${part["id"]} - ${part["specification"]["PartType"]} - ${part["serialNumber"]}`}));
    };

    return (
        <>
            {/*<DefaultSelect required={required} options={data ? PartsOptions() : []} onChange={(e) => onChange(e)} label={label}/>*/}
            <CustomSelect
                options={data ? PartsOptions() : []}
                placeHolder={`Select a Part`}
                onChange={(e) => onChange(e)}
                isSearchable
            />
        </>
    )
}