import React from 'react';

export default function SearchInput({onChange, label}) {
    return (
        <>
            <label htmlFor="default-search"
                   className="mb-1 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
            <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none"
                         stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                    </svg>
                </div>
                <input type="search" id="default-search"
                       onChange={(e) => onChange(e)}
                       className="block w-full p-4 pl-10 text-sm text-gray-900 bg-white focus:border-none focus:outline-none truncate text-ellipsis overflow-hidden focus:placeholder-transparent"
                       placeholder={`Search ${label}`}/>
            </div>
        </>
    )
}