import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom';
import {Dialog} from '@headlessui/react'
import useApi from '../../../utility/Hooks/Api/useApi';
import {AuthenticationApi} from '../../../services/Api/AuthenticationApi';
import {UsersApi} from '../../../services/Api/UsersApi';
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline'
import Logo from '../../../assets/icon/IM_Efficiency_Logo_ral5015.png'

const navigation = [
    {
        name: 'Orders', href: '/assembly', sub: [
            {name: "All", href: "/assembly/orders"},
            {name: "Open", href: "/assembly/orders/open"},
            {name: "Completed", href: "/assembly/orders/completed"},
        ]
    },
];

export default function AssemblyNavbar() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [show, setShow] = useState(false);

    const navigate = useNavigate();
    const {request, data} = useApi();

    const session = JSON.parse(localStorage.getItem("SESSION"));

    useEffect(() => {
        if (session) {
            request(UsersApi.CurrentUser())
        }
    }, []);

    const logoutHandler = async (e) => {
        e.preventDefault();
        const id = data['id'];

        await request(AuthenticationApi.Logout(id));

        localStorage.removeItem("SESSION");

        navigate("/")
    };

    return (
        <>
            <div className="px-6 py-2 lg:px-8 bg-white shadow-md">
                <div>
                    <nav className="flex h-9 items-center justify-between" aria-label="Global">
                        <div className="flex lg:min-w-0 lg:flex-1" aria-label="Global">
                            <Link to="/main" className="-m-1.5 p-1.5">
                                <span className="sr-only">IM Efficiency</span>
                                <img
                                    className="h-8"
                                    src={Logo}
                                    alt=""
                                />
                            </Link>
                        </div>
                        <div className="flex lg:hidden">
                            <button
                                type="button"
                                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(true)}
                            >
                                <span className="sr-only">Open main menu</span>
                                <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                            </button>
                        </div>
                        {/*<div className="hidden lg:flex lg:min-w-0 lg:flex-1 lg:justify-center lg:gap-x-12">*/}
                        {/*    {navigation.map((item) => (*/}
                        {/*        <a key={item.name} href={item.href}*/}
                        {/*           className="font-semibold text-gray-900 hover:text-gray-600">*/}
                        {/*            <FontAwesomeIcon*/}
                        {/*                icon={item.icon}*/}
                        {/*                className="text-lg"*/}
                        {/*            />*/}
                        {/*            <span className="px-2"> {item.name}</span>*/}
                        {/*        </a>*/}
                        {/*    ))}*/}
                        {/*</div>*/}
                        <div className="hidden lg:flex lg:min-w-0 lg:flex-1 lg:justify-end">
                            <button
                                onClick={(e) => logoutHandler(e)}
                                className="inline-block rounded-lg px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900 shadow-sm ring-1 ring-gray-900/10 hover:ring-gray-900/20"
                            >
                                Log out
                            </button>
                        </div>
                    </nav>
                    <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                        <Dialog.Panel focus="true"
                                      className="fixed inset-0 z-10 overflow-y-auto bg-white px-6 py-6 lg:hidden">
                            <div className="flex h-9 items-center justify-between">
                                <div className="flex">
                                    <a href="#" className="-m-1.5 p-1.5 flex flex-row items-center justify-between">
                                        <span className="sr-only">IM Efficiency</span>
                                        <img
                                            className="h-10"
                                            src={Logo}
                                            alt=""
                                        />
                                        <div className="text-ellipsis overflow-hidden font-semibold leading-6 mx-2">
                                            <span className="">IM</span>
                                            <span className="text-blue-600">Efficiency</span>
                                        </div>
                                    </a>
                                </div>
                                <div className="flex">
                                    <button
                                        type="button"
                                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                                        onClick={() => setMobileMenuOpen(false)}
                                    >
                                        <span className="sr-only">Close menu</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>
                                </div>
                            </div>
                            <div className="mt-6 flow-root">
                                <div className="-my-6 divide-y divide-gray-500/10">
                                    <div className="space-y-2 py-6">
                                        {navigation.map((item) => (
                                            !item.sub
                                                ? <a
                                                    key={item.name}
                                                    href={item.href}
                                                    className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-400/10 flex flex-row"
                                                >
                                                    <span className="px-2"> {item.name}</span>
                                                </a>
                                                :  <>
                                                    <button key={item.name}
                                                            className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-400/10 flex flex-row w-full items-center justify-between"
                                                            onClick={() => setShow(!show)}>
                                            <span
                                                className="px-2"> {item.name}</span>
                                                        <svg className="w-4 h-4 text-gray-500" fill="currentColor"
                                                             viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd"
                                                                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                                  clipRule="evenodd"/>
                                                        </svg>
                                                    </button>
                                                    {item.sub.map((subRoute) => (
                                                        <Link key={subRoute.name} to={subRoute.href}
                                                              className={`font-medium text-gray-900 hover:text-gray-600 truncate flex flex-row py-2 px-2 hover:bg-gray-400/10 rounded-lg ${show ? "" : "hidden"}`}>
                                    <span
                                        className="text-ellipsis overflow-hidden text-gray-500 text-sm"> {subRoute.name}</span>
                                                        </Link>
                                                    ))}
                                                </>
                                        ))}
                                    </div>
                                    <div className="py-6">
                                        <button
                                            onClick={(e) => logoutHandler(e)}
                                            className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-400/10"
                                        >
                                            Log out
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Dialog>
                </div>
            </div>
        </>
    );
}