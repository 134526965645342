import { get, post, put, destroy} from './../Client/BaseClient';

export const PartsApi = {
    getParts: () =>
        get('Parts'),
    getPart: (id) =>
        get(`Parts/${id}`),
    createPart: (param) =>
        post('Parts', param),
    updatePart: (id, param) =>
        put(`Parts/${id}`, param),
    deletePart: (id) =>
        destroy(`Parts/${id}`),
    getElectronicHousingUnit: (id) =>
        get(`Parts/${id}/ElectronicHousingUnits`),
    remarkPart: (id, param) =>
        post(`Parts/${id}/Remarks`, param),
};