import React from "react";

export default function DefaultAddFormHeader({items, openTab}) {
    return (
        <>
            <form>
                <div className="flex flex-wrap justify-center ">
                    <div className="w-full lg:order-3 text-center lg:self-center container border-b-[3px]">
                        <ul role="tablist" className="inline-flex w-full text-gray-500">
                            {
                                items.map((item, index) => {
                                    const tabNumber = index + 1;
                                    const {title, subtitle} = item;
                                    return <li
                                        className={`${tabNumber === 1
                                            ? "rounded-tl-lg"
                                            : ""} whitespace-nowrap px-6 py-8 -mb-[3px] font-semibold text-gray-500 lg:min-w-[12.5rem]` +
                                        (openTab === tabNumber
                                            ? "text-gray-400 border-b-2 border-indigo-200 bg-indigo-200 bg-opacity-50 border-b-[3px]"
                                            : "border-blueGray-800 hover:text-blueGray-300")}>
                                        <span
                                           aria-disabled={true}
                                           role="tablist"
                                           data-toggle="tab"
                                           className="cursor-default flex flex-row text-left justify-center items-center text-xs text-gray-600">
                                       <span className={"py-1 px-3 rounded-md mx-3 " +
                                       (openTab === tabNumber
                                           ? "text-indigo-500 bg-indigo-200 bg-opacity-50"
                                           : "text-gray-400 bg-gray-200 bg-opacity-50")}>{tabNumber} </span>
                                            <div className="flex flex-col">
                                        <span className={"mx-2 text-sm " +
                                        (openTab === tabNumber
                                            ? "text-gray-500 "
                                            : "text-gray-800 ")}>{title}</span>
                                                <span className={"mx-2 " +
                                                (openTab === tabNumber
                                                    ? "text-indigo-300 text-xs "
                                                    : "text-gray-400")}>{subtitle}</span>
                                            </div>
                                        </span></li>
                                })
                            }
                            <li className="hidden px-4 py-2 font-semibold rounded-t opacity-50 w-full">
                                <a
                                    href="" className="pointer-events-none"></a></li>
                        </ul>
                    </div>
                </div>
            </form>
        </>
    )
};