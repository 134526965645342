import { get, post, put} from './../Client/BaseClient';

export const UsersApi = {

    UpdateUser: (id, param) =>
        put(`Users/${id}`, param),
    CurrentUser: () =>
        get('Users/Current'),
    getAllUsers: () =>
        get('Users/List'),
    CreateUser: (param) =>
        post('Users', param),
    SendActivationEmail: (id, param) =>
        post(`Users/${id}/sendactivationemail`, param),
    ActivateUser: (id, param) =>
        post(`Users/${id}/activate`, param),
    BlockUser: (id) =>
        post(`Users/${id}/block`),
    UnblockUser: (id) =>
        post(`Users/${id}/unblock`),
    SendPasswordResetEmail: (param) =>
        post('Users/sendpasswordresetmail', param),
    ResetPassword: (id, param) =>
        post(`Users/${id}/resetpassword`, param),
    ChangePassword: (param) =>
        post('Users/changepassword', param),
};