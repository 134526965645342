import React, {useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFloppyDisk, faRectangleXmark} from "@fortawesome/free-solid-svg-icons";

export default function RemarkTextArea({onChange, label, required, value, confirm, cancel}) {
    const ref = useRef();
    const [hasFocus, setFocus] = useState(false);

    useEffect(() => {
        if (document.hasFocus() && ref.current.contains(document.activeElement)) {
            setFocus(true);
        }
    }, []);
    return (
        <div className="w-full relative flex justify-center bg-white flex-row">
            <div className="w-full relative pt-[15px] px-4">
        <textarea id="message" rows="15"
                  required={required}
                  className={"overflow-hidden resize-none max-h-10 textarea-field p-2 w-full text-sm text-gray-800 bg-white rounded-lg border-2 focus:outline-none ease-linear transition duration-1500 " + (hasFocus || value !== "" ? "pb-40" : "")}
                  placeholder="Type a new remark here"
                  onChange={(e) => onChange(e)}
                  value={value}
                  ref={ref}
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}/>
                <label htmlFor="options"
                       className="invisible textarea-label block absolute top-0 left-[10px] p-[8px] bg-white text-sm ease-linear transition duration-1500 top-[18px]">{label}</label>
            </div>
            <div className={"pt-3 " + (hasFocus || value !== "" ? "" : "hidden")}>
                <FontAwesomeIcon
                    icon={faFloppyDisk}
                    className="h-4 w-4 text-gray-500 hover:text-green-500 cursor-pointer remark-textarea-interface"
                    onClick={() => confirm()}
                />
                <FontAwesomeIcon
                    icon={faRectangleXmark}
                    className="h-4 w-4 text-gray-500 hover:text-red-500 cursor-pointer"
                    onClick={() => cancel()}
                />
            </div>
        </div>
    )
}