import React from 'react';
import VehicleInstallationLinkForm from "./VehicleInstallationLinkForm";

export default function VehicleInstallationView() {
    return (
        <>
            <div className="relative container mx-auto shadow-2xl bg-white rounded-lg min-h-[46.4rem]">
                <VehicleInstallationLinkForm/>
            </div>
        </>
    );
}
