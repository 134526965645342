import React, {useEffect} from 'react';
import useApi from '../../../utility/Hooks/Api/useApi';
import DefaultSelect from '../Default/DefaultSelect';
import {ElectronicHousingUnitApi} from "../../../services/Api/ElectronicHousingUnitApi";

export default function ElectronicHousingUnitSelect({onChange, label, required}) {
    const {request, data} = useApi();

    useEffect(() => {
        request(ElectronicHousingUnitApi.getElectronicHousingUnits())
    }, []);

    const ehuOptions = (e) => {
        return data.map((ehu) => ({value: JSON.stringify(ehu), label: `${ehu["id"]} - ${ehu["productType"]} - ${ehu["productCode"]}`}));
    };

    return (
        <>
            <DefaultSelect required={required} options={data ? ehuOptions() : []} onChange={(e) => onChange(e)} label={label}/>
        </>
    )
}