import {useParams, useNavigate} from 'react-router-dom';
import useApi from "../../../utility/Hooks/Api/useApi";
import React, {useEffect, useState} from "react";
import {CompanyApi} from "../../../services/Api/CompanyApi";
import {PartsApi} from "../../../services/Api/PartsApi";
import {IdentificationIcon, MinusSmallIcon, PlusSmallIcon} from "@heroicons/react/24/solid/esm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBarcode} from "@fortawesome/free-solid-svg-icons";
import RemarkTextArea from "../../../components/Inputs/Remark/RemarkTextArea";
import moment from "moment";

export default function PartsDetailView() {
    const {partsId: id} = useParams();
    const {request: partsRequest, data: partsData, loading: partsLoading} = useApi();
    const {request: partsEhuRequest, data: partsEhuData, loading: partsEhuLoading} = useApi();
    const {request: companyRequest, data: companyData, loading: companyLoading} = useApi();
    const [newRemark, setNewRemark] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        partsRequest(PartsApi.getPart(id));
        partsEhuRequest(PartsApi.getElectronicHousingUnit(id));
        companyRequest(CompanyApi.getCompanies());
    }, []);

    const newRemarkHandler = (e) => {
        setNewRemark(e.target.value)
    };

    const newRemarkRequestHandler = (e) => {
        if (newRemark !== "") {
            const obj = {
                "remark": newRemark.toString()
            };
            partsRequest(PartsApi.remarkPart(id, obj));
            setNewRemark("")
        }
    };

    const newRemarkCancelHandler = (e) => {
        setNewRemark("")
    };

    const reverseArray = (arr) => {
        return arr.slice().reverse()
    };

    return (
        <>
            {(partsData && partsEhuData && companyData) ?
                <div className="relative container mx-auto bg-white rounded-lg shadow-2xl min-h-[46.4rem]">
                    <div className="mb-1 sm:mb-4 justify-between w-full items-center">
                        <div>
                            <div className="mb-0 px-4 pt-4 border-0">
                                <div className="flex lg:flex-row flex-col">
                                    <div className="relative px-4 max-w-full">
                                        <h3 className="font-semibold text-lg text-gray-800">{partsData["specification"]["PartType"]}</h3>
                                    </div>
                                    <div className="relative px-4 max-w-full flex lg:flex-col text-sm lg:border-l-2">
                                        <span className="flex lg:mr-0 mr-1">Status:</span>
                                        <span className="flex text-indigo-600">{partsData["reference"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="rounded-t mb-0 pt-1 pb-4 px-4 border-0">
                                    <div className="relative px-4 max-w-full flex flex-row items-center">
                                        <IdentificationIcon className="h-5 w-5 text-gray-600" aria-hidden="true"/>
                                        <span className="text-gray-500 px-2 text-sm">{id}</span>
                                    </div>
                                    <div className="relative px-4 max-w-full flex flex-row items-center">
                                        <FontAwesomeIcon
                                            icon={faBarcode}
                                            className="h-4 w-5 text-gray-500"
                                        />
                                        <span className="text-gray-500 px-2 text-sm">{partsData["serialNumber"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="relative lg:flex mb-1 sm:mb-4">
                                <div className="flex flex-row mb-1 justify-between w-full lg:mr-2">
                                    <div className="mb-0 px-4 py-4 w-full">
                                        <div className="flex flex-wrap items-center border-0">
                                            <div
                                                className="relative w-full px-4 max-w-full mt-2 mb-4 border-b-2 border-gray-100 ">
                                                <h3
                                                    className="font-semibold text-lg text-gray-800">Basic
                                                    Info</h3>
                                            </div>
                                            <div className="relative px-4 max-w-full flex w-full">
                                                <ul className="flex flex-col text-gray-500 px-2 text-sm truncate">
                                                    <li className="pb-1">Id :</li>
                                                    <li className="pb-1">Serial number :</li>
                                                    <li className="pb-1">Type :</li>
                                                    <li className="pb-1">Order number :</li>
                                                    <li className="pb-1">Order date :</li>
                                                    <li className="pb-1">Shipping date :</li>
                                                    <li className="pb-1">Last seen :</li>
                                                    <li className="pb-1">Electronic Housing Unit :</li>
                                                </ul>
                                                <ul className="flex flex-col text-gray-700 text-sm px-4 truncate">
                                                    <li className="pb-1 flex-1">{partsData["id"]}</li>
                                                    <li className="pb-1 flex-1">{partsData["serialNumber"]}</li>
                                                    <li className="pb-1 flex-1">{partsData["specification"]["PartType"]}</li>
                                                    <li className="pb-1 flex-1">{partsData["orderNumber"]}</li>
                                                    <li className="pb-1 flex-1">{partsData["orderDate"]}</li>
                                                    <li className="pb-1 flex-1">{partsData["shippingDate"]}</li>
                                                    <li className="pb-1 flex-1">{partsData["location"]}</li>
                                                    <li className="pb-1 flex-1 text-indigo-600">
                                                        <span
                                                            className="cursor-pointer"
                                                            onClick={() => navigate(`/main/electronic-housing-unit/${partsEhuData?.[0]?.["id"]}`)}
                                                        >{partsEhuData?.[0]?.["productCode"]}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row mb-1 justify-between w-full lg:ml-2">
                                    <div className="mb-0 px-4 py-4 border-0 w-full">
                                        <div className="flex flex-wrap items-center">
                                            <div
                                                className="relative w-full px-4 max-w-full flex-grow flex-1 mt-2 mb-4 flex justify-between border-b-2 border-gray-100 items-center">
                                                <h3
                                                    className="font-semibold text-lg text-gray-800">Specifications</h3>
                                            </div>
                                            <div className="relative px-4 max-w-full flex w-full">
                                                <ul className="flex flex-col px-2 text-sm w-full">
                                                    {partsData["specification"]["Details"].map((spec) => {
                                                        return (<li className="pb-1">
                                                            <div className="flex space-x-4">
                                                                <div className="flex-1 min-w-0">
                                                                    <p className="text-sm text-gray-500 truncate">
                                                                        <span>{spec?.["Key"]} : </span>
                                                                    </p>
                                                                </div>
                                                                <div className="flex-1 min-w-0">
                                                                    <p className="text-sm truncate flex">
                                                                        <span
                                                                            className="px-2 text-gray-700">{spec?.["Value"]}</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>)
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row mb-1 justify-between w-full items-center lg:mr-2 pb-4">
                                <div className="mb-0 px-4 py-4 border-0 w-full">
                                    <div className="flex flex-wrap items-center">
                                        <div className="relative w-full px-4 max-w-full flex-grow flex-1"><h3
                                            className="font-semibold text-lg text-gray-800 border-b-2 border-gray-100 py-2">Remarks</h3>
                                        </div>
                                    </div>
                                    <RemarkTextArea
                                        required
                                        onChange={e => newRemarkHandler(e)}
                                        value={newRemark}
                                        confirm={() => newRemarkRequestHandler()}
                                        cancel={() => newRemarkCancelHandler()}/>
                                    {reverseArray(partsData["remarks"]).map((remark, index) => {
                                        return (<div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                                <div className="flex flex-wrap items-center pt-4">
                                                    <div
                                                        className="text-indigo-600 text-base font-semibold mr-2">{remark["Name"]}</div>
                                                    <div
                                                        className="text-gray-500 text-sm">{moment(remark["On"]).format("DD-MM-yyyy").toString()}
                                                    </div>
                                                </div>
                                                <div
                                                    className="text-gray-400 text-sm border-b border-gray-200 pb-4 pt-2">{remark["Text"]}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null}
        </>
    )
}