import {useNavigate, useParams} from 'react-router-dom';
import {IdentificationIcon, BuildingOffice2Icon, MinusSmallIcon, PlusSmallIcon} from "@heroicons/react/24/solid/esm";
import React, {useEffect, useState} from "react";
import useApi from "../../../utility/Hooks/Api/useApi";
import {CompanyApi} from "../../../services/Api/CompanyApi";
import ElectronicHousingUnitSelect from "../../../components/Selects/ElectronicHousingUnit/ElectronicHousingUnitSelect";
import VehicleSelect from "../../../components/Selects/Vehicle/VehicleSelect";

export default function CompanyDetailView() {
    const {companyId: id} = useParams();

    const {request: companyRequest, data: companyData, loading: companyLoading, set: vehicleSetEhuData,} = useApi();
    const {request: companyVehicleRequest, data: companyVehicleData, set: companySetVehicleData} = useApi();
    const {request: linkRequest, data: linkData, loading: linkLoading} = useApi();
    const [showSelect, setShowSelect] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        companyRequest(CompanyApi.getCompany(id));
        companyVehicleRequest(CompanyApi.getVehicles(id));
    }, []);

    const certificateHandler = (e) => {
        const certificate = e?.find(e => e.text.includes("certificateNumber"))?.["text"];
        return certificate ? certificate.split(": ").pop() : null
    };

    const addVehicle = (e) => {
        const vehicle = JSON.parse(e.target.value);
        linkRequest(CompanyApi.addVehicle(id, vehicle.id));
        companyVehicleData.push(vehicle);
        setShowSelect(false)
    };

    const removeVehicle = (vehicleId) => {
        linkRequest(CompanyApi.removeVehicle(id, vehicleId));
        const newArr = companyVehicleData.filter(vehicle => {
            return vehicle.id !== vehicleId;
        });
        companySetVehicleData([...newArr]);
    };

    return (
        <>
            {(companyData && companyVehicleData) ?
                <div className="relative container mx-auto bg-white rounded-lg shadow-2xl min-h-[46.4rem]">
                    <div className="mb-1 sm:mb-4 justify-between w-full items-center">
                        <div>
                            <div className="mb-0 px-4 pt-4 border-0">
                                <div className="flex lg:flex-row flex-col">
                                    <div className="relative px-4 max-w-full">
                                        <h3 className="font-semibold text-lg text-gray-800">{companyData["company name"]}</h3>
                                    </div>
                                    <div className="relative px-4 max-w-full flex lg:flex-col text-sm lg:border-l-2">
                                        <span className="flex lg:mr-0 mr-1">Type:</span>
                                        <span className="flex text-indigo-600">{companyData["type"]["Name"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="rounded-t mb-0 pt-1 pb-4 px-4 border-0">
                                    <div className="relative px-4 max-w-full flex flex-row items-center">
                                        <IdentificationIcon className="h-5 w-5 text-gray-600" aria-hidden="true"/>
                                        <span className="text-gray-500 px-2 text-sm">{id}</span>
                                    </div>
                                    <div className="relative px-4 max-w-full flex flex-row items-center">
                                        <BuildingOffice2Icon className="h-5 w-5 text-gray-600" aria-hidden="true"/>
                                        <span className="text-gray-500 px-2 text-sm">{companyData["customer id"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="relative lg:flex mb-1 sm:mb-4">
                                <div className="flex flex-row mb-1 justify-between w-full lg:mr-2">
                                    <div className="mb-0 px-4 py-4 w-full">
                                        <div className="flex flex-wrap items-center border-0">
                                            <div
                                                className="relative w-full px-4 max-w-full mt-2 mb-4 border-b-2 border-gray-100 ">
                                                <h3
                                                    className="font-semibold text-lg text-gray-800">Basic
                                                    Info</h3>
                                            </div>
                                            <div className="relative px-4 max-w-full flex w-full">
                                                <ul className="flex flex-col text-gray-500 px-2 text-sm truncate">
                                                    <li className="pb-1">Id :</li>
                                                    <li className="pb-1">Customer id :</li>
                                                    <li className="pb-1">Type :</li>
                                                    <li className="pb-1">CCO :</li>
                                                    <li className="pb-1">VAT :</li>
                                                </ul>
                                                <ul className="flex flex-col text-gray-700 text-sm px-4 truncate">
                                                    <li className="pb-1 flex-1">{companyData["id"]}</li>
                                                    <li className="pb-1 flex-1">{companyData["customer id"]}</li>
                                                    <li className="pb-1 flex-1">{companyData["type"]["Name"]}</li>
                                                    <li className="pb-1 flex-1">{companyData["cco"]}</li>
                                                    <li className="pb-1 flex-1">{companyData["vat"]}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row mb-1 justify-between w-full lg:ml-2">
                                    <div className="mb-0 px-4 py-4 border-0 w-full">
                                        <div className="flex flex-wrap items-center">
                                            <div
                                                className="relative w-full px-4 max-w-full flex-grow flex-1 mt-2 mb-4 flex justify-between border-b-2 border-gray-100 items-center">
                                                <h3
                                                    className="font-semibold text-lg text-gray-800">Adress</h3>
                                            </div>
                                            <div className="relative px-4 max-w-full flex w-full">
                                                <ul className="flex flex-col text-gray-500 px-2 text-sm truncate">
                                                    <li className="pb-1">Country :</li>
                                                    <li className="pb-1">Province :</li>
                                                    <li className="pb-1">City :</li>
                                                    <li className="pb-1">Street :</li>
                                                </ul>
                                                <ul className="flex flex-col text-gray-700 text-sm px-4 truncate">
                                                    <li className="pb-1 flex-1">{companyData["country"]}</li>
                                                    <li className="pb-1 flex-1">{companyData["province"]}</li>
                                                    <li className="pb-1 flex-1">{companyData["city"]}</li>
                                                    <li className="pb-1 flex-1">{companyData["street name"]}, {companyData["street number"]}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="flex flex-row mb-1 justify-between w-full items-center lg:mr-2 pb-4">
                                <div className="mb-0 px-4 py-4 border-0 w-full">
                                    <div className="flex flex-wrap items-center">
                                        <div className="relative w-full px-4 max-w-full flex-grow flex-1 relative mt-2 mb-4 flex justify-between border-b-2 border-gray-100 items-center">
                                            <h3 className="font-semibold text-lg text-gray-800 py-2">Vehicles</h3>
                                            <PlusSmallIcon
                                                className="h-5 w-5 text-green-600 cursor-pointer hover:text-green-400 mx-2"
                                                onClick={() => setShowSelect(!showSelect)}/>
                                        </div>
                                        <div className={`w-full px-2 mb-4 ${showSelect ? "" : "hidden"}`}>
                                            <VehicleSelect classname="w-full" required={true}
                                                                         onChange={(ehu) => addVehicle(ehu)}/>
                                        </div>
                                    </div>
                                    <div className="relative w-full px-4 max-w-full flex-grow flex-1 pt-4">
                                        <ul className="flex flex-col text-gray-700 text-sm px-4 truncate">
                                            <li className="flex flex-row pb-4 text-gray-500">
                                                <div className="pb-1 flex-1">ID</div>
                                                <div className="pb-1 flex-1">Type</div>
                                                <div className="pb-1 flex-1">Reference</div>
                                                <div className="pb-1 flex-1">Number Plate</div>
                                                <div className="pb-1 flex-1"></div>
                                                {/*<div className="pb-1 flex-1">Certificate Number</div>*/}
                                            </li>
                                            {companyVehicleData.map((vehicle, index) => {
                                                return <li className="flex flex-row">
                                                        <div className="pb-1 flex-1">{vehicle["id"]}</div>
                                                        <div className="pb-1 flex-1">{vehicle["type"]}</div>
                                                        <div onClick={() => navigate(`/main/vehicle/${vehicle["id"]}`)} className="cursor-pointer hover:text-indigo-400 pb-1 flex-1">{vehicle["reference"]}</div>
                                                        <div className="pb-1 flex-1">{vehicle["plateNumber"]}</div>
                                                        {/*<div*/}
                                                        {/*    className="pb-1 flex-1">{certificateHandler(vehicle["remarks"])}</div>*/}
                                                    <div className="pb-1 flex-1">
                                                        <MinusSmallIcon
                                                            className="h-5 w-5 text-red-600 cursor-pointer hover:text-red-400"
                                                            onClick={(e) => removeVehicle(vehicle["id"])}/>
                                                    </div>
                                                    </li>

                                            })}         </ul>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                : null}
        </>
    )
}