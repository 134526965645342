import React from 'react';

export default function DefaultTextArea({onChange, label, required}) {
    return (
        <div className="w-full relative flex justify-center items-center bg-white flex-row">
            <div className="w-full relative pt-[15px]">
        <textarea id="message" rows="15"
                  required={required}
                  className="textarea-field p-2.5 w-full text-sm text-gray-800 bg-white rounded-lg border-2 focus:outline-none ease-linear transition duration-1500"
                  placeholder="Write the serial number for each part on a new line..."
                  onChange={(e) => onChange(e)}/>
                <label htmlFor="options"
                       className="invisible textarea-label block absolute top-0 left-[10px] p-[8px] bg-white text-sm ease-linear transition duration-1500 top-[18px]">{label}</label>
            </div>
        </div>
    )
}