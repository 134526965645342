import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import useApi from '../../../utility/Hooks/Api/useApi';
import {VehicleApi} from '../../../services/Api/VehicleApi';
import {CheckCircleIcon, XCircleIcon} from '@heroicons/react/24/solid';
import DefaultInput from '../../Inputs/Default/DefaultInput';
import CompanySelect from '../../Selects/Company/CompanySelect';
import DefaultAddFormHeader from '../Helpers/FormHeaders/DefaultAddFormHeader';
import DefaultAddFormNavigation from '../Helpers/FormNavigation/DefaultAddFormNavigation';
import VehicleTypeSelect from '../../Selects/Vehicle/VehicleTypeSelect';
import VehicleCategorySelect from '../../Selects/Vehicle/VehicleCategorySelect';
import CountrySelect from '../../Selects/Country/CountrySelect';

export default function VehicleAddForm({}) {
    const navigate = useNavigate();
    const {data, request, loading, error} = useApi();

    const [itemData, setItemData] = useState(
        {
            "owner": "",
            "type": "",
            "category": "",
            "brand": "",
            "model": "",
            "numberPlate": "",
            "numberPlateCountry": "",
            "vin": "",
            "reference": "",
            "remarks": {}
        }
    );
    const [openTab, setOpenTab] = useState(1);

    const lastIndex = 4;

    const tabItems = [
        {title: "Vehicle type", subtitle: "General vehicle details"},
        {title: "Reference", subtitle: "References to vehicle"},
        {title: "Owner", subtitle: "Owner of vehicle"},
    ];

    const ownerHandler = (e) => {
        setItemData({...itemData, Owner: e.target.value})
    };

    const typeHandler = (e) => {
        setItemData({...itemData, Type: e.target.value})
    };

    const categoryHandler = (e) => {
        setItemData({...itemData, Category: e.target.value})
    };

    const brandHandler = (e) => {
        setItemData({...itemData, Brand: e.target.value})
    };

    const modelHandler = (e) => {
        setItemData({...itemData, Model: e.target.value})
    };

    const numberPlateHandler = (e) => {
        setItemData({...itemData, NumberPlate: e.target.value})
    };

    const numberPlateCountryHandler = (e) => {
        setItemData({...itemData, NumberPlateCountry: e.target.value})
    };

    const vinHandler = (e) => {
        setItemData({...itemData, Vin: e.target.value})
    };

    const referenceHandler = (e) => {
        setItemData({...itemData, Reference: e.target.value})
    };


    const nextBtnHandler = (e) => {
        e.preventDefault();

        const positionLastIndex = openTab >= lastIndex && !loading;
        const nextCondition = openTab === (lastIndex - 1);
        const continueCondition = positionLastIndex && !error;
        const retryCondition = positionLastIndex && error;

        const createRequest = () => {
            request(VehicleApi.createVehicle(itemData))
        };

        if (nextCondition) {
            createRequest();
            setOpenTab(lastIndex)
        } else if (continueCondition) {
            navigate("/main/vehicles");
        } else if (retryCondition) {
            createRequest();
        } else {
            setOpenTab(openTab + 1)
        }
    };

    const getHeader = () => {
      return <div className="text-gray-600 text-opacity-50 text-2xl text-center w-full flex justify-center items-center font-semibold py-6">Vehicle</div>
    };

    return (
        <>
            <DefaultAddFormHeader items={tabItems} openTab={openTab}/>
            <form onSubmit={(e) => nextBtnHandler(e)}>

                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                    <div
                        className="w-full md:w-[39%] ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[30.1rem]">
                        {getHeader()}
                        <div className="relative w-full py-2">
                            <VehicleTypeSelect required={openTab === 1} onChange={(e) => typeHandler(e)}/>
                        </div>
                        <div className="relative w-full py-2">
                            <VehicleCategorySelect required={openTab === 1} onChange={(e) => categoryHandler(e)}/>
                        </div>
                        <div className="relative w-full py-2">
                            <DefaultInput required={openTab === 1} type="text" label="Vehicle brand"
                                          onChange={(e) => brandHandler(e)}/>
                        </div>
                        <div className="relative w-full py-2">
                            <DefaultInput required={openTab === 1} type="text" label="Vehicle model"
                                          onChange={(e) => modelHandler(e)}/>
                        </div>
                    </div>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                    <div
                        className="w-full md:w-[39%] ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[30.1rem]">
                        {getHeader()}
                        <div className="relative w-full py-2">
                            <DefaultInput required={openTab === 2} type="text" label="Vehicle reference"
                                          onChange={(e) => referenceHandler(e)}/>
                        </div>
                        <div className="relative w-full py-2">
                            <DefaultInput required={openTab === 2} type="text" label="Vehicle vin"
                                          onChange={(e) => vinHandler(e)}/>
                        </div>
                        <div className="relative w-full py-2">
                            <DefaultInput required={openTab === 2} type="text" label="Vehicle number plate"
                                          onChange={(e) => numberPlateHandler(e)}/>
                        </div>
                        <div className="relative w-full py-2">
                            <CountrySelect required={openTab === 2} onChange={(e) => numberPlateCountryHandler(e)}/>
                        </div>
                    </div>
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                    <div
                        className="w-full md:w-[39%] ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[30.1rem]">
                        {getHeader()}
                        <div className="relative w-full py-2">
                            <CompanySelect required={openTab === 3} label="Vehicle owner"
                                           onChange={(e) => ownerHandler(e)}
                            />
                        </div>
                    </div>
                </div>
                <div className={openTab === lastIndex ? "block" : "hidden"} id="link4">
                    {loading
                        ? <div
                            className="w-full md:w-[39%] ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[39.1rem]">
                            {getHeader()}
                            {<svg className="animate-spin h-5 w-5 text-indigo-600"
                                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>}
                        </div>
                        : error
                            ?
                            <div className="ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[39.1rem]">
                                <XCircleIcon className="h-32 w-32 text-red-500" aria-hidden="true"/>
                                <span className="text-red-500 font-bold text-2xl py-3">Error</span>
                                <span
                                    className="text-gray-500 font-semibold text-gray-400 text-sm">Could not add vehicle {itemData["Reference"]}.</span>
                                <span className="text-gray-500 font-semibold text-gray-400 text-sm">Please check if fields are filled in correctly or try again later.</span>
                            </div>
                            : <div
                                className="ml-auto mr-auto px-4 flex flex-col justify-center items-center h-[39.1rem] text-center">
                                <CheckCircleIcon className="h-32 w-32 text-green-500 " aria-hidden="true"/>
                                <span className="text-green-500 font-bold text-2xl py-3">Success</span>
                                <span
                                    className="font-semibold text-gray-400 text-sm">Successfully added vehicle</span>
                                <span className="font-semibold text-gray-400 text-sm">{itemData["Reference"]}</span>
                            </div>
                    }
                </div>
                <DefaultAddFormNavigation open={openTab}
                                          lastIndex={lastIndex}
                                          error={error}
                                          loading={loading}
                                          data={data}
                                          previousBtnHandler={() => setOpenTab(openTab - 1)}/>
            </form>
        </>
    )
}