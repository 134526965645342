import React from 'react';
import DefaultSelect from '../Default/DefaultSelect';

export default function EhuStatusSelect({onChange, required}) {
    const options = [
        {value: "Created", label: "Created",},
        {value: "Assembled", label: "Assembled",},
        {value: "Warehouse, not tested", label: "Warehouse, not tested",},
        {value: "Warehouse, OK", label: "Warehouse, OK",},
        {value: "Not OK, fix", label: "Not OK, fix",},
        {value: "Picked", label: "Picked",},
        {value: "Shipped", label: "Shipped",},
        {value: "Installed", label: "Installed",},
        {value: "Active", label: "Active",},
    ];

    return (
        <>
            <DefaultSelect required={required} options={options} onChange={(e) => onChange(e)}
                           label="Parts status"/>
        </>
    )
}