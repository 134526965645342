import React from 'react';
import {usePagination, DOTS} from '../../utility/Hooks/Pagination/usePagination';
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/20/solid/esm";

const DefaultPagination = props => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <>
            <div
                className="absolute flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 rounded-b-2xl w-full">
                <div className="flex flex-1 justify-between sm:hidden">
                    <button
                        onClick={onPrevious}
                        disabled={currentPage === 1}
                        className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    >
                        Previous
                    </button>
                    <button
                        onClick={onNext}
                        disabled={currentPage === lastPage}
                        className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    >
                        Next
                    </button>
                </div>
                <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                    <div>
                        <p className="text-sm text-gray-700">
                            Showing <span className="font-medium">{((currentPage - 1) * pageSize) + 1}</span> to <span
                            className="font-medium">{(currentPage * pageSize) < totalCount ? currentPage * pageSize : totalCount}</span> of{' '}
                            <span className="font-medium">{totalCount}</span> results
                        </p>
                    </div>
                    <div>
                        {(currentPage === 0 || paginationRange.length < 2)
                            ? <div
                                className="px-4 py-2 font-semibold inline-flex rounded-t -space-x-px opacity-50 w-full h-[38px]"/>
                            : <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                                   aria-label="Pagination">
                                <button
                                    onClick={onPrevious}
                                    disabled={currentPage === 1}
                                    className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                                >
                                    <span className="sr-only">Previous</span>
                                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true"/>
                                </button>
                                {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                                {paginationRange.map((pageNumber, index) => {
                                    if (pageNumber === DOTS) {
                                        return <span
                                            className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700">...</span>;
                                    }
                                    return (
                                        <button key={index} onClick={() => onPageChange(pageNumber)}
                                                aria-current="page"
                                                className={`relative z-10 inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-20 ${pageNumber === currentPage ? "border-indigo-500 bg-indigo-50 text-indigo-600 z-20"
                                                    : "border-gray-300 bg-white text-gray-500 hover:bg-gray-50"}`}
                                        >
                                            {pageNumber}
                                        </button>
                                    );
                                })}
                                <button
                                    onClick={onNext}
                                    disabled={currentPage === lastPage}
                                    className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                                >
                                    <span className="sr-only">Next</span>
                                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </nav>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default DefaultPagination;
