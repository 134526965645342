import { get, post, destroy, put} from './../Client/BaseClient';

export const ElectronicHousingUnitApi = {
    getElectronicHousingUnits: () =>
        get('ElectronicHousingUnits'),
    getElectronicHousingUnit: (id) =>
        get(`ElectronicHousingUnits/${id}`),
    getElectronicHousingUnitParts: (id) =>
        get(`ElectronicHousingUnits/${id}/Parts`),
    createElectronicHousingUnit: (param) =>
        post('ElectronicHousingUnits', param),
    updateElectronicHousingUnit: (id, param) =>
        put(`ElectronicHousingUnits/${id}`, param),
    deleteElectronicHousingUnit: (id) =>
        destroy(`ElectronicHousingUnits/${id}`),
    remarkElectronicHousingUnit: (id, param) =>
        post(`ElectronicHousingUnits/${id}/Remarks`, param),
    addPart: (id, partId) =>
        put(`ElectronicHousingUnits/${id}/Parts/${partId}/Add`),
    removePart: (id, partId) =>
        put(`ElectronicHousingUnits/${id}/Parts/${partId}/Remove`),
};