import React, {useState} from 'react';
import DefaultSelect from '../Default/DefaultSelect';
import ehuInstallationTypes from '../../../assets/lists/Types/ehuInstallationTypes'
import ehuAssemblyTypes from '../../../assets/lists/Types/ehuAssemblyTypes'

export default function ElectronicHousingUnitOrderTypeSelect({onChange, onNumberChange, required, type}) {
    const [variation, setVariation] = useState([]);
    const options = () => {
        return type === 'installation' ? ehuInstallationTypes.map((unit) => ({value: JSON.stringify(unit), label: unit["type"],})) : ehuAssemblyTypes.map((unit) => ({value: JSON.stringify(unit), label: unit["type"],}));
    };
    const variationOptions = () => {
        return variation.map((count) => ({value: count, label: count,}));
    };

    return (
        <>
            <DefaultSelect required={required} options={options()} onChange={(e) => {
                setVariation(JSON.parse(e.target.value)["orderParts"]["pvPanel"] ? JSON.parse(e.target.value)["orderParts"]["pvPanel"]["count"] : []); onChange(e);}}
                           label="SolarOnTop type"/>
            <div className="pt-4">
            <DefaultSelect required={required} options={variationOptions()} onChange={(e) => onNumberChange(e)}
                           label="Number of pv panels" disabled={variation.length < 1}/>
            </div>
        </>
    )
}