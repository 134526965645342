import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import useApi from "../../../utility/Hooks/Api/useApi";
import {CompanyApi} from "../../../services/Api/CompanyApi";
import {PartSpecificationsApi} from "../../../services/Api/PartSpecificationsApi";
import {get} from "../../../services/Client/BaseClient";

export default function PackingSlipCompositionView({orders}) {
    const {request: companyRequest, data: companyData, loading: companyLoading} = useApi();
    const {request: partSpecificationRequest, data: partSpecificationData, loading: partSpecificationLoading} = useApi();
    const {orderType: type} = useParams();

    useEffect(() => {
        companyRequest(CompanyApi.getCompanies());
        partSpecificationRequest(PartSpecificationsApi.getPartSpecifications());
    }, []);

    const getCompanyName = (item) => {
        const company = companyData?.find(function (element) {
            return element["id"]?.toString() === item?.toString() || element["customer id"] === item?.toString();
        });
        return company ? company["company name"] : "";
    };

    const getPartName = (item) => {
        const part = partSpecificationData?.find(function (element) {
            return element["id"]?.toString() === item?.toString()
        });
        return part ? part["partType"] : "";
    };

    return (
        <>{(companyData && partSpecificationData) ?
            <div className="relative container mx-auto bg-white rounded-lg shadow-2xl min-h-[46.4rem]">
                <div className="mb-1 sm:mb-4 justify-between w-full items-center">
                    <div>
                        <div className="mb-0 px-8 pt-4 border-0 flex justify-between">
                            <div className="flex lg:flex-row flex-col">
                                <div className="relative px-4 max-w-full">
                                    <h3 className="font-semibold text-lg text-gray-800">Packing Slip</h3>
                                    <span className="flex text-indigo-600 text-sm">{type}</span>
                                </div>
                            </div>
                            <div className="flex lg:flex-row flex-col">
                                <div className="relative px-4 max-w-full">
                                    <div
                                        className="flex flex-row py-2 px-3 leading-6 ease-linear transition-all duration-150">
                                        <div className="text-ellipsis overflow-hidden font-semibold leading-6 mx-4">
                                            <span className="">IM</span>
                                            <span className="text-blue-600">Efficiency</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="relative px-4 max-w-full flex lg:flex-col text-sm lg:border-l-2">
                                    <ul className="flex flex-col text-gray-500 px-2 text-sm truncate">
                                        <li className="pb-1">Automotive Campus 30</li>
                                        <li className="pb-1">5708 HZ Helmond</li>
                                        <li className="pb-1">www.imefficiency.com</li>
                                        <li className="pb-1">Btw: NL854667155B01</li>
                                        <li className="pb-1">KvK: 62117556</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="relative lg:flex mb-1 sm:mb-4">
                            <div className="flex flex-row mb-1 justify-between w-full lg:mr-2">
                                <div className="mb-0 px-8 py-4 w-full">
                                    <div className="flex flex-wrap items-center border-0">
                                        <div className="relative px-4 max-w-full flex w-full">
                                            <ul className="flex flex-col text-gray-500 px-2 text-sm truncate">
                                                {orders.map((order) => {
                                                    const {customerCompanyId, partnerCompanyId, id, remarks} = order;
                                                    return <div className="flex">
                                                        <ul className="flex flex-col text-gray-500 px-2 text-sm truncate">
                                                            <li className="pb-1 text-xs">Order:</li>
                                                            <li className="pb-1 text-xs">Customer:</li>
                                                            <li className="pb-1 text-xs">Partner:</li>
                                                            <li className="pb-1 text-xs">SoT Package:</li>
                                                        </ul>
                                                        <ul className="flex flex-col text-gray-500 px-2 text-sm truncate">
                                                            <li className="pb-1 text-xs"> {id}</li>
                                                            <li className="pb-1 text-xs"> {getCompanyName(customerCompanyId)}</li>
                                                            <li className="pb-1 text-xs"> {getCompanyName(partnerCompanyId)}</li>
                                                            <li className="pb-1 text-xs"> {remarks}</li>
                                                        </ul>
                                                    </div>
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row mb-1 justify-between w-full items-center lg:mr-2 pb-4">
                            <div className="mb-0 px-8 py-4 border-0 w-full">
                                <div className="flex flex-wrap items-center">
                                    <div className="relative px-4 max-w-full flex w-full">


                                        <table className="min-w-full leading-normal">
                                            <thead>
                                            <tr>
                                                <th scope="col"
                                                    className="px-5 py-2 bg-white border-b-2 border-t border-gray-200 text-gray-800 text-left text-sm font-semibold">
                                                    Amount
                                                </th>
                                                <th scope="col"
                                                    className="px-5 py-2 bg-white border-b-2 border-t border-gray-200 text-gray-800 text-left text-sm font-semibold">
                                                    Item Description
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {orders.map((order) => {
                                                const {orderParts} = order;
                                                return orderParts.map((part) => {
                                                         const {id, count} = part;
                                                    return (
                                                        <tr className="font-semibold text-xs"
                                                            key={id}>
                                                            <td className="px-5 py-2 bg-white border-t border-gray-200 text-gray-800 text-left">
                                                                {part.count}
                                                            </td>
                                                            <td className="px-5 py-2 bg-white border-t border-gray-200 text-gray-800 text-left">
                                                                {getPartName(id)}
                                                            </td>
                                                        </tr>)
                                                })
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : null}
        </>
    )
}