import React from 'react';
import PartsAddForm from "../../components/Forms/Parts/PartsAddForm";

export default function PartsAddView() {
    return (
        <>
            <div className="relative container mx-auto shadow-2xl bg-white rounded-lg min-h-[46.4rem]">
            <PartsAddForm/>
            </div>
        </>
    );
}