import React from 'react';
import DefaultSelect from '../Default/DefaultSelect';

export default function VehicleCategorySelect({onChange, required}) {
    const options = [
        {value: "soft-top", label: "Soft-top",},
        {value: "hard-top", label: "Hard-top",},
    ];

    return (
        <>
            <DefaultSelect required={required} options={options} onChange={(e) => onChange(e)}
                           label="Vehicle category"/>
        </>
    )
}