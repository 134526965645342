import React from 'react';

export default function AuthenticationWrapper(props) {
    const {isAuth, redirect} = props;

    const session = JSON.parse(localStorage.getItem("SESSION"));

    return (
        <>
            {session ? isAuth ? props.children : redirect : !isAuth ? props.children : redirect}
        </>
    )
}